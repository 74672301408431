<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header"> Gider Türü Değiştir </template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-select-expense-type
              v-model="formData.expense_type_id"
              :label="$t('labels.expense_type')"
              :rules="[rules.required]"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select
              :label="$t('labels.expense_kind')"
              v-model="formData.child_expense_type_id"
              :items="childExpenseTypeList"
              :disabled="!formData.expense_type_id"
              :readonly="!formData.expense_type_id"
            />
          </v-col>
        </v-row>
        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";
import hasForm from "@/view/mixins/hasForm";
import ClusterService from "@/core/services/cluster.service";

export default {
  name: "InvoiceItemBulk",
  mixins: [hasForm],
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      postData: {},
    };
  },
  computed: {
    ...mapGetters(["cluster", "invoiceTypeList"]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.postData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      let tmpid = [];

      this.ids.forEach((element) => {
        tmpid.push(element.id);
      });

      const formData = {
        expense_type_id: this.formData.expense_type_id,
        child_expense_type_id: this.formData.child_expense_type_id,
        id: tmpid,
      };

      this.$api
        .post(`/expense/invoice-items/bulk-update`, formData)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$nextTick(() => this.$emit("saved", response.data.data));
          if (event && event.closeOnSave) {
            this.hide();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
