<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Test Mesajı Gönder</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-select
              v-if="batch && batch.for_each_house_user"
              :items="batchResidentList"
              :item-value="(item) => item.id"
              item-text="resident"
              :search-input.sync="search"
              label="Test Sakin Seçin"
              v-model="formData.batch_recipient_id"
              :readonly="disabled"
              :filled="disabled"
              :rules="[rules.required]"
              required
            />

            <rs-select
              v-else
              :items="batchResidentList"
              :item-value="(item) => item.id"
              item-text="resident"
              :search-input.sync="search"
              label="Test Sakin Seçin"
              v-model="formData.batch_recipient_id"
              :readonly="disabled"
              :filled="disabled"
              :rules="[rules.required]"
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-autocomplete-company-user
              label="Mesajın gönderileceği kişi"
              v-model="formData.user_id"
              :disabled="disabled"
              :company-id="companyId"
              :extra-params="{ is_active: true }"
              :rules="disabled ? [] : [rules.required]"
              item-value="id"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
          save-close-label="Gönder"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "SendTestForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId", "companyId"]),
  },
  data() {
    return {
      batch: {},
      batchId: null,
      disabled: false,
      batchResidentList: [],
      formData: {
        batch_recipient_id: null,
        user_id: null,
      },
    };
  },
  methods: {
    show(batchId) {
      this.batch = {};
      this.batchId = batchId || null;
      this.batchResidentList = [];

      this.formData = {
        batch_recipient_id: null,
        user_id: null,
      };

      this.loadBatchResidentList();

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    loadBatchResidentList() {
      this.isLoading = true;

      const params = {
        per_page: 100,
      };

      this.$api
        .get("batch-notifications", this.batchId)
        .then((response) => {
          return response.data.data;
        })
        .then((batch) => {
          this.batch = batch;

          let url = `batch-notifications/${this.batchId}/house-users`;
          if (!batch.for_each_house_user) {
            url = `batch-notifications/${this.batchId}/residents`;
          }

          return this.$api.query(url, { params });
        })
        .then((response) => {
          this.batchResidentList = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading resident list", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = {
        user_id: this.formData.user_id,
        batch_recipient_id: this.formData.batch_recipient_id,
      };

      this.$api
        .post(`batch-notifications/${this.batchId}/send-test`, formData)
        .then(() => {
          this.$toast.success("Gönderildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
