<template>
  <div>
    <v-card :loading="isLoading" class="mb-3">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="originalData.customer">
            <small>Fatura</small><br />
            <span>{{ originalData.customer.name }}</span>
            <span
              ><rs-table-cell-date :value="originalData.invoiced_on"
            /></span>
          </div>
        </h3>
        <v-col class="text-right p-0">
          <!-- <rs-action
            @click="showActionLogs"
            v-if="id"
            icon
            title="Düzenleme geçmişi"
          >
            <v-icon>mdi-information-variant</v-icon>
          </rs-action> -->
          <rs-action
            @click.prevent="handleDownloadInvoiceClick"
            target="_blank"
            v-if="id && originalData.accounting_id"
            icon
            title="PDF İndir"
          >
            <v-icon>mdi-file-download-outline</v-icon>
          </rs-action>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent.stop="handleFormSubmit"
          ref="form"
          :readonly="isLoading || disabled"
        >
          <v-row dense>
            <v-col cols="12" sm="6">
              <rs-text-field
                label="Fatura Tarihi"
                :value="new Date(formData.invoiced_on).toLocaleDateString()"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-money-field label="Toplam Tutar" :value="formData.amount" />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-money-field
                label="Toplam Tutar KDV Dahil"
                :value="formData.amount_tax_included"
              />
            </v-col>

            <v-col sm="6" v-id="id">
              <rs-text-field
                label="Resmileştirme Tamamlanma"
                :value="
                  originalData.accounting_registered_at
                    ? new Date(
                        originalData.accounting_registered_at
                      ).toLocaleString()
                    : null
                "
                disabled
              />
            </v-col>
          </v-row>

          <h3 class="mt-8">Kalemler</h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="bg-secondary bordered">
                  <th>TYA</th>
                  <th>Gün</th>
                  <th>BB</th>
                  <th>Birim</th>
                  <th>Tutar</th>
                  <th>KDV</th>
                  <th>Toplam</th>
                </tr>
              </thead>
              <tfoot v-if="formData.items.length">
                <tr class="bg-secondary bordered">
                  <td>Toplamlar</td>
                  <td></td>
                  <td class="text-end">{{ itemTotals.units }}</td>
                  <td></td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="itemTotals.total_amount"
                    />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="itemTotals.tax_amount"
                    />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number price :value="itemTotals.sum" />
                  </td>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  class="bordered"
                  v-for="item in formData.items"
                  :key="item.id || item.key"
                >
                  <td>
                    {{ item.customer_cluster.cluster.name }}
                  </td>
                  <td class="text-end">{{ item.invoiced_days }} / 30</td>
                  <td class="text-end">{{ item.units }}</td>
                  <td class="text-end">
                    <rs-table-cell-number price :value="item.amount_per_unit" />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number price :value="item.total_amount" />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number price :value="item.tax_amount" />
                  </td>
                  <td class="text-end">
                    <rs-table-cell-number
                      price
                      :value="item.total_amount + item.tax_amount"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-card :loading="isLoading">
      <v-card-title>Ödemeler</v-card-title>
      <v-card-text v-if="originalData.customer_id">
        <CustomerInvoicePaymentList
          ref="invoicePaymentList"
          :customer-id="originalData.customer_id"
          :invoice-id="id"
        />
        <v-data-table
          v-bind="dataTableAttrs"
          :headers="headersShown"
          :items="list"
          :loading="isLoading"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page.sync="itemsPerPage"
          v-model="selectedItems"
          @update:items-per-page="handleItemsPerPageUpdated"
          :show-select="false"
        >
          <template v-slot:top>
            <rs-table-title-bar
              class="mt-6"
              title=""
              icon=""
              @reload="loadList"
              :search.sync="search.query"
              add-route="#"
              @click:add="handleAddClick"
              hide-edit
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:body.append="{ headers }">
            <rs-table-foot-totals
              :headers="headers"
              :totals="footTotals"
              v-if="list.length > 0"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.edit="{ item }">
            <a
              class="btn btn-icon btn-sm btn-clean"
              href="#"
              @click.prevent="handleEditClick(item)"
            >
              <i class="menu-icon mdi mdi-pencil"></i>
            </a>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.invoice_payment_amount="{ value, item }">
            <template v-if="item.payment_amount !== value">
              <span class="text-muted" title="Toplam ödeme tutarı">
                (<rs-table-cell-number price :value="item.payment_amount" />)
              </span>
            </template>
            <rs-table-cell-number price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.success_at="{ value }">
            <rs-table-cell-date show-time :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <rs-table-button-delete
              @deleted="handleDeleteClick(item)"
              confirmed
              :loading="isLoading"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <!-- <template v-slot:item.removed_at="{ value, item }">
      <rs-table-cell-date show-time :value="value" v-if="value" />
      <rs-table-button-delete
        v-else
        @deleted="handleDeleteConfirmed(item)"
        :loading="isLoading"
        confirmed
        label="Kaldır"
      />
    </template> -->
        </v-data-table>
      </v-card-text>
      <v-card-text v-else>
        Ödemeleri düzenlemek için faturayı kaydedin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { hasDataTable, hasForm, isPage } from "@/view/mixins";
// import { CustomerInvoicePaymentList } from "../components";

export default {
  name: "CustomerInvoiceDetail",
  mixins: [hasDataTable, hasForm, isPage],
  // components: { CustomerInvoicePaymentList },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      pageMeta: {
        title: "Fatura",
      },
      formData: {
        customer_id: null,
        invoiced_on: new Date().toJSON().split("T")[0],
        amount: null,
        amount_tax_included: null,
        accounting_id: null,
        is_auto_invoiced: false,
        items: [],
      },
      itemTotals: {
        units: 0,
        total_amount: 0,
        tax_amount: 0,
        sum: 0,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Tarih",
          value: "success_at",
          searchable: "date",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Ödenen Tutar",
          value: "invoice_payment_amount",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      const scopes = [
        "customer",
        "items",
        "items.customerCluster",
        "items.customerCluster.cluster",
      ];
      const params = Object.assign(this.buildSearchParams({ scopes: scopes }));

      return this.$api
        .query(`customer-invoices/${this.id}`, { params })
        .then((response) => {
          this.loadData(response);
          this.pageMeta.title =
            this.originalData.customer.name + " Fatura Düzenleme";

          const itemTotals = {
            units: 0,
            total_amount: 0,
            tax_amount: 0,
            sum: 0,
          };
          for (const index in this.formData.items) {
            const item = this.formData.items[index];
            itemTotals.units += item.units;
            itemTotals.total_amount += item.total_amount;
            itemTotals.tax_amount += item.tax_amount;
            itemTotals.sum += item.total_amount + item.tax_amount;
          }

          this.itemTotals = itemTotals;
        })
        .then(() => {
          this.loadPaymentList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadPaymentList() {
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.customer_invoice_id = this.id;

      return this.$api
        .query("customer-invoice-payments", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        });
    },
    handleDownloadInvoiceClick() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query(`customer-invoices/${this.id}/document`)
        .then((response) => {
          if (response.data.data.url) {
            const anchor = document.createElement("a");
            anchor.href = response.data.data.url;
            anchor.target = "_blank";
            anchor.click();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>
