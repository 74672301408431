var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("TYA - Yönetim Şirketi Eşleştir")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"cols":"12","dense":""}},[(_vm.cluster === null)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-autocomplete-cluster',{ref:"autocompleteCluster",attrs:{"rules":[_vm.rules.required],"readonly":_vm.disabled,"filled":_vm.disabled,"required":""},model:{value:(_vm.formData.cluster_id),callback:function ($$v) {_vm.$set(_vm.formData, "cluster_id", $$v)},expression:"formData.cluster_id"}})],1):_vm._e(),(_vm.company === null)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-autocomplete-company',{ref:"autocompleteCompany",attrs:{"rules":[_vm.rules.required],"readonly":_vm.disabled,"filled":_vm.disabled,"required":""},on:{"change:option":_vm.handleCompanySelected},model:{value:(_vm.formData.company_id),callback:function ($$v) {_vm.$set(_vm.formData, "company_id", $$v)},expression:"formData.company_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-datepicker',{attrs:{"rules":[_vm.rules.required],"required":"","label":"Başlangıç Tarihi"},model:{value:(_vm.formData.legal_beginning_date),callback:function ($$v) {_vm.$set(_vm.formData, "legal_beginning_date", $$v)},expression:"formData.legal_beginning_date"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-datepicker',{attrs:{"rules":[
              _vm.rules.required,
              _vm.rules.min(
                _vm.formData.legal_ending_date,
                new Date().toISOString().substr(0, 10)
              ),
            ],"label":"Bitiş Tarihi","min":new Date().toISOString().substr(0, 10)},model:{value:(_vm.formData.legal_ending_date),callback:function ($$v) {_vm.$set(_vm.formData, "legal_ending_date", $$v)},expression:"formData.legal_ending_date"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-save-close":""},on:{"cancel":_vm.hide}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }