<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ms-2" color="primary" outlined v-bind="attrs" v-on="on">
        İndir
        <v-icon>mdi-menu-down-outline</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        @click="handleDownloadClick('odt')"
        v-if="types.indexOf('odt') !== -1"
      >
        <v-list-item-title>
          <v-icon>mdi-file-document</v-icon>ODT</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        @click="handleDownloadClick('docx')"
        v-if="types.indexOf('docx') !== -1"
      >
        <v-list-item-title>
          <v-icon>mdi-file-document</v-icon>DOCX</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        @click="handleDownloadClick('ods')"
        v-if="types.indexOf('ods') !== -1"
      >
        <v-list-item-title> <v-icon>mdi-table</v-icon>ODS</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="handleDownloadClick('xlsx')"
        v-if="types.indexOf('xlsx') !== -1"
      >
        <v-list-item-title> <v-icon>mdi-table</v-icon>XLSX</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="handleDownloadClick('pdf')"
        v-if="types.indexOf('pdf') !== -1"
      >
        <v-list-item-title>
          <v-icon>mdi-text-box-outline</v-icon>PDF</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    types: {
      type: Array,
      default: () => ["ods", "xlsx", "pdf"],
    },
  },
  methods: {
    handleDownloadClick(type) {
      this.$emit("download", type);
    },
  },
};
</script>
