<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Demirbaş Tanımı</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Demirbaş Tanımı</span>
      </h3>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-subtitle>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Bilgiler</v-tab>
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
            {{ $t("headers.notes_and_reminders") }}
          </v-badge></v-tab
        >
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          <v-badge
            color="deep-purple lighten-3"
            :content="documentCount || '0'"
          >
            {{ $t("headers.documents") }}
          </v-badge>
        </v-tab>
      </v-tabs>
      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form ref="form" @submit.prevent="handleFormSubmit">
            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-text-field
                  label="Demirbaş Adı"
                  :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                  v-model="formData.name"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select
                  label="Demirbaş Grubu"
                  :rules="rules.maxLength(formData.name, 100)"
                  v-model="formData.fixture_group_id"
                  :items="fixtureGroupsList.filter((item) => item.is_active)"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-datepicker
                  :rules="[]"
                  label="Alınma Tarihi"
                  v-model="formData.purchased_on"
                  :disabled="disabled"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  label="Miktar"
                  :rules="[rules.required]"
                  v-model="formData.quantity"
                  step="1"
                  min="1"
                  max="99999999"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-money-field
                  label="Birim Fiyat"
                  v-model="formData.unit_price"
                  :rules="
                    disabled
                      ? []
                      : [
                          rules.required,
                          rules.min(formData.unit_price, 0.01),
                          rules.max(formData.unit_price, 99999999.99),
                        ]
                  "
                  :disabled="disabled"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-money-field
                  label="Toplam Tutar"
                  v-model="totalPrice"
                  :rules="[
                    rules.min(formData.unit_price, 0.01),
                    rules.max(formData.unit_price, 99999999.99),
                  ]"
                  disabled
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select
                  label="Durumu"
                  :items="fixtureStatusList"
                  :rules="[rules.required]"
                  v-model="formData.status"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select-provider
                  v-model="formData.provider_id"
                  :rules="disabled ? [] : [rules.required]"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  label="Belge No (Fatura-Fiş No)"
                  :rules="[]"
                  v-model="formData.document_number"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-datepicker
                  :rules="[]"
                  label="Belge Tarihi"
                  v-model="formData.document_date"
                  :disabled="disabled"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-datepicker
                  :rules="[]"
                  label="Garanti Başlangıç Tarihi"
                  v-model="formData.warranty_starts_on"
                  :disabled="disabled"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-datepicker
                  :rules="[]"
                  label="Garanti Bitiş Tarihi"
                  v-model="formData.warranty_ends_on"
                  :disabled="disabled"
                />
              </v-col>

              <v-col sm="12">
                <rs-text-field
                  label="Açıklama"
                  :rules="[rules.maxLength(formData.name, 1000)]"
                  v-model="formData.description"
                  :readonly="disabled || (id && !originalData.cluster_id)"
                  :filled="disabled || (id && !originalData.cluster_id)"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4" :eager="id">
          <!-- TODO: the notes should be separate based on "mode" -->
          <NoteList
            :allow-add="true"
            :extra-params="{ fixture_id: id }"
            :item-count.sync="noteCount"
            v-if="id && can('see-note')"
            :form-subtitle="originalData.name"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4" :eager="id">
          <!-- TODO: the notes should be separate based on "mode" -->
          <DocumentList
            :allow-add="true"
            :extra-params="{ fixture_id: id }"
            :item-count.sync="documentCount"
            v-if="id && can('see-document')"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";

export default {
  mixins: [hasForm, hasPermissions],
  components: {
    DocumentList,
    NoteList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "clusterId",
      "cluster",
      "fixtureGroupsList",
      "fixtureStatusList",
    ]),
    totalPrice() {
      let total = 0;
      total = this.formData.quantity * this.formData.unit_price;
      return total;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      selectedTab: "info",
      isLoading: false,
      formData: {
        cluster_id: null,
        provider_id: null,
        name: null,
        fixture_group_id: null,
        purchased_on: null,
        quantity: null,
        unit_price: null,
        status: 1,
        document_number: null,
        document_date: null,
        warranty_starts_on: null,
        warranty_ends_on: null,
        description: null,
      },
      originalData: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`fixtures/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (
            this.originalData.cluster_id &&
            this.clusterId !== this.originalData.cluster_id
          ) {
            this.updateClusterId(this.originalData.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign(
        { cluster_id: this.cluster_id },
        this.formData
      );
      formData.name = this.formData.name;

      if (this.id) {
        this.$api
          .put(`fixtures/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/fixtures`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.fixtures.fixtures.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
