import validation from "@/core/validation";
import { numberToLocaleFormat } from "@/core/helpers";

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      originalData: {},
      isLoading: false,
      rules: validation,
      dataId: null,
      noteCount: null,
      documentCount: null,
    };
  },
  methods: {
    loadData(response) {
      this.originalData = Object.assign({}, response.data.data);

      for (const index in this.formData) {
        if (response.data.data[index] !== undefined) {
          this.formData[index] = response.data.data[index];
        }
      }

      if (this.originalData.id) {
        this.dataId = this.originalData.id;
      }
    },
    handleError(error, options) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }

      options = options || { timeout: 7000 };
      const isGet = error.config.method === "get";

      if (error.response.status === 401) {
        this.$toast.error("Kullanıcı girişi yapmalısınız.");
      } else if (error.response.status === 403) {
        this.$toast.error("Bunu yapmaya yetkili değilsiniz.");
      } else if (error.response.status === 422) {
        for (const itemKey in error.response.data.errors) {
          const messageList = error.response.data.errors[itemKey];
          for (const index in messageList) {
            this.$toast.error(messageList[index], options);
          }
        }
      } else if (error.response.status === 500) {
        if (isGet) {
          this.$toast.error("Sunucu hatası. Veriler yüklenemedi.");
        } else {
          this.$toast.error("Sunucu hatası. İşleminiz kaydedilmedi.");
        }
      } else if (error.response.status === 503) {
        this.$toast.warning(
          "Sunucu bakım modunda. Lütfen daha sonra tekrar deneyin."
        );
      } else if (error.response.status === 404) {
        this.$toast.error("Öğe bulunamadı.");
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    numberToLocaleFormat,
  },
};
