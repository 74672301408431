<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Bağımsız Bölüm</small><br />
          <span>{{ originalData.block.name }}-{{ originalData.name }}</span>
        </div>
        <span v-else>Bağımsız Bölüm Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          v-if="
            can('edit-house') &&
            $refs.confirmFutureOwner &&
            originalData.future_owner_id
          "
          @click="handleFutureOwnerClick"
        >
          Tapu Devret
        </rs-action>

        <rs-confirm
          ref="confirmFutureOwner"
          :loading="isLoading"
          @confirmed="handleFutureOwnerSubmitClick"
          @cancelled="$refs.confirmFutureOwner.hide()"
        />

        <rs-action
          v-if="can('edit-house') && $refs.ownerForm"
          @click="$refs.ownerForm.show(null, { house_id: id })"
        >
          <template v-if="originalData.has_multiple_owners">
            Hissedar Tanımla
          </template>
          <template v-else>Ev Sahibi Tanımla</template>
        </rs-action>

        <rs-action
          v-if="can('edit-house')"
          @click="$refs.renterForm.show(null, { house_id: id })"
        >
          Kiracı Tanımla
        </rs-action>

        <rs-action
          v-if="can('edit-house-income') && $refs.openningForm"
          @click="$refs.openningForm.show(id)"
        >
          Açılış Alacak Fişi
        </rs-action>

        <v-menu offset-y bottom left v-if="originalData && originalData.id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="pink"
              v-bind="attrs"
              v-on="on"
              outlined
              small
              class="ms-2"
            >
              Kişiler
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in originalData.house_users"
              :key="item.resident_id"
              :to="{
                name: 'definitions.residents.edit',
                params: { id: item.resident_id },
              }"
            >
              <v-list-item-title>{{ item.resident.name }}</v-list-item-title>
              <br />
              <v-list-item-subtitle>
                {{ $t("house.user_type." + item.house_user_type_id) }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>

        <rs-action
          :to="{ name: 'incomes.houses.show', params: { id: id } }"
          v-if="id && can('edit-house-income')"
          title="Mali işlemlere git"
          icon
        >
          <v-icon>mdi-cash</v-icon>
        </rs-action>

        <rs-action
          :to="{ name: 'definitions.houses.edit', params: { id: id } }"
          v-if="id && can('edit-house') && disabled"
          title="Düzenle"
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action-delete
          v-if="can('delete-house') && deletable"
          @click="handleDeleteClick(item)"
          confirmed
          title="Sil"
          icon
        />

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-house')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Bilgiler</v-tab>
        <v-tab
          to="#auto-assessments"
          replace
          :disabled="!id"
          v-if="can('edit-house') && can('see-periodical-income')"
        >
          Otomatik Borçlandırma
        </v-tab>
        <!-- <v-tab
            to="#owners"
            v-if="originalData.has_multiple_owners && can('edit-house')"
            replace
            :disabled="!id"
          >
            Hissedarlar
          </v-tab> -->
        <v-tab to="#history" replace :disabled="!id" v-if="can('edit-house')"
          >Giriş Çıkış</v-tab
        >
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          {{ $t("headers.notes_and_reminders") }}
        </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          {{ $t("headers.documents") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <p v-if="!cluster && !id">
            Bağımsız bölüm eklemek için üst menüden toplu yaşam alanı
            seçmelisiniz.
          </p>
          <p v-if="cluster && !id">
            TYA'daki bağımsız bölüm sayısı:
            {{ cluster.current_house_count }}
            <br />
            {{ cluster && cluster.house_count - cluster.current_house_count }}
            tane bağımsız bölüm ekleyebilirsiniz.
          </p>
          <v-form
            v-if="cluster && cluster.house_count >= cluster.current_house_count"
            ref="form"
            @submit.prevent="handleFormSubmit"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select
                  :label="$t('labels.block')"
                  :rules="[rules.required]"
                  :items="blocks"
                  v-model="formData.block_id"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.floor')"
                  v-model="formData.floor"
                  :rules="[
                    rules.minLength(formData.floor, 1),
                    rules.maxLength(formData.floor, 25),
                  ]"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.house_no')"
                  :rules="[rules.required]"
                  v-model="formData.door_number"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :label="$t('labels.house_kind')"
                  :rules="[rules.required]"
                  :items="houseUsageTypeList"
                  v-model="formData.house_usage_type_id"
                  :item-text="(item) => $t(item.translation_key)"
                  item-value="id"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :label="$t('labels.house_type')"
                  :rules="[rules.required]"
                  :items="cluster.house_types"
                  v-model="formData.house_type_id"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <!-- <v-col sm="6" v-if="id">
                <rs-text-field
                  :label="$t('labels.owner_atm_code')"
                  v-model="formData.owner_atm_code"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->
              <!-- <v-col sm="6" v-if="id">
                <rs-text-field
                  :label="$t('labels.renter_atm_code')"
                  v-model="formData.renter_atm_code"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col> -->
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  step="0.01"
                  min="1"
                  :label="$t('labels.area_gross')"
                  v-model="formData.area_gross"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  step="0.01"
                  min="1"
                  :label="$t('labels.area_net')"
                  v-model="formData.area_net"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  step="0.01"
                  min="1"
                  :label="$t('labels.area_garden')"
                  v-model="formData.area_garden"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  step="0.01"
                  min="1"
                  :label="$t('labels.area_share_meter')"
                  v-model="formData.area_share_meter"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  type="number"
                  step="0.01"
                  min="1"
                  :label="$t('labels.area_land_share')"
                  v-model="formData.area_land_share"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.has_periodical_assessments"
                  :label="$t('labels.has_periodical_assessments')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.is_active"
                  :label="formData.is_active ? 'Aktif' : 'Pasif'"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.has_periodical_crediting"
                  :label="$t('labels.has_periodical_crediting')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col> -->
              <!-- <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.calculate_share_meter"
                  :label="$t('labels.calculate_share_meter')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.has_share_meter_assessments"
                  :label="$t('labels.has_share_meter_assessments')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col> -->
            </v-row>

            <v-row dense>
              <!-- <v-col cols="12" sm="6">
                <v-switch
                  v-model="formData.has_multiple_owners"
                  :label="$t('labels.has_multiple_owners')"
                  hide-details="auto"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col> -->
              <!-- <v-col sm="6" v-if="formData.has_multiple_owners">
                  <rs-text-field
                    type="number"
                    step="1"
                    min="1"
                    :label="$t('labels.share_total')"
                    :rules="[rules.required]"
                    v-model="formData.share_total"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col> -->
            </v-row>

            <v-divider class="my-10" v-if="cluster.has_future_owners" />

            <v-expansion-panels
              multiple
              accordion
              v-if="cluster.has_future_owners"
            >
              <v-expansion-panel key="future_owner">
                <v-expansion-panel-header
                  ><h5>
                    Teslim Edilmemiş Bağımsız Bölüm Bilgileri
                    <v-badge
                      class="ms-3"
                      v-if="originalData.future_owner_id"
                      color="deep-purple lighten-3"
                      content="1"
                    /></h5
                ></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>
                    Henüz sahibine teslim edilmemiş bağımsız bölüme dair
                    bilgileri buraya girebilirsiniz.
                  </p>
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <rs-select-resident
                        label="Sözleşmeli Ev Sahibi"
                        v-model="formData.future_owner_id"
                        :cluster-id="clusterId"
                        :readonly="disabled"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <rs-datepicker
                        label="Teslim Tarihi"
                        v-model="formData.future_owner_delivery_on"
                        :readonly="disabled"
                        :cluster-id="clusterId"
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-switch
                        v-model="formData.future_owner_is_conditional"
                        :disabled="disabled"
                        :label="
                          formData.future_owner_is_conditional
                            ? 'Şartlı Teslim'
                            : 'Şartsız Teslim'
                        "
                        hide-details="auto"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <rs-textarea
                        label="Teslim Açıklaması"
                        v-model="formData.future_owner_description"
                        :readonly="disabled"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-divider class="my-10" />

            <v-row v-if="id">
              <v-col cols="12">
                <h3>Durum</h3>
              </v-col>
              <v-col sm="6" v-if="id">
                <rs-text-field
                  :label="$t('labels.owner')"
                  v-model="ownerName"
                  readonly
                  filled
                />
              </v-col>
              <v-col sm="6" v-if="id">
                <rs-text-field
                  :label="$t('labels.renter')"
                  v-model="renterName"
                  readonly
                  filled
                />
              </v-col>
              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.debt')"
                  :value="status.debt"
                  :disabled="true"
                />
              </v-col>

              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.due')"
                  :value="status.due"
                  :disabled="true"
                />
              </v-col>

              <v-col sm="4">
                <rs-money-field
                  :label="$t('labels.balance')"
                  :value="status.balance"
                  :disabled="true"
                />
              </v-col>
            </v-row>

            <rs-form-buttons
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              v-if="!disabled"
              @submit.prevent="handleFormSubmit"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="auto-assessments">
          <HousePeriodicalAssessmentList v-if="id" :house-id="id" />
        </v-tab-item>
        <v-tab-item value="owners">
          <v-data-table
            :fixed-header="false"
            :height="undefined"
            v-bind="dataTableAttrs"
            :headers="ownersHeaders"
            :items="ownersList"
            :loading="isLoading"
            :options.sync="ownersOptions"
            :server-items-length="ownersList.length"
            :items-per-page.sync="itemsPerPage"
            v-model="selectedItems"
            v-if="ownersList.length"
            hide-default-footer
          >
            <template v-slot:top>
              <rs-table-title-bar
                class="mt-6"
                title="Hissedarlar"
                icon=""
                @clear-filters="handleRemoveFiltersClick"
                @reload="load()"
                :edit-enabled="selectedItems.length"
                :hide-search="true"
                @click:edit="handleHouseOwnerEditClick"
                v-model="selectedItems"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:body.append="{ headers }">
              <rs-table-foot-totals
                not-price
                :headers="headers"
                :totals="footTotals"
              />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.edit="{ item }">
              <a href="#" @click.prevent="handleHouseOwnerEditClick(item)">
                <i class="menu-icon mdi mdi-pencil"></i>
              </a>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.name="{ item, value }">
              <a href="#" @click.prevent="handleHouseOwnerEditClick(item)">
                {{ value }}
              </a>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.is_spokesperson="{ value }">
              <rs-table-cell-boolean :value="value" />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.is_staying="{ value }">
              <rs-table-cell-boolean :value="value" />
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.share_percent="{ item, value }">
              <v-edit-dialog
                :return-value.sync="item.share_percent"
                @save="handleSharePercentSaved"
              >
                %{{ value }}
                <template v-slot:input>
                  <v-text-field
                    type="number"
                    step="1"
                    min="1"
                    max="100"
                    v-model="item.share_percent"
                    :rules="[rules.required]"
                    label="Düzenle"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <v-btn
                color="pink"
                outlined
                @click="handleIsSpokespersonClick(item)"
                small
                class="me-1 mb-1"
                :disabled="item.is_spokesperson"
              >
                İlgili
              </v-btn>

              <v-btn
                color="pink"
                outlined
                @click="handleIsStayingClick(item)"
                small
                class="me-1 mb-1"
                :disabled="item.is_staying"
              >
                Oturuyor
              </v-btn>

              <rs-table-button-delete confirmed @deleted="load" />
            </template>
          </v-data-table>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            :disable-submit="footTotals.share_percent !== 100"
            hide-save-close
          />
        </v-tab-item>
        <v-tab-item value="history">
          <HouseUserList v-if="id" :house-id="id" />
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <NoteList
            :allow-add="true"
            :extra-params="{ house_id: id }"
            :form-subtitle="originalData.door_number"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4">
          <!-- TODO: the notes should be separate based on "mode" -->
          <DocumentList :allow-add="true" :extra-params="{ house_id: id }" />
        </v-tab-item>
      </v-tabs-items>

      <OwnerForm
        ref="ownerForm"
        :extra-params="{ house_id: id }"
        @saved="load()"
        :house="originalData"
      />

      <RenterForm
        ref="renterForm"
        :extra-params="{ house_id: id }"
        @saved="load()"
        :house="originalData"
      />

      <OpenningDueReceiptForm
        ref="openningForm"
        :extra-params="{ house_id: id }"
        @saved="load()"
        :house="originalData"
      />
      <ActionLogList ref="actionLogList" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { houseUsageTypes } from "@/core/data/options";
import { hasDataTable, hasForm, hasPermissions } from "@/view/mixins";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import OpenningDueReceiptForm from "@/view/content/houses/OpenningDueReceiptForm";
import OwnerForm from "@/view/content/houses/OwnerForm";
import RenterForm from "@/view/content/houses/RenterForm";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";
import HousePeriodicalAssessmentList from "./HousePeriodicalAssessmentList.vue";
import HouseUserList from "./HouseUserList.vue";

export default {
  mixins: [hasDataTable, hasForm, hasPermissions],
  components: {
    ActionLogList,
    OpenningDueReceiptForm,
    OwnerForm,
    HousePeriodicalAssessmentList,
    HouseUserList,
    RenterForm,
    NoteList,
    DocumentList,
  },
  props: {
    /**
     * House ID if editing
     */
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId", "incomeExpenseTypeList", "blocks"]),
    renterName() {
      const list = [];
      if (
        this.originalData &&
        this.originalData.house_users &&
        this.originalData.house_users.length > 0
      ) {
        for (let index in this.originalData.house_users) {
          let item = this.originalData.house_users[index];

          if (item.house_user_type_id === 2) {
            list.push(item.resident.name);
          }
        }
      }

      return list.join(", ") || "Kiracı yok";
    },
    ownerName() {
      const list = [];
      if (
        this.originalData &&
        this.originalData.house_users &&
        this.originalData.house_users.length > 0
      ) {
        for (let index in this.originalData.house_users) {
          let item = this.originalData.house_users[index];

          if (item.house_user_type_id === 1) {
            list.push(item.resident.name);
          }
        }
      }

      return list.join(", ");
    },
  },
  watch: {
    id() {
      this.load();
    },
    cluster: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.cluster) {
            this.formData.cluster_id = this.cluster.id;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      } else {
        if (this.$route.params && this.$route.params.formParams) {
          this.formData = Object.assign(
            this.formData,
            this.$route.params.formParams
          );
        }
      }
    });
  },
  data() {
    return {
      selectedTab: "info",
      houseUsageTypeList: houseUsageTypes,
      status: {},
      deletable: false,
      formData: {
        block_id: null,
        door_number: null,
        floor: null,
        house_type_id: null,
        house_usage_type_id: null,
        area_share_meter: null,
        area_gross: null,
        area_net: null,
        area_garden: 0,
        area_land_share: null,
        share_total: null,
        has_periodical_assessments: true,
        // has_periodical_crediting: true,
        has_multiple_owners: false,
        // calculate_share_meter: false,
        // has_share_meter_assessments: false,
        is_active: true,
        // owner_atm_code: null,
        // renter_atm_code: null,
        future_owner_id: null,
        future_owner_delivery_on: null,
        future_owner_is_conditional: false,
        future_owner_description: null,
      },
      ownersList: [],
      ownersOptions: {},
      ownersTotal: null,
      footTotals: {},
      ownersHeaders: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-house"),
        },
        {
          text: "Kişi",
          value: "name",
          searchable: "text",
          sortable: true,
        },
        {
          text: "Hisse Oranı",
          value: "share_percent",
          searchable: "number",
          sortable: true,
          align: "center",
          cellClass: () =>
            this.footTotals.share_percent !== 100
              ? "text-danger font-weight-black"
              : "text-success font-weight-bold",
        },
        {
          text: "Oturuyor",
          value: "is_staying",
          searchable: "yesNo",
          align: "center",
          sortable: true,
        },
        {
          text: "İlgili",
          value: "is_spokesperson",
          searchable: "yesNo",
          align: "center",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          searchable: false,
          sortable: false,
        },
      ],
      showRenterForm: false,
    };
  },
  methods: {
    ...mapActions(["updateCompany", "updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      this.$api
        .query(`income/houses/${this.id}/status`)
        .then((response) => {
          this.status = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });

      return this.$api
        .query(`houses/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(this.originalData.block.cluster_id);
          this.setOwnersList();

          const params = {
            cluster_id: this.originalData.block.cluster_id,
            house_id: this.id,
            per_page: 1,
          };
          this.deletable = false;

          this.$api.query("balance-activities", { params }).then((response) => {
            if (response.data.data.length === 0) {
              this.deletable = true;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setOwnersList() {
      const list = [];

      if (!this.id || !this.originalData || !this.originalData.house_users) {
        return list;
      }

      let shareSum = 0;

      for (const index in this.originalData.house_users) {
        let houseUser = this.originalData.house_users[index];
        if (houseUser.house_user_type_id !== 1) {
          continue;
        }

        const sharePercent =
          Math.round(
            (houseUser.share / this.originalData.share_total) * 10000
          ) / 100;

        list.push({
          id: houseUser.id,
          user_id: houseUser.resident.id,
          name: houseUser.resident.name,
          share: houseUser.share,
          share_percent: sharePercent,
          is_spokesperson: houseUser.is_spokesperson,
          is_staying: houseUser.is_staying,
        });
        shareSum += sharePercent;
      }

      this.ownersList = list;
      this.footTotals.share_percent = shareSum;
    },
    handleSharePercentSaved() {
      let shareSum = 0;

      for (const index in this.ownersList) {
        const houseUser = this.ownersList[index];
        shareSum += parseInt(houseUser.share_percent);
      }

      this.footTotals = {
        share_percent: shareSum,
      };
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`houses/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.cluster_id = this.clusterId;

        this.$api
          .post(`clusters/${this.clusterId}/houses`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.houses.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleHouseOwnerEditClick(item) {
      if (item && item.id) {
        this.$refs.ownerForm.show(item.id);
      } else if (this.selectedItems.length === 1) {
        this.$refs.ownerForm.show(this.selectedItems[0].id);
      }
    },
    handleIsSpokespersonClick(item) {
      for (const index in this.ownersList) {
        this.ownersList[index].is_spokesperson = false;
      }

      item.is_spokesperson = true;
    },
    handleIsStayingClick(item) {
      for (const index in this.ownersList) {
        this.ownersList[index].is_staying = false;
      }

      item.is_staying = true;
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\House", this.id);
    },
    handleFutureOwnerClick() {
      this.$refs.confirmFutureOwner.show(
        `Ev sahibini ${this.originalData.future_owner.name} olarak değiştirmek istediğinizden emin misiniz?`
      );
    },
    handleFutureOwnerSubmitClick() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.$api
        .post(`houses/${this.id}/transfer-to-future-owner`)
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.$refs.confirmFutureOwner.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.load();
        });
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`houses/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.$router.replace({
            name: "definitions.houses.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
