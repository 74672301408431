<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Ekle</template>
    <template slot="body">
      <v-form
        @submit.prevent="handleFormSubmit"
        ref="form"
        :filled="isLoading"
        :readonly="isLoading"
      >
        <v-row cols="6" dense>
          <v-col sm="12">
            <rs-select-customer
              label="Müşteri"
              v-model="formData.customer_id"
              :rules="[rules.required]"
              :disabled="customerId"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-money-field
              label="Tutar"
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.0),
                rules.max(formData.amount, 999999.99),
              ]"
              :disabled="isLoading"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Ödeme Tarihi"
              v-model="formData.payment_on"
              :rules="[rules.required]"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              :label="$t('labels.description')"
              :rules="[rules.maxLength(formData.description, 1000)]"
              v-model="formData.description"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "CustomerPaymentForm",
  mixins: [hasForm, hasPermissions],
  props: {
    customerId: {
      type: Number,
      required: false,
      default: null,
    },
    invoiceId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      id: null,
      selectedProduct: null,
      formData: {
        customer_id: null,
        payment_on: new Date().toJSON().split("T")[0],
        amount: null,
        // amount_for_app: null,
        customer_invoice_id: null,
        description: null,
      },
    };
  },
  methods: {
    show(id) {
      this.formData = {
        customer_id: null,
        payment_on: new Date().toJSON().split("T")[0],
        amount: null,
        // amount_for_app: null,
        customer_invoice_id: null,
        description: null,
      };

      if (this.customerId) {
        this.formData.customer_id = this.customerId;
      }

      if (this.invoiceId) {
        this.formData.customer_invoice_id = this.invoiceId;
      }

      if (id) {
        this.id = id;
        this.load();
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/customer-invoice-payments/${this.id}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-invoice-payments/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/customer-invoice-payments`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleProductSelected(product) {
      this.selectedProduct = product;

      if (this.formData.is_yearly) {
        this.formData.amount = this.selectedProduct.yearly_price_per_house;
      } else {
        this.formData.amount = this.selectedProduct.monthly_price_per_house;
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
