<template>
  <div>
    <v-card :loading="isLoading" class="mb-5">
      <v-card-title>
        <template v-if="originalData.name">
          <span>{{ originalData.name }}</span>
        </template>
        <span v-else>Proje Tanımı</span>
        <!-- <div>
          <small>{{ cluster.name }} Projesi</small>
        </div> -->
        <v-col class="text-right p-0">
          <v-btn
            class="me-2"
            color="pink"
            outlined
            @click="
              $refs.confirmDelete.show('Silmek istediğinizden emin misiniz?')
            "
            v-if="
              can('delete-project') &&
              $refs.confirmDelete &&
              id &&
              totalExpense === 0 &&
              totalIncome === 0
            "
            small
          >
            Sil
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-subtitle>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </v-card-subtitle>
      <v-card-text>
        <v-row dense v-if="id">
          <v-col sm="4">
            <p>Toplam Gider: {{ (totalExpense || 0).toLocaleString() }}₺</p>
          </v-col>
          <v-col sm="4">
            <p>Toplam Gelir: {{ (totalIncome || 0).toLocaleString() }}₺</p>
          </v-col>
          <v-col sm="4">
            <p>
              Toplam Fark:
              {{ ((totalIncome || 0) - (totalExpense || 0)).toLocaleString() }}₺
            </p>
          </v-col>
        </v-row>

        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col cols="12" sm="6">
              <rs-text-field
                label="Proje Kodu"
                :rules="[rules.required, rules.maxLength(formData.name, 50)]"
                v-model="formData.name"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-text-field
                label="Proje Adı"
                :rules="[rules.required, rules.maxLength(formData.title, 100)]"
                v-model="formData.title"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.starts_on"
                :rules="disabled ? [] : [rules.required]"
                :disabled="disabled"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.ends_on"
                :rules="disabled ? [] : [rules.required]"
                :disabled="disabled"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-money-field
                v-model="formData.amount"
                label="Proje Tutarı"
                :disabled="disabled"
                :rules="[
                  rules.min(formData.amount, 0.01),
                  rules.max(formData.amount, 9999999.99),
                ]"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>

            <v-col cols="12">
              <rs-textarea
                v-model="formData.description"
                :rules="[rules.required]"
                :label="$t('labels.description')"
                required
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>

        <rs-confirm
          ref="confirmDelete"
          :loading="isLoading"
          @confirmed="handleDeleteClick"
          @cancelled="$refs.confirmDelete.hide()"
        />
      </v-card-text>
    </v-card>

    <v-row v-if="id">
      <v-col cols="12" sm="6">
        <v-card :loading="isLoading">
          <v-card-title> Proje Giderleri </v-card-title>
          <v-card-subtitle>
            Toplam Gider:
            {{ (totalExpense || 0).toLocaleString() }} TL
          </v-card-subtitle>
          <v-card-text>
            <ProjectExpenseList
              :project-id="id"
              @update:total="totalExpense = $event || 0"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card :loading="isLoading">
          <v-card-title> Proje Gelirleri </v-card-title>
          <v-card-subtitle>
            Toplam Gelir:
            {{ (originalData.income || 0).toLocaleString() }} TL
          </v-card-subtitle>
          <v-card-text>
            <ProjectIncomeList
              :project-id="id"
              @update:total="totalIncome = $event || 0"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasForm, hasPermissions } from "@/view/mixins";
import ProjectExpenseList from "./ProjectExpenseList";
import ProjectIncomeList from "./ProjectIncomeList";

export default {
  name: "ProjectForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ProjectExpenseList,
    ProjectIncomeList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "cluster"]),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        cluster_id: null,
        name: null,
        title: null,
        starts_on: null,
        ends_on: null,
        amount: null,
        description: null,
        is_active: true,
      },
      totalExpense: 0,
      totalIncome: 0,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`projects/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          if (this.clusterId !== this.originalData.cluster_id) {
            this.updateClusterId(this.originalData.cluster_id);
          }

          if (document.getElementsByClassName("formatted-text")[0]) {
            document.getElementsByClassName("formatted-text")[0].innerHTML =
              this.numberToLocaleFormat(this.formData.amount);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (!this.id) {
        formData.cluster_id = this.clusterId;
      }

      if (this.id) {
        this.$api
          .put(`projects/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", this.formData);
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
            ClusterService.loadProjectList();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/projects`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", this.formData));
            ClusterService.loadProjectList();

            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "other-definitions.projects.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`projects/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          ClusterService.loadProjectList();
          this.$router.replace({
            name: "other-definitions.projects.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
