<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header"> Transfer Detay </template>
    <template slot="body">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Tarih"
              v-model="originalData.action_on"
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="Tutar"
              v-model="amount"
              readonly
              suffix="₺"
              filled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="originalData.description"
              rows="3"
              no-resize
              readonly
              filled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <h5>Çıkan Fiş Bilgileri</h5>
            <rs-text-field
              label="Çıkan Banka/Kasa"
              v-model="toname"
              readonly
              filled
            />
            <br />
            <rs-text-field
              label="Sistem No"
              v-model="tosystem_no"
              readonly
              filled
            />
            <br />
            <rs-text-field
              label="İşlem Sonrası Bakiye"
              v-model="tobalance"
              readonly
              filled
              suffix="₺"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <h5>Giren Fiş Bilgileri</h5>
            <rs-text-field
              label="Giren Banka/Kasa"
              v-model="sendername"
              readonly
              filled
            />
            <br />
            <rs-text-field
              label="Sistem No"
              v-model="sendersystem_no"
              readonly
              filled
            />
            <br />
            <rs-text-field
              label="İşlem Sonrası Bakiye"
              v-model="senderbalance"
              readonly
              filled
              suffix="₺"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          :hide-submit="true"
          :hide-save-close="true"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "TransferDetail",
  mixins: [hasForm],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      idReal: null,
      originalData: {},
      sendername: "",
      toname: "",
      sendersystem_no: "",
      tosystem_no: "",
      senderbalance: "",
      tobalance: "",
      amount: "",
    };
  },
  methods: {
    show(id) {
      if (this.id) {
        this.idReal = this.id;
      } else {
        this.idReal = id;
      }
      this.$nextTick(() => {
        this.load();
      });
    },
    load() {
      if (this.isLoading || !this.idReal) return false;

      this.isLoading = true;

      this.$api
        .query(`income/transfers/${this.idReal}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          this.amount = this.numberToLocaleFormat(this.originalData.amount);
          this.originalData.balance_activities.forEach((element) => {
            if (element.bank_safe_amount < 0) {
              this.tosystem_no = element.balance_no;
              this.tobalance = this.numberToLocaleFormat(
                element.bank_safe_balance
              );
            } else {
              this.sendersystem_no = element.balance_no;
              this.senderbalance = this.numberToLocaleFormat(
                element.bank_safe_balance
              );
            }
          });

          if (this.originalData.from_safe) {
            this.toname =
              this.originalData.from_safe.name +
              " - " +
              this.originalData.from_safe.title;
          } else {
            this.toname =
              this.originalData.from_bank_account.name +
              " - " +
              this.originalData.from_bank_account.title;
          }

          if (this.originalData.to_safe) {
            this.sendername =
              this.originalData.to_safe.name +
              " - " +
              this.originalData.to_safe.title;
          } else {
            this.sendername =
              this.originalData.to_bank_account.name +
              " - " +
              this.originalData.to_bank_account.title;
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.sidePanel.show();
        });
    },
    hide() {
      this.originalData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
  },
};
</script>
