export default [
  {
    path: "/incomes/houses",
    name: "incomes.houses.list",
    component: () => import("@/view/pages/income/houses/HouseList.vue"),
  },
  {
    path: "/incomes/houses/:id",
    name: "incomes.houses.show",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/income/houses/HouseDetail.vue"),
  },
  {
    path: "/incomes/residents",
    name: "incomes.residents.list",
    component: () => import("@/view/pages/income/residents/ResidentList.vue"),
  },
  {
    path: "/incomes/residents/:id",
    name: "incomes.residents.show",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/income/residents/ResidentDetail.vue"),
  },
  {
    path: "/incomes/bank/open",
    name: "incomes.bank.open.list",
    props: () => ({ listType: "open" }),
    component: () => import("@/view/pages/income/bank/BankActivityList.vue"),
  },
  {
    path: "/incomes/bank/closed",
    name: "incomes.bank.closed.list",
    props: () => ({ listType: "closed" }),
    component: () => import("@/view/pages/income/bank/BankActivityList.vue"),
  },
  {
    path: "/incomes/bank/balance-check",
    name: "incomes.bank.balance-check.list",
    component: () => import("@/view/pages/income/bank/BalanceCheckList.vue"),
  },
  {
    path: "/incomes/bank/:id",
    name: "incomes.bank.show",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/income/bank/BankActivityDetail.vue"),
  },
  {
    path: "/incomes/other",
    name: "incomes.other.list",
    component: () => import("@/view/pages/income/other/OtherIncomeList.vue"),
  },
  {
    path: "/incomes/other/create",
    name: "incomes.other.create",
    component: () => import("@/view/pages/income/other/OtherIncomeForm.vue"),
  },
  {
    path: "/incomes/other/:id",
    name: "incomes.other.edit",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/income/other/OtherIncomeForm.vue"),
  },
  {
    path: "/incomes/other/:id",
    name: "incomes.other.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/income/other/OtherIncomeForm.vue"),
  },
  {
    path: "/incomes/risky",
    name: "incomes.risky.list",
    component: () => import("@/view/pages/income/risky/ResidentRisky.vue"),
  },
];
