<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 v-if="id">{{ formData.subject }} Arıza Kaydı</h3>
      <h3 v-else>Arıza Oluşturma</h3>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#items" replace> Bilgiler </v-tab>
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
            {{ $t("headers.notes_and_reminders") }}
          </v-badge>
        </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          <v-badge
            color="deep-purple lighten-3"
            :content="documentCount || '0'"
          >
            {{ $t("headers.documents") }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="items">
          <div class="py-5">
            <v-form ref="form" @submit.prevent="handleFormSubmit">
              <v-row cols="12" dense>
                <v-col lg="3" cols="6">
                  <rs-datepicker
                    label="Arıza Tarihi"
                    v-model="formData.date"
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Arıza Şekli"
                    v-model="formData.target_id"
                    :items="careTargetList"
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Arıza Türü"
                    :rules="[rules.required]"
                    v-model="formData.breakdown_type_id"
                    :items="breakdownTypeList"
                    :disabled="closedBreakdown"
                  >
                    <template
                      slot="append-outer"
                      v-if="can('edit-breakdown-type')"
                    >
                      <v-btn
                        v-if="$refs.breakdownTypeForm"
                        small
                        icon
                        outlined
                        @click="$refs.breakdownTypeForm.show()"
                        color="pink"
                        title="Yeni arıza türü tanımla"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                      <BreakdownTypeBasicForm
                        @created="handleBreakdownTypeCreated"
                        ref="breakdownTypeForm"
                      />
                    </template>
                  </rs-select>
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Arıza Durumu"
                    v-model="formData.status_id"
                    :items="careStatusList"
                    :disabled="closedBreakdown || !id"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <v-switch
                    label="Faaliyet Raporuna Ekle"
                    v-model="formData.add_to_report"
                    hide-details="auto"
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-text-field
                    label="Arıza Konu"
                    :rules="[rules.required]"
                    v-model="formData.subject"
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select-provider
                    label="Arızayı Giderecek"
                    v-model="formData.provider_id"
                    :cluster-id="clusterId"
                    :disabled="closedBreakdown"
                  >
                  </rs-select-provider>
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select-resident
                    label="Arıza Bildiren"
                    v-model="formData.resident_id"
                    :cluster-id="clusterId"
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col cols="12">
                  <rs-textarea
                    label="Arıza Açıklama"
                    v-model="formData.description"
                    rows="5"
                    no-resize
                    :disabled="closedBreakdown"
                  />
                </v-col>
                <v-col v-if="id" cols="4">
                  <rs-textarea
                    label="Arıza Durum Açıklaması"
                    v-model="formData.status_text"
                    rows="5"
                    :disabled="closedBreakdown"
                    no-resize
                  />
                </v-col>
                <v-col cols="4" v-if="formData.status_id == 99">
                  <rs-textarea
                    label="Arıza Kapatma Açıklaması"
                    v-model="formData.close_text"
                    :rules="[
                      formData.status_id == 99 ? rules.required : null,
                      rules.minLength(formData.close_text, 10),
                    ]"
                    rows="5"
                    no-resize
                    :disabled="closedBreakdown"
                    hint="Faaliyet raporunda gözükür"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="4" v-if="formData.status_id == 99">
                  <rs-datepicker
                    label="Arıza Kapatma Tarihi"
                    v-model="formData.closed_on"
                    :disabled="closedBreakdown"
                    hint="Faaliyet raporunda bu tarih gözükür"
                    :rules="[
                      rules.minOrEqualDate(formData.closed_on, formData.date),
                      rules.maxOrEqualDate(formData.closed_on, new Date()),
                    ]"
                  />
                </v-col>
              </v-row>
              <rs-form-buttons
                v-if="!closedBreakdown"
                :is-loading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
              />
            </v-form>
          </div>
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4" :eager="id">
          <NoteList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="noteCount"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4" :eager="id">
          <DocumentList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="documentCount"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import { mapGetters, mapActions } from "vuex";
import DocumentList from "@/view/content/documents/DocumentList";
import NoteList from "@/view/content/Notes/NoteList";
import { BreakdownTypeBasicForm } from "@/view/content/definitions/forms";

export default {
  name: "BreakdownForm",
  mixins: [hasForm, hasPermissions],
  components: {
    BreakdownTypeBasicForm,
    DocumentList,
    NoteList,
  },
  computed: {
    ...mapGetters([
      "clusterId",
      "careTargetList",
      "careStatusList",
      "companyId",
    ]),
    extraParams() {
      return { care_id: this.id };
    },
  },
  props: {
    /**
     * Breakdown ID
     * @type {Number}
     */
    id: {
      type: Number,
    },
  },
  data() {
    return {
      formData: {
        add_to_report: true,
        date: new Date().toJSON().split("T")[0],
        closed_on: null,
        breakdown_type_id: null,
      },
      selectedTab: null,
      isLoading: false,
      breakdownTypeList: [],
      closedBreakdown: false,
      documentCount: null,
      noteCount: null,
    };
  },
  methods: {
    ...mapActions([
      "updateClusterId",
      "updateBreakdownRecentList",
      "updateBreakdownCount",
    ]),
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("breakdowns", this.id)
        .then((response) => {
          this.updateClusterId(response.data.data.cluster_id);
          this.formData = Object.assign({}, response.data.data);
          if (this.formData.status_id == 99) {
            this.closedBreakdown = true;
          }
          this.loadBreakTypeList();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign(this.formData);

      formData.cluster_id = this.clusterId;

      this.isLoading = true;

      if (this.id) {
        if (this.formData.status_id != 99) {
          this.formData.close_text = undefined;
        }
        this.$api
          .put(`breakdowns/${this.id}`, formData)
          .then(() => {
            this.$toast.success(this.$t("saved"));
            if (this.formData.status_id == 99) {
              this.closedBreakdown = true;
              this.checkBreakdownList();
            }
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      } else {
        this.$api
          .post(`breakdowns`, formData)
          .then((res) => {
            this.$toast.success(this.$t("saved"));
            this.checkBreakdownList();
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            } else {
              this.$router.push({
                name: "communication.breakdowns.edit",
                params: { id: res.data.data.id },
              });
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      }
    },
    loadBreakTypeList() {
      const params = {
        cluster_id: this.clusterId,
      };

      this.$api
        .query(`/clusters/${this.clusterId}/breakdown-types/`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.breakdownTypeList = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkBreakdownList() {
      const params = {
        order_by: "date",
        order: "asc",
        page: 1,
        per_page: 5,
        status_ids: [10, 1, 5],
      };

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      this.$api.query("breakdowns", { params }).then((response) => {
        this.updateBreakdownRecentList(response.data.data);
        this.updateBreakdownCount(response.data.meta.total);
      });
    },
    handleCancelClick() {
      this.$router.push({
        name: "communication.breakdowns.list",
      });
    },
    handleBreakdownTypeCreated(breakdownType) {
      this.loadBreakTypeList();
      this.formData.breakdown_type_id = breakdownType.id;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      } else {
        this.loadBreakTypeList();
      }
    });
  },
};
</script>
