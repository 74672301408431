<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Sakin-BB Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-select-resident
              label="Sakin Seçin"
              :cluster-id="clusterId"
              v-model="formData.resident_id"
              :readonly="disabled"
              :filled="disabled"
              required
              @change="formData.house_id = null"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select-house
              label="BB Seçin"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              v-model="formData.house_id"
              :readonly="disabled"
              :filled="disabled"
              required
              :disabled="!formData.resident_id"
              @change:option="handleHouseSelected"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  name: "BatchNotificationHouseUserForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      batchId: null,
      id: null,
      disabled: false,
    };
  },
  methods: {
    show(batchId) {
      this.batchId = batchId || null;

      this.formData = {
        resident_id: null,
        house_id: null,
        house_user_id: null,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleHouseSelected(value) {
      if (!value.id) {
        this.formData.house_user_id = null;
      } else {
        this.formData.house_user_id = value.item.house_user_id;
      }
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.house_user_ids = [formData.house_user_id];

      this.$api
        .post(`batch-notifications/${this.batchId}/house-users`, formData)
        .then(() => {
          this.$toast.success("Eklendi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
