<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bildirim Tedarikçisi Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-select
              :items="notificationProviderListShown"
              :rules="[rules.required]"
              label="Bildirim Tedarikçisi"
              v-model="formData.notification_provider_id"
              :readonly="disabled || this.id"
              :filled="disabled || this.id"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              v-model="formData.is_active"
              :disabled="disabled"
              :label="formData.is_active ? 'Aktif' : 'Pasif'"
              hide-details="auto"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              v-model="formData.is_default"
              :disabled="disabled"
              label="Varsayılan"
              hide-details="auto"
            ></v-switch>
          </v-col>

          <v-col cols="12" v-if="formData.notification_provider_id === 1">
            <v-divider class="my-6" />

            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.sms_username')"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataGoldSMS.username, 1),
                    rules.maxLength(formDataGoldSMS.username, 50),
                  ]"
                  v-model="formDataGoldSMS.username"
                  :disabled="disabled"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.sms_password')"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataGoldSMS.password, 1),
                    rules.maxLength(formDataGoldSMS.password, 50),
                  ]"
                  v-model="formDataGoldSMS.password"
                  :disabled="disabled"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  label="SMS Başlık"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataGoldSMS.title, 1),
                    rules.maxLength(formDataGoldSMS.title, 50),
                  ]"
                  v-model="formDataGoldSMS.title"
                  :disabled="disabled"
                  required
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="formData.notification_provider_id === 2">
            <v-divider class="my-6" />

            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.sms_username')"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataMasGSM.username, 1),
                    rules.maxLength(formDataMasGSM.username, 50),
                  ]"
                  v-model="formDataMasGSM.username"
                  :disabled="disabled"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.sms_password')"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataMasGSM.password, 1),
                    rules.maxLength(formDataMasGSM.password, 50),
                  ]"
                  v-model="formDataMasGSM.password"
                  :disabled="disabled"
                  required
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  label="SMS Başlık"
                  :rules="[
                    rules.required,
                    rules.minLength(formDataMasGSM.title, 1),
                    rules.maxLength(formDataMasGSM.title, 50),
                  ]"
                  v-model="formDataMasGSM.title"
                  :disabled="disabled"
                  required
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "ClusterNotificationProviderForm",
  mixins: [hasForm, hasPermissions],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "notificationProviderList"]),
    notificationProviderListShown() {
      if (!this.notificationProviderList) {
        return [];
      }

      return this.notificationProviderList.filter((v) => v.is_addable);
    },
  },
  data() {
    return {
      formData: {
        notification_provider_id: 1,
        is_active: true,
        is_default: true,
      },
      formDataGoldSMS: {
        username: null,
        password: null,
        title: null,
      },
      formDataMasGSM: {
        username: null,
        password: null,
        title: null,
      },
      id: null,
      disabled: false,
      extraParams: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id, disabled, extraParams) {
      this.formData = Object.assign(
        {
          notification_provider_id: 1,
          is_active: true,
          is_default: true,
        },
        extraParams || {}
      );

      this.originalData = {};
      this.extraParams = {};

      this.$refs.sidePanel.show();
      this.id = null;
      if (id) {
        this.id = id;
        this.load();
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    load() {
      this.isLoading = true;

      this.$api
        .get("cluster-notification-providers/" + this.id)
        .then((response) => {
          this.loadData(response);

          if (this.formData.notification_provider_id === 1) {
            this.formDataGoldSMS = this.originalData.credentials;
          } else if (this.formData.notification_provider_id === 2) {
            this.formDataMasGSM = this.originalData.credentials;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      const params = Object.assign({}, this.extraParams, formData);
      params.cluster_id = this.clusterId;

      if (params.notification_provider_id === 1) {
        params.goldsms = Object.assign({}, this.formDataGoldSMS);
      } else if (params.notification_provider_id === 2) {
        params.masgsm = Object.assign({}, this.formDataMasGSM);
      }

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put("cluster-notification-providers/" + this.id, params)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("updated", response.data.data);
            this.$emit("saved", response.data.data);
            this.$nextTick(() => this.hide());
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("cluster-notification-providers", params)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("created", response.data.data);
            this.$emit("saved", response.data.data);
            this.$nextTick(() => this.hide());
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
