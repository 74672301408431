<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bağımsız Bölüm Borç Tahsilat</template>
    <template slot="body">
      <p>{{ selected.length }} adet borç için tahsilat yapıyorsunuz.</p>

      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col cols="12" sm="6">
            <!-- BUG: constantly reloading the list -->
            <rs-select-resident
              v-if="house && cluster && resident_id && !resident"
              label="Ödeyen"
              v-model="resident_id"
              :rules="[rules.required]"
              :cluster-id="cluster.id"
              :house-id="house.id"
              readonly
              filled
              required
            />
            <rs-text-field
              v-else-if="resident"
              label="Ödeyen"
              :value="resident.name"
              readonly
              filled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select
              label="Tahsilat Türü"
              :items="collectingTypeList"
              v-model="formData.collecting_type_id"
              :rules="[rules.required]"
              required
              readonly
              filled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-datepicker
              :label="$t('labels.date')"
              v-model="formData.collected_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select-collecting
              label="Tahsilat Yeri"
              :rules="[rules.required]"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              required
              :readonly="disabled"
              :filled="disabled"
              can-collect
              is-active
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, total_amount),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="Tahsilat Makbuz No"
              v-model="formData.collecting_number"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <!-- <v-col cols="12">
            <rs-select-project
              v-model="formData.project_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col> -->
          <v-col cols="12" sm="6">
            <v-switch
              label="Tahsilat Mobil Bildirimi Gönder"
              v-model="formData.send_push_notification"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              label="Makbuz Yazdır"
              v-model="formData.print_receipt"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <CollectingReceiptForm
        :house="house"
        ref="collectingReceiptForm"
        :selected="[{ collecting_id: id, resident_id: this.resident_id }]"
        :z-index="100"
        @hidden="handleCollectingReceiptFormHidden"
      />

      <DebtList :list="selected" />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import { CollectingReceiptForm } from "@/view/pages/income/forms";
import DebtList from "./DebtList";

export default {
  name: "DebtCollectingForm",
  mixins: [hasForm],
  components: {
    CollectingReceiptForm,
    DebtList,
  },
  props: {
    house: {
      type: Object,
      required: false,
      default: null,
    },
    resident: {
      type: Object,
      required: false,
      default: null,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["cluster", "collectingTypeList"]),
  },
  data() {
    return {
      formData: {},
      resident_id: null,
      total_amount: null,
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();

      this.formData = {
        resident_id: null,
        safe_id: null,
        bank_account_id: null,
        collected_on: new Date().toISOString().substr(0, 10),
        amount: null,
        collecting_number: null,
        // project_id: null,
        send_push_notification: true,
        print_receipt: false,
        description: null,
        collecting_type_id: null,
      };
      this.total_amount = null;

      let amount = 0;
      let incomeTypeId = this.selected[0].income_type_id;
      let description = [];

      for (const index in this.selected) {
        const item = this.selected[index];
        amount += item.total_balance * 100;

        if (this.selected[index].income_type_id !== incomeTypeId) {
          incomeTypeId = null;
        }

        description.push(`[${item.description}]`);
      }

      this.formData.amount = amount / 100;
      this.total_amount = amount / 100;
      this.formData.description = description.join(" ");

      this.resident_id = this.selected[0].resident_id;

      if (incomeTypeId) {
        for (const index in this.collectingTypeList) {
          if (this.collectingTypeList[index].income_type_id === incomeTypeId) {
            this.formData.collecting_type_id =
              this.collectingTypeList[index].id;
            break;
          }
        }
      }

      if (!this.formData.collecting_type_id) {
        this.formData.collecting_type_id = 1;
      }
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      formData.debt_ids = this.selected.map((item) => item.debt_id);

      this.$api
        .post("income/debt-collectings", formData)
        .then((response) => {
          const debtCount = response.data.data.debt_collectings.length;
          this.$toast.success(`${debtCount} adet borç ödemesi yapıldı.`);
          this.$emit("saved", response.data.data);
          if (this.formData.print_receipt) {
            this.id = response.data.data.id;
            this.$refs.collectingReceiptForm.show();
          } else {
            this.$nextTick(() => this.hide());
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCollectingReceiptFormHidden() {
      this.hide();
    },
  },
};
</script>
