<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Avans - Borç Eşleştirme</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col cols="6">
            <rs-money-field
              v-model="formData.amount"
              label="Avanstan Kullanılacak Tutar"
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, maxAmount),
                    ]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="formData.send_push_notification"
              label="Tahsilat Bildirimi Gönder"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select-resident
              label="Borçlu Sakin"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="houseId && onlyHouseUsers ? houseId : null"
              @change="handleResidentChange"
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              v-if="houseId"
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              @change="handleOnlyHouseUsersChange"
            ></v-switch>
          </v-col>
        </v-row>

        <DebtList
          :list="listShown"
          @change="handleDebtChange"
          selectable
          multiple
          required
          v-if="!isLoading"
        />

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";
import DebtList from "./DebtList";

export default {
  name: "CollectingDebtMatchForm",
  mixins: [hasForm],
  components: {
    DebtList,
  },
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
    listShown() {
      if (this.selected.length !== 1) {
        return [];
      }

      const list = [];

      for (const index in this.list) {
        const item = this.list[index];

        if (item.debt_id && item.total_balance > 0 && !item.has_enforcement) {
          // if (item.resident_id !== this.selected[0].resident_id) {
          //   continue;
          // }

          // if (item.house_id !== this.selected[0].house_id) {
          //   continue;
          // }

          list.push(item);
        }
      }

      return list;
    },
    maxAmount() {
      if (!this.selected.length) {
        return null;
      }
      let maxAmount = this.selected[0].total_balance;
      if (this.selectedDebt) {
        if (Math.abs(this.selectedDebt.total_balance) < maxAmount) {
          maxAmount = Math.abs(this.selectedDebt.total_balance);
        }
      }

      return -1 * maxAmount;
    },
  },
  watch: {
    maxAmount() {
      this.formData.amount = this.maxAmount;
    },
  },
  data() {
    return {
      formData: {},
      selectedDebt: null,
      list: [],
      houseId: null,
      onlyHouseUsers: true,
    };
  },
  methods: {
    show(houseId) {
      this.$refs.sidePanel.show();
      this.houseId = houseId || null;

      this.formData = {
        amount: this.maxAmount,
        send_push_notification: true,
        debt_ids: null,
      };

      if (this.selected[0].resident_id) {
        this.formData.resident_id = this.selected[0].resident_id;
        this.handleResidentChange();
      }
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleResidentChange() {
      this.list = [];

      if (!this.formData.resident_id) {
        return;
      }

      this.isLoading = true;

      this.$api
        .query(`income/residents/${this.formData.resident_id}`)
        .then((response) => {
          if (response.data.data.length) {
            this.list = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = {
        amount: this.formData.amount,
        debt_ids: this.formData.debt_ids,
      };

      this.isLoading = true;

      this.$api
        .post(
          `income/collectings/${this.selected[0].collecting_id}/collect-multiple-debts`,
          params
        )
        .then(() => {
          this.$toast.success("Avansla borç ödemesi yapıldı.");
          this.$emit("saved", this.formData);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDebtChange(list) {
      this.formData.debt_ids = list;
      // this.selectedDebt = item;
    },
    handleOnlyHouseUsersChange() {
      this.formData.resident_id = null;
      this.list = [];
    },
  },
};
</script>
