<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 v-if="id">{{ formData.subject }} Şikayet Kaydı</h3>
      <h3 v-else>Şikayet Oluşturma</h3>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#items" replace> Bilgiler </v-tab>
        <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
          <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
            {{ $t("headers.notes_and_reminders") }}
          </v-badge>
        </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          <v-badge
            color="deep-purple lighten-3"
            :content="documentCount || '0'"
          >
            {{ $t("headers.documents") }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="items">
          <div class="py-5">
            <v-form ref="form" @submit.prevent="handleFormSubmit">
              <v-row cols="12" dense>
                <v-col lg="3" cols="6">
                  <rs-datepicker
                    label="Şikayet Tarihi"
                    v-model="formData.date"
                    :disabled="closedComplaint"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Şikayet Durumu"
                    v-model="formData.status_id"
                    :items="careStatusList"
                    :disabled="closedComplaint || !id"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-text-field
                    label="Şikayet Konu"
                    :rules="[rules.required]"
                    v-model="formData.subject"
                    :disabled="closedComplaint"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <v-switch
                    label="Faaliyet Raporuna Ekle"
                    v-model="formData.add_to_report"
                    hide-details="auto"
                    :disabled="closedComplaint"
                  />
                </v-col>
                <v-col cols="6">
                  <rs-select-provider
                    label="Şikayeti Giderecek"
                    v-model="formData.provider_id"
                    :cluster-id="clusterId"
                    :disabled="closedComplaint"
                  >
                  </rs-select-provider>
                </v-col>
                <v-col cols="6">
                  <rs-select-resident
                    label="Şikayeti Bildiren"
                    v-model="formData.resident_id"
                    :cluster-id="clusterId"
                    :disabled="closedComplaint"
                  />
                </v-col>
                <v-col cols="12">
                  <rs-textarea
                    label="Şikayet Açıklama"
                    v-model="formData.description"
                    rows="5"
                    no-resize
                    :disabled="closedComplaint"
                  />
                </v-col>
                <v-col v-if="id" cols="4">
                  <rs-textarea
                    label="Şikayet Durum Açıklaması"
                    v-model="formData.status_text"
                    rows="5"
                    :disabled="closedComplaint"
                    no-resize
                  />
                </v-col>
                <v-col cols="4" v-if="formData.status_id == 99">
                  <rs-textarea
                    label="Şikayet Kapatma Açıklaması"
                    v-model="formData.close_text"
                    :rules="[
                      formData.status_id == 99 ? rules.required : null,
                      rules.minLength(formData.close_text, 10),
                    ]"
                    rows="5"
                    no-resize
                    :disabled="closedComplaint"
                    hint="Faaliyet raporunda gözükür"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="4" v-if="formData.status_id == 99">
                  <rs-datepicker
                    label="Şikayet Kapatma Tarihi"
                    v-model="formData.closed_on"
                    :disabled="closedComplaint"
                    hint="Faaliyet raporunda bu tarih gözükür"
                    :rules="[
                      rules.minOrEqualDate(formData.closed_on, formData.date),
                      rules.maxOrEqualDate(formData.closed_on, new Date()),
                    ]"
                  />
                </v-col>
              </v-row>
              <rs-form-buttons
                v-if="!closedComplaint"
                :is-loading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
              />
            </v-form>
          </div>
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4" :eager="id">
          <NoteList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="noteCount"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4" :eager="id">
          <DocumentList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="documentCount"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import { mapGetters, mapActions } from "vuex";
import DocumentList from "@/view/content/documents/DocumentList";
import NoteList from "@/view/content/Notes/NoteList";

export default {
  name: "ComplaintForm",
  mixins: [hasForm, hasPermissions],
  components: {
    DocumentList,
    NoteList,
  },
  computed: {
    ...mapGetters(["clusterId", "careStatusList", "companyId"]),
    extraParams() {
      return { care_id: this.id };
    },
  },
  props: {
    /**
     * Complaint ID
     * @type {Number}
     */
    id: {
      type: Number,
    },
  },
  data() {
    return {
      formData: {
        add_to_report: true,
        date: new Date().toJSON().split("T")[0],
        closed_on: null,
      },
      selectedTab: null,
      isLoading: false,
      closedComplaint: false,
      documentCount: null,
      noteCount: null,
    };
  },
  methods: {
    ...mapActions([
      "updateClusterId",
      "updateComplaintRecentList",
      "updateComplaintCount",
    ]),
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("complaints", this.id)
        .then((response) => {
          this.updateClusterId(response.data.data.cluster_id);
          this.formData = Object.assign({}, response.data.data);
          if (this.formData.status_id == 99) {
            this.closedComplaint = true;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign(this.formData);

      formData.cluster_id = this.clusterId;

      this.isLoading = true;

      if (this.id) {
        if (this.formData.status_id != 99) {
          this.formData.close_text = undefined;
        }
        this.$api
          .put(`complaints/${this.id}`, formData)
          .then(() => {
            this.$toast.success(this.$t("saved"));
            if (this.formData.status_id == 99) {
              this.closedComplaint = true;
              this.checkComplaintList();
            }
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      } else {
        this.$api
          .post(`complaints`, formData)
          .then((res) => {
            this.$toast.success(this.$t("saved"));
            this.checkComplaintList();
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            } else {
              this.$router.push({
                name: "communication.complaints.edit",
                params: { id: res.data.data.id },
              });
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      }
    },
    checkComplaintList() {
      const params = {
        order_by: "date",
        order: "asc",
        page: 1,
        per_page: 5,
        status_ids: [10, 1, 5],
      };

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      this.$api.query("complaints", { params }).then((response) => {
        this.updateComplaintRecentList(response.data.data);
        this.updateComplaintCount(response.data.meta.total);
      });
    },
    handleCancelClick() {
      this.$router.push({
        name: "communication.complaints.list",
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
};
</script>
