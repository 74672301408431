<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        Şirket Çalışanı
        <small v-if="originalData.id">
          {{ originalData.name }}
        </small>
      </h3>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-subtitle>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>{{ $t("headers.info") }}</v-tab>
        <v-tab
          to="#clusters"
          :disabled="
            !id ||
            !originalData ||
            !originalData.role ||
            (originalData.role.name !== 'companyworker' &&
              originalData.role.name !== 'companyworkerbasic' &&
              originalData.role.name !== 'doorman')
          "
          replace
        >
          {{ $t("headers.clusters_authorized") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form @submit.prevent="handleFormSubmit" ref="form">
            <v-row dense>
              <v-col lg="4" cols="6">
                <rs-text-field
                  :label="$t('labels.name_surname')"
                  :rules="[rules.required, rules.maxLength(formData.name, 300)]"
                  v-model="formData.name"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>
              <v-col lg="4" cols="6">
                <rs-select-role
                  :label="$t('labels.user_role')"
                  role-type="company"
                  v-model="formData.role_id"
                  :rules="[rules.required]"
                  :readonly="disabled"
                  :filled="disabled"
                  single
                />
              </v-col>
              <v-col lg="4" cols="6">
                <rs-tel-field
                  :label="$t('labels.phone')"
                  v-model="formData.phone"
                  :disabled="disabled"
                  :rules="[
                    rules.required,
                    formData.phone != ''
                      ? rules.minLength(formData.phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                />
              </v-col>

              <v-col lg="4" cols="6">
                <rs-text-field
                  :label="$t('labels.email')"
                  :rules="[
                    rules.required,
                    rules.email,
                    rules.maxLength(formData.email, 120),
                  ]"
                  v-model="formData.email"
                  type="email"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col lg="4" cols="6">
                <rs-select
                  label="Görevi"
                  v-model="formData.position_id"
                  :items="workerPositionsList.filter((item) => item.is_active)"
                  :readonly="disabled"
                  :filled="disabled"
                >
                  <template slot="append-outer" v-if="!disabled">
                    <v-btn
                      small
                      outlined
                      :to="{ name: 'my-company.worker-positions.create' }"
                      color="pink"
                      title="Yeni görev tanımlamak için tıklayın"
                    >
                      Ekle
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template></rs-select
                >
              </v-col>

              <v-col lg="2" cols="4" v-if="id">
                <v-switch
                  :label="$t('labels.has_push_notifications')"
                  v-model="formData.has_push_notifications"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col
                lg="2"
                cols="4"
                v-if="id && originalData.has_push_notifications"
              >
                <rs-text-field
                  :label="$t('labels.mobile_os')"
                  v-model="originalData.mobile_os"
                  disabled
                />
              </v-col>

              <v-col lg="2" cols="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="$t('labels.is_active')"
                  :readonly="disabled"
                  :filled="disabled"
                ></v-switch>
              </v-col>
            </v-row>

            <rs-form-buttons
              :is-loading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="clusters" class="pa-4" eager>
          <WorkerClusterList :user-id="id" v-if="id" :disabled="disabled" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import WorkerClusterList from "./WorkerClusterList";

export default {
  name: "WorkerForm",
  mixins: [hasForm],
  components: {
    WorkerClusterList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["company", "workerPositionsList"]),
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
        email: null,
        phone: null,
        is_active: true,
        role_id: null,
        postion_id: null,
        has_push_notifications: true,
      },
    };
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
  methods: {
    load() {
      this.isLoading = true;

      this.$api
        .query(`company-users/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);

          this.formData.name = this.originalData.user.name;
          this.formData.phone = this.originalData.user.phone;
          this.formData.email = this.originalData.user.email;
          this.formData.role_id = this.originalData.role_id;
          this.formData.has_push_notifications =
            this.originalData.user.has_push_notifications;

          this.formData.position_id = this.originalData.position_id;
          this.formData.is_active = this.originalData.is_active;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`company-users/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.company_id = this.company.id;

        this.$api
          .post(`company-users`, formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "my-company.workers.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>
