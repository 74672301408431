<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-chart-bar-stacked</v-icon>
        &nbsp;Gelir - Gider (Özet)
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit('pdf')" ref="form">
          <v-row dense>
            <v-col sm="3">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-select
                label="Muhasebe Türü"
                v-model="formData.type_id"
                :items="[
                  { id: 1, name: 'Tahakkuk Esaslı' },
                  { id: 2, name: 'Ödeme Esaslı' },
                ]"
                :rules="[rules.required]"
                required
                :hint="
                  formData.type_id === 1
                    ? 'Tahakkuk esaslı raporun gelir özet kısmında borçların sadece ana para kısmı gösterilir.'
                    : ''
                "
              />
            </v-col>

            <v-col sm="3">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <p v-if="iframeUrl" class="mb-0 text-h5">
          <a :href="iframeUrl" download="rapor.pdf" target="_blank">
            Raporu cihazınıza PDF formatında indirmek için tıklayın.
          </a>
        </p>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm } from "@/view/mixins";
import { slugify } from "@/core/helpers";

export default {
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  watch: {
    clusterId() {
      this.iframeUrl = null;
    },
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
    );
    const max = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );

    return {
      iframeUrl: null,
      reportData: null,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
        type_id: 1,
      },
    };
  },
  methods: {
    handleFormSubmit(format) {
      format = format || "pdf";
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = Object.assign(this.buildSearchParams(this.formData));
      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query(`reports/income-expense-review-report/${this.clusterId}`, {
          params,
        })
        .then((response) => {
          this.reportData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.reportData[index] = response.data.data[index];
            }
          }
          this.reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          this.reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();

          for (const index in this.reportData.past_periods) {
            const item = this.reportData.past_periods[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }

          for (const index in this.reportData.current_periods) {
            const item = this.reportData.current_periods[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }

          for (const index in this.reportData.expenses) {
            const item = this.reportData.expenses[index];

            if (this.formData.type_id === 2) {
              item.amount = this.numberToLocaleFormat(item.collecting);
            } else {
              item.amount = this.numberToLocaleFormat(item.amount);
            }
          }

          if (this.formData.type_id === 2) {
            this.reportData.expense_types = this.reportData.expense_types
              .filter((item) => item.collecting !== 0)
              .map((item) => {
                item.amount = this.numberToLocaleFormat(item.collecting);
                return item;
              });

            let incomeTotal = 0.0;

            this.reportData.income_types = this.reportData.income_types
              .map((item) => {
                item.amount = item.collecting;
                return item;
              })
              .filter((item) => item.amount !== 0)
              .map((item) => {
                incomeTotal = (incomeTotal * 100 + item.amount * 100) / 100;
                item.amount = this.numberToLocaleFormat(item.amount);
                return item;
              });

            this.reportData.total_sums.expense_type_sums.amount =
              this.numberToLocaleFormat(
                this.reportData.total_sums.expense_type_sums.collecting
              );
            this.reportData.total_sums.income_type_sums.amount =
              this.numberToLocaleFormat(incomeTotal);
          } else {
            this.reportData.expense_types = this.reportData.expense_types
              .filter((item) => item.amount !== 0)
              .map((item) => {
                item.amount = this.numberToLocaleFormat(item.amount);
                return item;
              });

            let incomeTotal = 0.0;

            this.reportData.income_types = this.reportData.income_types
              .map((item) => {
                item.amount =
                  item.amount_main !== undefined
                    ? item.amount_main
                    : item.amount;
                return item;
              })
              .filter((item) => item.amount !== 0)
              .map((item) => {
                incomeTotal = (incomeTotal * 100 + item.amount * 100) / 100;
                item.amount = this.numberToLocaleFormat(item.amount);
                return item;
              });

            this.reportData.total_sums.expense_type_sums.amount =
              this.numberToLocaleFormat(
                this.reportData.total_sums.expense_type_sums.amount
              );
            this.reportData.total_sums.income_type_sums.amount =
              this.numberToLocaleFormat(incomeTotal);
          }

          this.reportData.total_sums.past_period_sums =
            this.numberToLocaleFormat(
              this.reportData.total_sums.past_period_sums
            );
          this.reportData.total_sums.current_period_sums =
            this.numberToLocaleFormat(
              this.reportData.total_sums.current_period_sums
            );

          return this.reportData;
        })
        .then((reportData) => {
          let fileName = this.cluster.name + "-";
          fileName += new Date(this.formData.date.min).toLocaleDateString();
          fileName += "-GelirGiderOzetRaporu";
          fileName += "-";
          fileName += new Date(this.formData.date.max).toLocaleDateString();

          if (fileName.length >= 100) {
            fileName = fileName.substring(0, 99);
          }

          const params = {
            file_type: format,
            print_template: "IncomeExpenseDetailReport",
            receiptData: reportData,
            file_name: slugify(fileName),
          };

          return this.$api.post("print-file", params).then((response) => {
            if (format !== "pdf") {
              const anchor = document.createElement("a");
              anchor.href = response.data.data.url;
              anchor.target = "_blank";
              anchor.click();
            } else {
              this.iframeUrl = response.data.data.url;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
