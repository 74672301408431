<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-if="budgetId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bütçe Kalemleri"
            icon=""
            @click:add="handleAddClick"
            :add-route="'#'"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            show-delete=""
          >
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <a href="#" @click.prevent="handleEditClick(item)">
            <i class="menu-icon mdi mdi-pencil"></i>
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.monthly_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actual_monthly_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actual_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.error_margin="{ value }">
          {{ "%" + value }}
        </template>

        <!-- eslint-enable -->
      </v-data-table>
      <DetailForm
        ref="detailForm"
        @saved="handleDetailFormSaved"
        @updated="handleDetailFormSaved"
      />
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasExport } from "../../../../mixins";
import DetailForm from "./DetailForm.vue";

export default {
  mixins: [hasDataTable, hasExport],
  computed: {
    ...mapGetters(["expenseTypeList"]),
  },
  props: {
    /**
     * Budget ID
     */
    budgetId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  components: {
    DetailForm,
  },
  data() {
    return {
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      titleBarAttrs: {
        exportUrl: () => `budget-items`,
        exportParams: this.getParams,
      },
      itemsPerPage: 100,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Gider Türü",
          value: "expense_type_name",
          searchValue: "expense_type_ids",
          searchable: "multiselect",
          options: () => this.expenseTypeList,
        },
        {
          text: "Gider Tipleri",
          value: "child_expense_types",
          searchable: "text",
        },
        {
          text: "Dağıtım Anahtarı",
          value: "distribution_type_name",
          searchValue: "distribution_type_ids",
          searchable: "multiselect",
          options: () => this.budgetDistributionTypeList,
          align: "center",
        },
        {
          text: "Tutar",
          value: "monthly_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Toplam Tutar",
          value: "total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gerçekleşen Aylık Tutar",
          value: "actual_monthly_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gerçekleşen Tutar",
          value: "actual_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sapma Payı",
          value: "error_margin",
          searchable: "number",
          align: "end",
        },
        {
          text: "Grup",
          value: "budget_item_group_name",
          searchable: "text",
        },
        {
          text: "Bloklar",
          value: "block_names",
          searchable: "text",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.generateExportColumns(this.headers);
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.budgetId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query(`budget-items`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.budget_id = this.budgetId;
      params.order_by = "expense_type_name";

      return params;
    },
    handleEditClick(item) {
      if (this.isLoading) {
        return false;
      }
      let id = null;
      if (item && item.id) {
        id = item.id;
      } else {
        if (this.selectedItems.length !== 1) {
          return false;
        } else {
          id = this.selectedItems[0].id;
        }
      }

      this.$refs.detailForm.show(id, this.budgetId);
    },
    handleAddClick() {
      this.$refs.detailForm.show(null, this.budgetId);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`budget-items/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "Bütçe detay kaydını silmek istediğinizden emin misiniz?"
      );
    },
    handleDetailFormSaved() {
      this.loadList();
      this.$emit("saved");
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    budgetId: {
      handler() {
        this.loadList();
      },
    },
  },
};
</script>
