var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Bildirim Tedarikçisi Ekle")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"12"}},[_c('rs-select',{attrs:{"items":_vm.notificationProviderListShown,"rules":[_vm.rules.required],"label":"Bildirim Tedarikçisi","readonly":_vm.disabled || this.id,"filled":_vm.disabled || this.id},model:{value:(_vm.formData.notification_provider_id),callback:function ($$v) {_vm.$set(_vm.formData, "notification_provider_id", $$v)},expression:"formData.notification_provider_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"disabled":_vm.disabled,"label":_vm.formData.is_active ? 'Aktif' : 'Pasif',"hide-details":"auto"},model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"disabled":_vm.disabled,"label":"Varsayılan","hide-details":"auto"},model:{value:(_vm.formData.is_default),callback:function ($$v) {_vm.$set(_vm.formData, "is_default", $$v)},expression:"formData.is_default"}})],1),(_vm.formData.notification_provider_id === 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-6"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":_vm.$t('labels.sms_username'),"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataGoldSMS.username, 1),
                  _vm.rules.maxLength(_vm.formDataGoldSMS.username, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataGoldSMS.username),callback:function ($$v) {_vm.$set(_vm.formDataGoldSMS, "username", $$v)},expression:"formDataGoldSMS.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":_vm.$t('labels.sms_password'),"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataGoldSMS.password, 1),
                  _vm.rules.maxLength(_vm.formDataGoldSMS.password, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataGoldSMS.password),callback:function ($$v) {_vm.$set(_vm.formDataGoldSMS, "password", $$v)},expression:"formDataGoldSMS.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":"SMS Başlık","rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataGoldSMS.title, 1),
                  _vm.rules.maxLength(_vm.formDataGoldSMS.title, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataGoldSMS.title),callback:function ($$v) {_vm.$set(_vm.formDataGoldSMS, "title", $$v)},expression:"formDataGoldSMS.title"}})],1)],1)],1):_vm._e(),(_vm.formData.notification_provider_id === 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-6"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":_vm.$t('labels.sms_username'),"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataMasGSM.username, 1),
                  _vm.rules.maxLength(_vm.formDataMasGSM.username, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataMasGSM.username),callback:function ($$v) {_vm.$set(_vm.formDataMasGSM, "username", $$v)},expression:"formDataMasGSM.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":_vm.$t('labels.sms_password'),"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataMasGSM.password, 1),
                  _vm.rules.maxLength(_vm.formDataMasGSM.password, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataMasGSM.password),callback:function ($$v) {_vm.$set(_vm.formDataMasGSM, "password", $$v)},expression:"formDataMasGSM.password"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":"SMS Başlık","rules":[
                  _vm.rules.required,
                  _vm.rules.minLength(_vm.formDataMasGSM.title, 1),
                  _vm.rules.maxLength(_vm.formDataMasGSM.title, 50),
                ],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formDataMasGSM.title),callback:function ($$v) {_vm.$set(_vm.formDataMasGSM, "title", $$v)},expression:"formDataMasGSM.title"}})],1)],1)],1):_vm._e()],1),_c('rs-form-buttons',{attrs:{"hide-submit":"","is-loading":_vm.isLoading},on:{"cancel":function($event){return _vm.hide()},"submit":_vm.handleFormSubmit}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }