<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Kullanıcı</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Kullanıcı Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          @click="loginAsUser"
          v-if="id"
          icon
          title="Kullanıcıyla giriş yap"
          tooltip="Kullanıcıyla giriş yap"
        >
          <v-icon>mdi-key</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <v-tab to="#residents" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.resident_count || '0'"
          >
            Sakinler
          </v-badge>
        </v-tab>
        <v-tab to="#company-users" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.company_user_count || '0'"
          >
            Şirketler
          </v-badge>
        </v-tab>
        <v-tab to="#devices" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.device_count || '0'"
          >
            Cihazlar
          </v-badge>
        </v-tab>
        <v-tab to="#cards" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.card_count || '0'"
          >
            Kayıtlı Kartlar
          </v-badge>
        </v-tab>
        <v-tab to="#subscriptions" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.subscription_count || '0'"
          >
            Otomatik Ödemeler
          </v-badge>
        </v-tab>
        <v-tab to="#notifications" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.notification_count || '0'"
          >
            Bildirimler
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form
            :readonly="disabled"
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 120)]"
                  v-model="formData.name"
                  hide-details="auto"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-tel-field
                  :label="$t('labels.phone')"
                  :rules="[
                    isRequired('phone') ? rules.required : true,
                    formData.phone != ''
                      ? rules.minLength(formData.phone, 7)
                      : null,
                    rules.numbersOnlyWithParam,
                  ]"
                  v-model="formData.phone"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.email')"
                  :rules="[
                    rules.maxLength(formData.email, 100),
                    isRequired('email') ? rules.required : true,
                  ]"
                  v-model="formData.email"
                  type="email"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  class="identity-number"
                  :label="$t('labels.identity_no')"
                  v-model="formData.identity_number"
                  :rules="[
                    rules.exactLength(formData.identity_number, 11),
                    rules.numbersOnly,
                    isRequired('identity') && formData.citizenship_id === 230
                      ? rules.required
                      : true,
                  ]"
                  maxlength="11"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  label="Pasaport Numarası"
                  v-model="formData.passport_no"
                  :rules="[rules.maxLength(formData.passport_no, 150)]"
                  maxlength="150"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-select-role
                  label="Kullanıcı Rolleri"
                  role-type="cluster"
                  v-model="formData.role_ids"
                  :rules="[phoneIsRequired]"
                  :readonly="!formData.phone"
                  :filled="!formData.phone"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col sm="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="$t('labels.is_active')"
                  hide-details="auto"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_email"
                  label="E-posta Gönderilsin mi?"
                  hide-details="auto"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_notice"
                  label="Tebligat Gönderilsin mi?"
                  hide-details="auto"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_push_notifications"
                  label="Mobil Bildirim Gönderilsin mi?"
                  hide-details="auto"
                ></v-switch>
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.send_sms"
                  label="SMS Gönderilsin mi?"
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="residents" class="pa-4">
          <ResidentList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="company-users" class="pa-4">
          <CompanyUserList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="company-users" class="pa-4"> </v-tab-item>
        <v-tab-item value="devices" class="pa-4">
          <TokenList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="cards" class="pa-4">
          <UserCardList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="subscriptions" class="pa-4">
          <SubscriptionList :user-id="id" />
        </v-tab-item>
        <v-tab-item value="notifications" class="pa-4">
          <NotificationList :user-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import {
  CompanyUserList,
  NotificationList,
  ResidentList,
  SubscriptionList,
  TokenList,
  UserCardList,
} from "../components";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [hasForm, hasPermissions],
  components: {
    CompanyUserList,
    NotificationList,
    ResidentList,
    SubscriptionList,
    TokenList,
    UserCardList,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
        phone: null,
        email: null,
        send_email: false,
        send_notice: false,
        send_push_notifications: false,
        send_sms: false,
        role_ids: [],
        identity_number: null,
        passport_no: null,
      },
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/users/${this.id}`)
        .then((response) => {
          this.loadData(response);

          const roleIds = [];

          if (response.data.data.roles) {
            for (const index in response.data.data.roles) {
              roleIds.push(response.data.data.roles[index].id);
            }
          }

          if (roleIds.length) {
            this.formData.role_ids = roleIds;
          }

          this.totals = response.data.data.totals;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`residents/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);

            if (response.data.data.user === null) {
              this.formData.user = {};
            }

            const roleIds = [];

            if (response.data.data.roles) {
              for (const index in response.data.data.roles) {
                roleIds.push(response.data.data.roles[index].id);
              }
            }

            if (roleIds.length) {
              this.formData.role_ids = roleIds;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/residents`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.residents.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Resident", this.id);
    },
    isRequired(fieldName) {
      if (
        !this.cluster ||
        !this.cluster.resident_required_fields ||
        !this.cluster.resident_required_fields.length
      ) {
        return false;
      }

      return this.cluster.resident_required_fields.indexOf(fieldName) !== -1;
    },
    phoneIsRequired(value) {
      if (
        this.isRequired("phone") &&
        this.rules.required(value) !== true &&
        this.rules.required(this.formData.phone) !== true
      ) {
        return "Rol seçebilmek için telefon numarası girmelisiniz.";
      }

      return true;
    },
    loginAsUser() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`api-super-admin/users/create-token/${this.id}`)
        .then((response) => {
          const data = response.data.data;
          JwtService.setTemporaryToken(data.token, data.token_id);
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.identity-number input {
  letter-spacing: 0.3vw;
}
</style>
