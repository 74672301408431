<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 v-if="id">{{ formData.subject }} Bakım Tanım Kaydı</h3>
      <h3 v-else>Bakım Tanım Oluşturma</h3>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#items" replace> Bilgiler </v-tab>
        <v-tab
          to="#documents"
          replace
          :disabled="!id"
          v-if="can('see-document')"
        >
          <v-badge
            color="deep-purple lighten-3"
            :content="documentCount || '0'"
          >
            {{ $t("headers.documents") }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="items">
          <div class="py-5">
            <v-form ref="form" @submit.prevent="handleFormSubmit">
              <v-row dense>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Bakım Türü"
                    :rules="[rules.required]"
                    :items="maintenanceTypes"
                    v-model="formData.maintenance_type_id"
                    @change="handleMaintenanceTypeChange()"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    label="Bakım Tipi"
                    :items="childMaintenanceTypes"
                    v-model="formData.child_maintenance_type_id"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-text-field
                    label="Bakım Konu"
                    :rules="[rules.required]"
                    v-model="formData.subject"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select-provider
                    label="Bakımı Giderecek"
                    v-model="formData.provider_id"
                    :cluster-id="clusterId"
                  >
                  </rs-select-provider>
                </v-col>
                <v-col cols="3">
                  <v-switch
                    label="Faaliyet Raporuna Ekle"
                    v-model="formData.add_to_report"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="3">
                  <v-switch
                    v-model="formData.is_active"
                    :label="$t('labels.is_active')"
                    hide-details="auto"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <rs-textarea
                    label="Bakım Açıklama"
                    v-model="formData.description"
                    rows="5"
                    no-resize
                  />
                </v-col>
                <v-col cols="12">
                  <rs-select
                    v-model="formData.reminder_period_id"
                    :label="$t('labels.reminder')"
                    clearable
                    @change="handleReminderPeriodChange"
                    :items="[
                      {
                        id: 1,
                        name: 'Tek Sefer',
                      },
                      {
                        id: 2,
                        name: 'Günlük',
                      },
                      {
                        id: 3,
                        name: 'Haftalık',
                      },
                      {
                        id: 4,
                        name: 'Aylık',
                      },
                      {
                        id: 5,
                        name: 'Yıllık',
                      },
                    ]"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                v-if="formData.reminder_period_id === 1"
              >
                <v-col cols="6">
                  <rs-datepicker
                    :label="$t('labels.reminder_date')"
                    v-model="formData.reminder_date"
                    :rules="
                      disabled
                        ? []
                        : [
                            rules.required,
                            rules.min(
                              formData.reminder_date,
                              new Date().toISOString().substr(0, 10)
                            ),
                          ]
                    "
                    :disabled="disabled"
                    :min="new Date().toISOString().substr(0, 10)"
                  />
                </v-col>
                <v-col cols="6">
                  <rs-timepicker
                    v-model="formData.remind_at"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                v-if="formData.reminder_period_id === 2"
              >
                <v-col cols="12">
                  <rs-timepicker
                    v-model="formData.remind_at"
                    :rules="[rules.required]"
                    prefix="Her Gün"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                v-if="formData.reminder_period_id === 3"
              >
                <v-col cols="6">
                  <rs-select
                    v-model="formData.reminder_day_id"
                    :rules="[rules.required]"
                    prefix="Her"
                    suffix="Günü"
                    :items="[
                      {
                        id: 3,
                        name: 'Pazartesi',
                      },
                      {
                        id: 4,
                        name: 'Salı',
                      },
                      {
                        id: 5,
                        name: 'Çarşamba',
                      },
                      {
                        id: 6,
                        name: 'Perşembe',
                      },
                      {
                        id: 7,
                        name: 'Cuma',
                      },
                      {
                        id: 8,
                        name: 'Cumartesi',
                      },
                      {
                        id: 9,
                        name: 'Pazar',
                      },
                    ]"
                  />
                </v-col>
                <v-col cols="6">
                  <rs-timepicker
                    v-model="formData.remind_at"
                    :rules="[rules.required]"
                    prefix="Saat"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                v-if="formData.reminder_period_id === 4"
              >
                <v-col lg="4" cols="6">
                  <rs-select
                    v-model="formData.reminder_day"
                    :rules="[rules.required]"
                    prefix="Her Ayın"
                    :items="monthlyReminderDays"
                  />
                </v-col>
                <v-col lg="4" cols="6">
                  <rs-select
                    v-model="formData.reminder_day_id"
                    :rules="[rules.required]"
                    :items="[
                      {
                        id: 1,
                        name: 'Günü',
                      },
                      {
                        id: 2,
                        name: 'İş Günü',
                      },
                      {
                        id: 3,
                        name: 'Pazartesi',
                      },
                      {
                        id: 4,
                        name: 'Salı',
                      },
                      {
                        id: 5,
                        name: 'Çarşamba',
                      },
                      {
                        id: 6,
                        name: 'Perşembe',
                      },
                      {
                        id: 7,
                        name: 'Cuma',
                      },
                      {
                        id: 8,
                        name: 'Cumartesi',
                      },
                      {
                        id: 9,
                        name: 'Pazar',
                      },
                    ]"
                  />
                </v-col>
                <v-col lg="4" cols="6">
                  <rs-timepicker
                    v-model="formData.remind_at"
                    :rules="[rules.required]"
                    prefix="Saat"
                  />
                </v-col>
              </v-row>

              <v-row
                dense
                justify="center"
                v-if="formData.reminder_period_id === 5"
              >
                <v-col lg="3" cols="6">
                  <rs-select
                    v-model="formData.reminder_month"
                    :rules="[rules.required]"
                    prefix="Her"
                    suffix="Ayının"
                    :items="
                      monthList.map((name, index) => {
                        return {
                          id: index + 1,
                          name: name,
                        };
                      })
                    "
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    v-model="formData.reminder_day"
                    :rules="[rules.required]"
                    :items="monthlyReminderDays"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-select
                    v-model="formData.reminder_day_id"
                    :rules="[rules.required]"
                    :items="[
                      {
                        id: 1,
                        name: 'Günü',
                      },
                      {
                        id: 2,
                        name: 'İş Günü',
                      },
                      {
                        id: 3,
                        name: 'Pazartesisi',
                      },
                      {
                        id: 4,
                        name: 'Salısı',
                      },
                      {
                        id: 5,
                        name: 'Çarşambası',
                      },
                      {
                        id: 6,
                        name: 'Perşembesi',
                      },
                      {
                        id: 7,
                        name: 'Cuması',
                      },
                      {
                        id: 8,
                        name: 'Cumartesisi',
                      },
                      {
                        id: 9,
                        name: 'Pazarı',
                      },
                    ]"
                  />
                </v-col>
                <v-col lg="3" cols="6">
                  <rs-timepicker
                    v-model="formData.remind_at"
                    :rules="[rules.required]"
                    prefix="Saat"
                  />
                </v-col>
              </v-row>
              <rs-form-buttons
                :is-loading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
              />
            </v-form>
          </div>
        </v-tab-item>
        <v-tab-item value="notes" class="pa-4" :eager="id">
          <NoteList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="noteCount"
          />
        </v-tab-item>
        <v-tab-item value="documents" class="pa-4" :eager="id">
          <DocumentList
            :allow-add="true"
            :extra-params="extraParams"
            :item-count.sync="documentCount"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import { mapGetters, mapActions } from "vuex";
import DocumentList from "@/view/content/documents/DocumentList";
import ClusterService from "@/core/services/cluster.service";

export default {
  name: "MaintenanceForm",
  mixins: [hasForm, hasPermissions],
  components: {
    DocumentList,
  },
  computed: {
    ...mapGetters(["clusterId", "maintenanceTypes", "companyId", "monthList"]),
    extraParams() {
      return { care_id: this.id };
    },
  },
  props: {
    /**
     * Maintenance ID
     * @type {Number}
     */
    id: {
      type: Number,
    },
  },
  data() {
    return {
      formData: {
        add_to_report: true,
        is_active: true,
      },
      selectedTab: null,
      isLoading: false,
      documentCount: null,
      noteCount: null,
      childMaintenanceTypes: [],
      monthlyReminderDays: [],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    handleReminderPeriodChange() {
      const newValue = this.formData.reminder_period_id;

      if (newValue === 1) {
        this.formData.reminder_date = new Date().toISOString().substr(0, 10);
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 2) {
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 3) {
        this.formData.reminder_day_id = 3;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 4) {
        this.formData.reminder_day = 1;
        this.formData.reminder_day_id = 1;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      } else if (newValue === 5) {
        this.formData.reminder_day = 1;
        this.formData.reminder_day_id = 1;
        this.formData.reminder_month = 1;
        this.formData.remind_at = new Date().toISOString().substr(11, 5);
      }
    },
    handleMaintenanceTypeChange() {
      this.childMaintenanceTypes = ClusterService.getMaintenanceTypeChildren(
        this.formData.maintenance_type_id
      );
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("maintenances", this.id)
        .then((response) => {
          this.updateClusterId(response.data.data.cluster_id);
          this.formData = Object.assign({}, response.data.data);
          this.childMaintenanceTypes =
            ClusterService.getMaintenanceTypeChildren(
              this.formData.maintenance_type_id
            );
          if (this.formData.remind_at) {
            const array = this.formData.remind_at.split(":");
            this.formData.remind_at = array[0] + ":" + array[1];
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign(this.formData);

      formData.cluster_id = this.clusterId;

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put(`maintenances/${this.id}`, formData)
          .then(() => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      } else {
        this.$api
          .post(`maintenances`, formData)
          .then((res) => {
            this.$toast.success(this.$t("saved"));
            if (event && event.closeOnSave) {
              this.handleCancelClick();
              return;
            } else {
              this.$router.push({
                name: "other-definitions.maintenance.edit",
                params: { id: res.data.data.id },
              });
            }
          })
          .catch((error) => this.handleError(error))
          .finally(() => (this.isLoading = false));
      }
    },
    handleCancelClick() {
      this.$router.push({
        name: "other-definitions.maintenance.list",
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
      for (let i = 1; i <= 31; i++) {
        this.monthlyReminderDays.push({
          id: i,
          name: i + ".",
        });
      }
    });
  },
};
</script>
