<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">
      Tedarikçi Otomatik Tahakkuk
      <small v-if="provider">{{ provider.name }}</small>
    </template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col cols="12" sm="6">
            <rs-select
              :items="autoAssessmentTimingTypeList"
              :label="$t('labels.assessment_timing_type')"
              :rules="[rules.required]"
              v-model="formData.issue_auto_assessment_timing_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              v-if="
                [1, 2, 8, 9].indexOf(
                  formData.issue_auto_assessment_timing_type_id
                ) > -1
              "
              type="number"
              min="1"
              max="31"
              step="1"
              :label="$t('labels.assessment_timing_day')"
              v-model="formData.issue_day"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              hint="Ayın 1'i ile 31'i arasında bir gün seçin"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select
              :items="autoAssessmentTimingTypeList"
              :label="$t('labels.last_payment_timing_type')"
              :rules="[rules.required]"
              v-model="formData.last_payment_auto_assessment_timing_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              v-if="
                [1, 2, 8, 9].indexOf(
                  formData.last_payment_auto_assessment_timing_type_id
                ) > -1
              "
              type="number"
              min="1"
              max="31"
              step="1"
              :label="$t('labels.last_payment_timing_day')"
              v-model="formData.last_payment_day"
              :rules="[rules.required]"
              required
              :readonly="disabled"
              :filled="disabled"
              hint="Ayın 1'i ile 31'i arasında bir gün seçin"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-money-field
              :rules="
                disabled
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.amount, 0.01),
                      rules.max(formData.amount, 999999.99),
                    ]
              "
              v-model="formData.amount"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select
              :items="invoicedTypeList"
              :rules="[rules.required]"
              label="Fatura Tipi"
              v-model="formData.invoiced_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Geçerlilik Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Geçerlilik Bitiş Tarihi"
              v-model="formData.ends_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select-expense-type
              :rules="[rules.required]"
              v-model="formData.expense_type_id"
              :readonly="disabled"
              :filled="disabled"
              required
              @change="formData.child_expense_type_id = null"
              :show-passive="id"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-select
              :items="childExpenseTypeList"
              label="Gider Tipi"
              v-model="formData.child_expense_type_id"
              :readonly="disabled"
              :filled="disabled"
              :disabled="!formData.expense_type_id"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              :rules="[]"
              :label="$t('labels.utility_no')"
              v-model="formData.utility_no"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-is-active
              v-model="formData.is_active"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              :label="$t('labels.description')"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          v-if="!disabled"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasForm } from "@/view/mixins";

export default {
  name: "AutoAssessmentForm",
  mixins: [hasForm],
  computed: {
    ...mapGetters(["autoAssessmentTimingTypeList", "invoicedTypeList"]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id, extraParams) {
      this.$nextTick(() => {
        this.formData = Object.assign(
          {
            expense_type_id: null,
            child_expense_type_id: null,
            provider_id: null,
            issue_auto_assessment_timing_type_id: null,
            issue_day: null,
            last_payment_auto_assessment_timing_type_id: null,
            last_payment_day: null,
            amount: null,
            starts_on: null,
            ends_on: null,
            description: null,
            is_active: true,
            utility_no: null,
            invoiced_type_id: null,
          },
          extraParams
        );

        this.id = null;

        if (id) {
          this.id = id;
          this.load();
        }

        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    load() {
      this.isLoading = true;

      this.$api
        .get("expense/periodical-expenses", this.id)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .then(() => (this.isLoading = false));
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);

      this.isLoading = true;

      if (this.id) {
        this.$api
          .put(`expense/periodical-expenses/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("expense/periodical-expenses", formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.hide();
              return;
            }

            this.id = response.data.data.id;

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
