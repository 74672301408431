var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Borç Paylaştır")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":"","align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.house)?_c('rs-select-resident',{attrs:{"label":"Paylaşılan Sakin","rules":[_vm.rules.required, _vm.residentIdNotSame],"cluster-id":_vm.clusterId,"house-id":_vm.onlyHouseUsers ? _vm.house.id : null,"required":""},model:{value:(_vm.formData.resident_id),callback:function ($$v) {_vm.$set(_vm.formData, "resident_id", $$v)},expression:"formData.resident_id"}}):_vm._e()],1),(_vm.byAmount)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"label":"Paylaşılan Tutar","rules":_vm.disabled
                ? []
                : [
                    _vm.rules.required,
                    _vm.rules.min(_vm.formData.amount, 0.01),
                    _vm.rules.max(_vm.formData.amount, _vm.totalAmount),
                    _vm.notEqualToAssessment(),
                  ],"disabled":_vm.disabled},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})],1):_vm._e(),(!_vm.byAmount)?_c('v-col',{attrs:{"sm":"3"}},[_c('rs-text-field',{attrs:{"label":"Paylaşılan Gün","rules":_vm.disabled
                ? []
                : [_vm.rules.required, _vm.rules.min(_vm.days, 1), _vm.rules.max(_vm.days, 30)],"disabled":_vm.disabled},model:{value:(_vm.days),callback:function ($$v) {_vm.days=$$v},expression:"days"}})],1):_vm._e(),(!_vm.byAmount)?_c('v-col',[_c('rs-action',{attrs:{"is-loading":_vm.isLoading},on:{"click":_vm.handleAutoShareClick}},[_vm._v("Otomatik")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('labels.show_only_house_users'),"hide-details":"auto"},on:{"change":_vm.handleOnlyHouseUsersChange},model:{value:(_vm.onlyHouseUsers),callback:function ($$v) {_vm.onlyHouseUsers=$$v},expression:"onlyHouseUsers"}})],1),_c('v-col',[_c('v-switch',{attrs:{"disabled":_vm.disabled,"label":_vm.byAmount ? 'Tutar girerek' : 'Gün girerek',"hide-details":"auto"},model:{value:(_vm.byAmount),callback:function ($$v) {_vm.byAmount=$$v},expression:"byAmount"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('rs-textarea',{attrs:{"label":"Açıklama","rules":[_vm.rules.required],"required":"","readonly":_vm.disabled,"filled":_vm.disabled,"rows":"2"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":""},on:{"cancel":function($event){return _vm.hide()},"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}})],1),_c('DebtList',{attrs:{"list":_vm.selected}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }