<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">Kullanıcı Hareketleri</h3>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="Adı Soyadı"
              :value="formData.user.name"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="İşlem"
              :value="formData.action_type.name"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="İşlem Yapılan Alan"
              :value="getLoggable()"
              filled
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              v-if="formData.action_on"
              label="Tarih-Saat"
              :value="new Date(formData.action_on).toLocaleString()"
              filled
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      isLoading: false,
      formData: {
        user: {},
        loggable: {},
        action_type: {},
      },
    };
  },
  methods: {
    getLoggable() {
      if (this.formData.loggable_type === "App\\Models\\House") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.door_number == null
            ? "Bağımsız Bölüm"
            : this.formData.loggable.door_number;
        }
        return "Bağımsız Bölüm";
      }
      if (this.formData.loggable_type === "App\\Models\\Provider") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Tedarikçi"
            : this.formData.loggable.name;
        }
        return "Tedarikçi";
      }
      if (this.formData.loggable_type === "App\\Models\\Income\\OtherIncome") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.description == null
            ? "Diğer Gelir"
            : this.formData.loggable.description;
        }
        return "Diğer Gelir";
      }
      if (this.formData.loggable_type === "App\\Models\\HouseType") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Bağımsız Bölüm Tipi"
            : this.formData.loggable.name;
        }
        return "Bağımsız Bölüm Tipi";
      }
      if (this.formData.loggable_type === "App\\Models\\Cluster") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Toplu Yaşam Alanı"
            : this.formData.loggable.name;
        }
        return "Toplu Yaşam Alanı";
      }
      if (this.formData.loggable_type === "App\\Models\\BankAccount") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Banka"
            : this.formData.loggable.name;
        }
        return "Banka";
      }
      if (this.formData.loggable_type === "App\\Models\\Resident") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Sakin"
            : this.formData.loggable.name;
        }
        return "Sakin";
      }
      if (
        this.formData.loggable_type ===
        "App\\Models\\Income\\PeriodicalAssessment"
      ) {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.description == null
            ? "Periyodik Borçlandırma"
            : this.formData.loggable.description;
        }
        return "Periyodik Borçlandırma";
      }
      if (this.formData.loggable_type === "App\\Models\\Safe") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Kasa"
            : this.formData.loggable.name;
        }
        return "Kasa";
      }
      if (this.formData.loggable_type === "App\\Models\\Assessment") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.description == null
            ? "Tahakkuk"
            : this.formData.loggable.description;
        }
        return "Tahakkuk";
      }
      if (this.formData.loggable_type === "App\\Models\\Type\\ExpenseType") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Gider Türleri"
            : this.formData.loggable.name;
        }
        return "Gider Türleri";
      }
      if (this.formData.loggable_type === "App\\Models\\Expense\\Invoice") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.description == null
            ? "Gider Faturası"
            : this.formData.loggable.description;
        }
        return "Gider Faturası";
      }
      if (this.formData.loggable_type === "App\\Models\\Company") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Yönetim Şirketi"
            : this.formData.loggable.name;
        }
        return "Yönetim Şirketi";
      }
      if (this.formData.loggable_type === "App\\Models\\Type\\IncomeType") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Gelir Türü"
            : this.formData.loggable.name;
        }
        return "Gelir Türü";
      }
      if (this.formData.loggable_type === "App\\Models\\Block") {
        if (this.formData.loggable !== null) {
          return this.formData.loggable.name == null
            ? "Blok"
            : this.formData.loggable.name;
        }
        return "Blok";
      }
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`/action-logs/${this.id}`)
        .then((response) => {
          this.formData = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
