var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Fatura Ödeme")]),_c('template',{slot:"body"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('p',[_c('router-link',{attrs:{"to":{
            name: 'expenses.invoices.edit',
            params: { id: _vm.originalData.invoice_id },
          }}},[_vm._v(" Faturayı göster ")])],1),_c('v-row',{attrs:{"cols":"12","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-datepicker',{attrs:{"label":_vm.$t('labels.date'),"rules":_vm.disabled ? [] : [_vm.rules.required],"disabled":_vm.disabled,"required":""},model:{value:(_vm.formData.collected_on),callback:function ($$v) {_vm.$set(_vm.formData, "collected_on", $$v)},expression:"formData.collected_on"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-money-field',{attrs:{"rules":_vm.disabled || _vm.amountDisabled
                ? []
                : [
                    _vm.rules.required,
                    _vm.rules.min(_vm.formData.amount, 0.01),
                    _vm.rules.max(_vm.formData.amount, _vm.totalRemaining),
                  ],"disabled":_vm.disabled || _vm.amountDisabled,"hint":`Kalan tutar: ${_vm.numberToLocaleFormat(_vm.totalRemaining)}₺`},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-select-collecting',{attrs:{"label":"Ödeme Yeri","rules":[_vm.rules.required],"bank-account-id":_vm.formData.bank_account_id,"safe-id":_vm.formData.safe_id,"required":"","readonly":_vm.disabled,"filled":_vm.disabled,"can-pay":"","is-active":""},on:{"update:bankAccountId":function($event){return _vm.$set(_vm.formData, "bank_account_id", $event)},"update:bank-account-id":function($event){return _vm.$set(_vm.formData, "bank_account_id", $event)},"update:safeId":function($event){return _vm.$set(_vm.formData, "safe_id", $event)},"update:safe-id":function($event){return _vm.$set(_vm.formData, "safe_id", $event)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-textarea',{attrs:{"label":"Açıklama","readonly":_vm.disabled,"filled":_vm.disabled,"rules":[_vm.rules.maxLength(_vm.formData.description, 1000)]},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":"","hide-save-close":_vm.disabled},on:{"cancel":function($event){return _vm.hide()},"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }