<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Eşleştir</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="bankActivity">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-money-field
              label="İşlem Kalan Tutar"
              :value="remainingAmount"
              :disabled="true"
            />
          </v-col>

          <v-col sm="12">
            <rs-bank-keywords :value="resident.bank_keywords" readonly filled />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select-resident
              label="Kişi"
              v-model="formData.resident_id"
              :cluster-id="clusterId"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              @change="handleResidentChange"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select
              label="Tahsilat Türü"
              v-model="formData.collecting_type_id"
              :rules="[rules.required]"
              :items="collectingTypeList"
              :readonly="disabled"
              :filled="disabled"
              required
              @change="handleCollectingTypeChange"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-money-field
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.01),
                rules.max(formData.amount, remainingAmount),
              ]"
              :messages="
                selectedTotalDebt &&
                rules.max(formData.amount, remainingAmount) === true &&
                formData.amount * selectedTotalDebt
                  ? 'Girilen tahsilat tutarı seçilen borç toplamından fazla. Fazla olan ödeme, avans olarak işlenecek.'
                  : []
              "
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select-project
              v-model="formData.project_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-bank-keywords
              label="Sakin için ek anahtar kelimeler"
              v-model="formData.additional_bank_keywords"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              label="Bilgilendirme Gönder"
              v-model="formData.send_notification"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              label="Avans Olarak Ekle"
              v-model="hasAdvance"
              hint="Bir bağımsız bölüme tahsilat eklenir ve borçlar otomatik kapatır"
              persistent-hint
            />
          </v-col>

          <v-col sm="6" v-if="hasAdvance">
            <rs-select-house
              v-if="formData.resident_id"
              label="Bağımsız Bölüm"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              @change="loadDebtList"
              required
            />
          </v-col>

          <!-- <v-col sm="6" v-if="hasAdvance">
            <rs-select
              label="Kişi Bağımsız Bölümü"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :items="residentHouses"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col> -->

          <v-col sm="12">
            <rs-textarea
              label="Tahsilat Açıklaması"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[rules.maxLength(formData.description, 1000)]"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col v-if="bankActivity && bankActivity.identity_number">
            <rs-text-field
              label="TC Kimlik No"
              :value="bankActivity.identity_number"
              readonly
              filled
            />
          </v-col>
          <v-col v-if="bankActivity && bankActivity.tax_number">
            <rs-text-field
              label="Vergi Kimlik No"
              :value="bankActivity.tax_number"
              readonly
              filled
            />
          </v-col>
          <v-col v-if="bankActivity && bankActivity.iban">
            <rs-text-field
              label="IBAN"
              :value="bankActivity.iban"
              readonly
              filled
            />
          </v-col>
        </v-row>

        <div class="mt-5" v-if="formData.resident_id && !hasAdvance">
          <h5>Sakin Borçları</h5>
          <p>
            Aşağıdaki listeden seçtiğiniz borçlar ödenecektir. Borçları son
            ödeme tarihine göre en eskiden en yeniye doğru kapatmak için tümünü
            seçin.
          </p>
          <div class="form-group d-flex flex-column">
            <label for="filtered_block" class="ml-1"
              >Blok Numarasına Göre Filtrele</label
            >
            <select
              class="custom-select w-50 mb-3"
              name="filtered_block"
              id="filtered_block"
              v-model="filtered_block"
              @change="filterBlock()"
            >
              <option value="" selected>Tümü</option>
              <option :value="item" v-for="item in blocks" :key="item">
                {{ item }}
              </option>
            </select>
          </div>
          <v-row>
            <v-col cols="12" sm="6">
              <p v-if="selectedTotalDebt !== null">
                Seçilen borçların toplam bakiyesi:
                <strong>{{ numberToLocaleFormat(selectedTotalDebt) }}₺</strong>
              </p>
            </v-col>
          </v-row>
        </div>

        <v-data-table
          v-bind="dataTableAttrs"
          :fixed-header="false"
          :height="undefined"
          v-if="formData.resident_id && !hasAdvance"
          :headers="headers"
          :items="listShown"
          :loading="isLoading"
          item-key="debt_id"
          v-model="selectedItems"
          :items-per-page="100"
          disable-sort
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.balance="{ value }">
            <rs-table-cell-balance :value="value" colored />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.description="{ item }">
            <template v-if="item.debt_id || item.collecting_id">
              {{ item.description }}
            </template>
            <template v-else>
              <strong>
                {{ item.house_name }} ({{ item.house_user_type }})
              </strong>
            </template>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.payed="{ value }">
            <rs-table-cell-number price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_assessment="{ value }">
            <rs-table-cell-number price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_payed="{ value }">
            <rs-table-cell-number price :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.total_balance="{ value }">
            <rs-table-cell-balance colored :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.has_enforcement="{ value }">
            <rs-table-cell-boolean :value="value" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.defer_balance="{ item, value }">
            <v-tooltip bottom v-if="item.stop_deferment_on">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="red" dark v-bind="attrs" v-on="on">
                  mdi-exclamation
                </v-icon>
              </template>
              {{ new Date(item.stop_deferment_on).toLocaleDateString() }}
              tarihinden itibaren gecikme işletilmeyecek.
            </v-tooltip>
            <span>
              <rs-table-cell-number price :value="value" />
            </span>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.last_payment_on="{ value }">
            <rs-table-cell-date
              :value="value"
              :css-class="{
                'text-danger font-weight-bold': value < new Date(),
              }"
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.type="{ item }">
            {{ item.income_type || item.collecting_type || null }}
          </template>
        </v-data-table>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-save-close
        />
      </v-form>

      <rs-confirm
        ref="confirmCancelDefer"
        :loading="isLoading"
        @confirmed="handleCancelDeferSubmitClick"
        @cancelled="$refs.confirmCancelDefer.hide()"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import hasDataTable from "@/view/mixins/hasDataTable";
import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasDataTable, hasForm],
  components: {},
  computed: {
    ...mapGetters(["clusterId", "collectingTypeList", "incomeTypeList"]),
    remainingAmount() {
      if (!this.bankActivity) {
        return 0;
      }

      const amount =
        this.bankActivity.amount * 100 -
        this.bankActivity.transaction_amount * 100;

      return amount / 100;
    },
    listShown() {
      if (!this.list.length) {
        return [];
      }

      let listShown = [].concat(this.list);
      const collectingTypeId = this.formData.collecting_type_id || null;

      listShown = listShown.filter((debt) => {
        if (!debt.debt_id || debt.has_enforcement) {
          return false;
        }

        if (
          collectingTypeId !== 1 &&
          !this.incomeMatchesCollecting(debt.income_type_id, collectingTypeId)
        ) {
          return false;
        }

        return true;
      });

      listShown.sort((a, b) => {
        if (a.last_payment_on === b.last_payment_on) {
          return 0;
        }

        return a.last_payment_on > b.last_payment_on ? 1 : -1;
      });

      return listShown;
    },
    selectedTotalDebt() {
      if (!this.selectedItems.length) {
        return null;
      }

      let total = 0;

      this.selectedItems.forEach((item) => {
        total += parseFloat(item.total_balance) * 100;
      });

      return parseInt(total) / 100;
    },
    cancelDefermentEnabled() {
      if (this.selectedItems.length === 0) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (!this.selectedItems[index].defer_balance) {
          return false;
        }
      }

      return true;
    },
    residentHouses() {
      if (
        !this.resident ||
        !this.resident.houses ||
        !this.resident.houses.length
      ) {
        return [];
      }

      return this.resident.house_users
        .filter((item) => [1, 2].indexOf(item.house_user_type_id) !== -1)
        .map((item) => {
          return {
            id: item.house.id,
            name: item.house.block.name + " - " + item.house.door_number,
          };
        });
    },
  },
  watch: {
    "formData.collecting_type_id"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedItems = [];
      }
    },
    // listShown(newList) {
    //   let amount = 0;

    //   for (const index in newList) {
    //     amount += parseFloat(newList[index].total_balance) * 100;
    //   }

    //   amount = amount / 100;

    //   if (amount > this.remainingAmount) {
    //     this.formData.amount = this.remainingAmount;
    //   }

    //   this.formData.amount = amount;
    // },
  },
  data() {
    return {
      tmpList: [],
      filtered_block: "",
      bankActivity: null,
      hasAdvance: false,
      resident: {},
      blocks: [],
      headers: [
        {
          text: "Blok",
          value: "block",
        },
        {
          text: "BB",
          value: "door_number",
        },
        {
          text: "Fiş Türü",
          value: "type",
        },
        {
          text: this.$t("labels.description"),
          value: "description",
        },
        {
          text: this.$t("labels.assessment"),
          value: "assessment",
          align: "end",
          cellClass: "price",
        },
        {
          text: this.$t("labels.last_payment_date"),
          value: "last_payment_on",
        },
        {
          text: this.$t("labels.payed"),
          value: "payed",
          align: "end",
        },
        {
          text: this.$t("labels.balance"),
          value: "balance",
          align: "end",
        },
        {
          text: this.$t("labels.defer_assessment"),
          value: "defer_assessment",
          align: "end",
        },
        {
          text: this.$t("labels.defer_payed"),
          value: "defer_payed",
          align: "end",
        },
        {
          text: this.$t("labels.defer_balance"),
          value: "defer_balance",
          align: "end",
        },
        {
          text: this.$t("labels.total_balance"),
          value: "total_balance",
          align: "end",
        },
        {
          text: this.$t("labels.has_enforcement"),
          value: "has_enforcement",
          align: "center",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(bankActivity) {
      this.hasAdvance = false;
      this.resident = {};
      this.bankActivity = bankActivity;
      this.updateClusterId(
        bankActivity.cluster_id || bankActivity.bank_account.cluster_id
      );

      this.formData = {
        resident_id: bankActivity.resident_id || null,
        collecting_type_id: 1,
        amount: this.remainingAmount,
        send_notification: true,
        house_id: bankActivity.house_id || null,
        description: bankActivity.description,
        additional_bank_keywords: [],
        debt_ids: [],
        project_id: null,
      };

      this.loadDebtList();

      this.$refs.sidePanel.show();
    },
    hide() {
      this.bankActivity = null;
      this.$refs.sidePanel.hide();
    },
    filterBlock() {
      if (this.filtered_block === "") {
        this.list = this.tmpList;
      } else {
        this.list = this.list.filter((x) => x.block === this.filtered_block);
      }
    },
    loadDebtList() {
      if (this.isLoading || !this.formData.resident_id) {
        this.list = [];
        this.selectedItems = [];
        return;
      }

      this.isLoading = true;

      // Get resident data
      this.$api
        .query(`residents/${this.formData.resident_id}`)
        .then((response) => {
          this.resident = Object.assign({}, response.data.data);
        })
        .catch((error) => {
          this.handleError(error);
        });

      this.list = [];
      this.selectedItems = [];
      this.blocks = [];
      this.filtered_block = "";
      this.tmpList = [];

      // const params = Object.assign(
      //   this.buildSearchParams(this.search, this.options)
      // );

      return this.$api
        .query(`income/residents/${this.formData.resident_id}`)
        .then((response) => {
          this.list = response.data.data.sort(
            (a, b) => a.last_payment_on > b.last_payment_on
          );
          this.listShown.forEach((element) => {
            if (!this.blocks.includes(element.block)) {
              this.blocks.push(element.block);
            }
          });
          this.blocks.sort((a, b) => a.localeCompare(b));
          this.tmpList = this.list;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (!this.hasAdvance && this.selectedItems.length === 0) {
        this.$toast.error(
          "Bir borç seçmelisiniz veya avans olarak eklemelisiniz"
        );
        return false;
      }

      this.isLoading = true;

      if (!this.hasAdvance) {
        this.formData.debt_ids = this.selectedItems.map((item) => item.debt_id);
      }
      const formData = Object.assign({}, this.formData);

      this.$api
        .post(
          `income/bank-activities/${this.bankActivity.id}/debt-collecting`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCollectingTypeChange(collectingTypeId) {
      collectingTypeId;
    },
    handleResidentChange() {
      this.resident = {};
      this.formData.house_id = null;
      this.loadDebtList();
    },
    incomeMatchesCollecting(incomeTypeId, collectingTypeId) {
      let collectingType, incomeType;

      for (const index in this.collectingTypeList) {
        if (parseInt(collectingTypeId) === this.collectingTypeList[index].id) {
          collectingType = this.collectingTypeList[index];

          if (!collectingType.income_type_id) {
            return false;
          }

          break;
        }
      }

      for (const index in this.incomeTypeList) {
        if (parseInt(incomeTypeId) === this.incomeTypeList[index].id) {
          incomeType = this.incomeTypeList[index];
          break;
        }
      }

      if (incomeType === undefined) {
        // eslint-disable-next-line
        console.error(
          "Income type not found",
          incomeTypeId,
          this.incomeTypeList
        );
        return false;
      }

      if (incomeType.id === collectingType.income_type_id) {
        return true;
      }

      return false;
    },
  },
};
</script>
