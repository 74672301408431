<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">Toplu Yaşam Alanı</h3>
      <v-col class="text-right p-0">
        <rs-action
          v-if="
            id &&
            can('edit-cluster') &&
            originalData.has_bank_integration &&
            originalData.paratic_token
          "
          @click="handleBankActivityClick"
        >
          Hesap Hareketlerini Güncelle
        </rs-action>

        <rs-action
          :href="templateDownloadTarget"
          target="_blank"
          v-if="
            hasRole('superadministrator') &&
            $refs.importForm &&
            id &&
            originalData.house_types &&
            originalData.house_types.length === 0 &&
            originalData.blocks.length === 0
          "
          small
        >
          Toplu Aktarım Şablonu
        </rs-action>

        <rs-action
          @click="$refs.importForm.show"
          v-if="
            hasRole('superadministrator') &&
            $refs.importForm &&
            id &&
            originalData.house_types &&
            originalData.house_types.length === 0 &&
            originalData.blocks.length === 0
          "
        >
          Toplu Aktarım
        </rs-action>

        <rs-action
          @click="handleResidentFileClick"
          v-if="
            hasRole('superadministrator') &&
            id &&
            originalData.house_types &&
            originalData.house_types.length !== 0 &&
            originalData.blocks.length !== 0
          "
        >
          Toplu Ekstre Aktarım
        </rs-action>

        <rs-file
          label="Dosya"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
          v-if="!disabled"
          @change="handleImportResidentFileChange"
          class="d-none"
          id="importResidentAccountInput"
        />

        <rs-action
          :to="{ name: 'definitions.clusters.edit', params: { id: id } }"
          v-if="id && can('edit-cluster') && disabled"
          icon
          title="Düzenle"
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action
          @click="handleDeleteClick"
          v-if="can('delete-cluster') && id && deletable"
          icon
          title="Sil"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-cluster')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <div class="card-body">
        <v-tabs v-model="selectedTab">
          <v-tab to="#info" replace>{{ $t("headers.general_info") }}</v-tab>
          <v-tab to="#other" replace :disabled="!id">
            {{ $t("headers.other_settings") }}
          </v-tab>
          <v-tab to="#house-types" replace :disabled="!id">
            {{ $t("headers.house_types_s") }}
          </v-tab>
          <v-tab to="#blocks" replace :disabled="!id">Bloklar</v-tab>
          <v-tab to="#sms" replace :disabled="!id" v-if="can('edit-cluster')">
            SMS
          </v-tab>
          <v-tab
            to="#bank-integration"
            replace
            :disabled="!id"
            v-if="can('edit-cluster') && originalData.has_bank_integration"
            @change="handleBankIntegrationActive"
          >
            Banka Entegrasyon
          </v-tab>
          <v-tab
            to="#cc"
            replace
            :disabled="!id"
            v-if="hasRole('superadministrator')"
          >
            Kredi Kartı Entegrasyon
          </v-tab>
          <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
            {{ $t("headers.notes_and_reminders") }}
          </v-tab>
          <v-tab
            to="#documents"
            replace
            :disabled="!id"
            v-if="can('see-document')"
          >
            {{ $t("headers.documents") }}
          </v-tab>
        </v-tabs>

        <v-tabs-items touchless v-model="selectedTab">
          <v-tab-item value="info" class="pa-4">
            <v-form
              @submit.prevent="handleFormSubmit"
              ref="form"
              :readonly="disabled || !can('edit-cluster')"
              :filled="disabled || !can('edit-cluster')"
            >
              <v-row dense>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.cluster_name')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.name, 120),
                    ]"
                    v-model="formData.name"
                    :disabled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.cluster_title')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.title, 300),
                    ]"
                    v-model="formData.title"
                    :disabled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-select-location
                    :label="$t('labels.city')"
                    :rules="[rules.required]"
                    v-model="formData.city_id"
                    :disabled="disabled"
                    :location-type-id="2"
                    required
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-select-location
                    :label="$t('labels.district')"
                    :rules="[rules.required]"
                    v-model="formData.district_id"
                    :location-type-id="3"
                    :parent-id="formData.city_id"
                    required
                    :disabled="disabled || !formData.city_id"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.tax_office')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.tax_office, 20),
                    ]"
                    v-model="formData.tax_office"
                    :disabled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.tax_number')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.tax_number, 20),
                    ]"
                    v-model="formData.tax_number"
                    :disabled="disabled"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-text-field
                    type="number"
                    min="1"
                    step="1"
                    :rules="[
                      rules.required,
                      rules.min(formData.house_count, 1),
                    ]"
                    :label="$t('labels.house_count')"
                    v-model="formData.house_count"
                    :disabled="disabled || !hasRole('superadministrator')"
                  />
                </v-col>

                <!-- <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.receipt_name')"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.receipt_name, 200),
                    ]"
                    v-model="formData.receipt_name"
                    :disabled="disabled"
                  />
                </v-col> -->

                <v-col cols="12" sm="6">
                  <rs-textarea
                    :label="$t('labels.address')"
                    :rules="[
                      rules.maxLength(formData.address, 500),
                      formData.create_customer ? rules.required : true,
                    ]"
                    v-model="formData.address"
                    :disabled="disabled"
                    rows="3"
                    no-resize
                  />
                </v-col>

                <v-col sm="6" v-if="id && originalData.created_at">
                  <rs-text-field
                    :label="$t('labels.created_date')"
                    :value="
                      new Date(originalData.created_at).toLocaleDateString()
                    "
                    readonly
                    filled
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <rs-datepicker
                    label="Mali Başlangıç Tarihi"
                    v-model="formData.legal_beginning_date"
                    :disabled="disabled || !hasRole('superadministrator')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col sm="4">
                  <v-switch
                    v-model="formData.is_active"
                    :disabled="disabled"
                    :label="formData.is_active ? 'Aktif' : 'Pasif'"
                    hide-details="auto"
                  ></v-switch>
                </v-col>

                <v-col sm="4">
                  <v-switch
                    label="Sakinlere Kapalı"
                    v-model="formData.is_closed"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col>

                <template v-if="hasRole('superadministrator')">
                  <v-col sm="4">
                    <v-switch
                      label="Banka Entegrasyonu"
                      v-model="formData.has_bank_integration"
                      :disabled="disabled"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col sm="4">
                    <v-switch
                      label="Demo"
                      v-model="formData.is_demo"
                      :disabled="disabled"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col sm="4" v-if="formData.is_demo">
                    <rs-datepicker
                      label="Demo Bitiş Tarihi"
                      v-model="formData.demo_ends_at"
                      :rules="[rules.required]"
                      required
                    />
                  </v-col>

                  <v-col sm="6" v-if="hasRole('superadministrator')">
                    <v-switch
                      label="Bütçe Modülü"
                      v-model="formData.has_budgets"
                      :disabled="disabled"
                      hide-details="auto"
                    />
                  </v-col>
                </template>
              </v-row>

              <v-row dense v-if="id && hasRole('superadministrator')">
                <v-col cols="12">
                  <v-divider class="my-5" />
                  <h3>Yönetim Şekli</h3>
                </v-col>
                <v-col sm="12">
                  <rs-select
                    label="Yönetim Şekli"
                    v-model="formData.management_type_id"
                    :items="managementTypeList"
                    hide-search
                    :disabled="disabled"
                  />
                </v-col>
                <template v-if="formData.management_type_id === 1">
                  <template v-if="originalData.active_company">
                    <v-col sm="3">
                      <rs-text-field
                        label="Yöneten Şirket"
                        :value="originalData.active_company.company.name"
                        filled
                        readonly
                        :disabled="disabled"
                      />
                    </v-col>
                    <v-col sm="3">
                      <v-btn
                        class="mt-3"
                        outlined
                        color="pink"
                        @click="$refs.clusterCompanyForm.show"
                        v-if="$refs.clusterCompanyForm"
                        :disabled="disabled"
                      >
                        Değiştir
                      </v-btn>
                    </v-col>
                  </template>

                  <v-col sm="3" v-else>
                    <v-btn
                      class="mt-3"
                      outlined
                      color="pink"
                      @click="$refs.clusterCompanyForm.show"
                      v-if="$refs.clusterCompanyForm"
                    >
                      Yönetim Şirketi Seç
                    </v-btn>
                  </v-col>
                </template>
                <template v-else-if="originalData.active_company">
                  <strong class="text-danger mt-5">
                    Şu anda {{ originalData.active_company.company.name }} ile
                    yönetiliyor.
                  </strong>
                </template>

                <v-col sm="6" v-if="formData.management_type_id === 2">
                  <rs-select-resident
                    :label="$t('labels.authorized_person')"
                    v-model="formData.manager_resident_id"
                    :cluster-id="id"
                    :extra-params="{ is_active: true }"
                    :rules="disabled ? [] : [rules.required]"
                  />
                </v-col>

                <v-col
                  sm="6"
                  v-else-if="
                    formData.active_company &&
                    formData.active_company.company_id
                  "
                >
                  <rs-autocomplete-company-user
                    :label="$t('labels.authorized_person')"
                    v-model="formData.company_user_id"
                    :disabled="disabled"
                    :company-id="formData.active_company.company_id"
                    :extra-params="{ is_active: true }"
                    :rules="disabled ? [] : [rules.required]"
                    item-value="company_user_id"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="hasRole('superadministrator')">
                <v-col cols="12">
                  <v-divider class="my-5" />
                  <h3>Müşteri Bilgisi</h3>
                </v-col>

                <v-col sm="4">
                  <v-switch
                    v-model="formData.create_customer"
                    :disabled="disabled"
                    label="TYA bilgileriyle müşteri oluştur"
                    hide-details="auto"
                    @change="formData.customer_id = null"
                  ></v-switch>

                  <strong
                    class="text-danger"
                    v-if="originalData.customer_id && formData.create_customer"
                  >
                    DİKKAT: Halihazırda müşterisi olan bir TYA'nın bilgileriyle
                    yeni müşteri oluşturuyorsunuz.
                  </strong>
                </v-col>

                <v-col sm="4" v-if="formData.create_customer">
                  <rs-select-customer-group
                    v-model="formData.new_customer.customer_group_id"
                    persistent-hint
                    hint="Seçmezseniz otomatik olarak oluşturulur"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>

                <v-col sm="4" v-if="formData.create_customer">
                  <rs-select-product
                    v-model="formData.new_customer.product_id"
                    :rules="[rules.required]"
                    :readonly="disabled"
                    :filled="disabled"
                    @change:item="handleProductSelected"
                  />
                </v-col>

                <v-col sm="4" v-if="formData.create_customer">
                  <rs-money-field
                    label="Aylık Tutar / BB"
                    v-model="formData.new_customer.amount"
                    :rules="[
                      rules.required,
                      rules.min(formData.amount, 0.0),
                      rules.max(formData.amount, 999999.99),
                    ]"
                    :disabled="isLoading"
                    required
                  />
                </v-col>

                <v-col sm="4" v-if="!formData.create_customer">
                  <rs-select-customer
                    v-model="formData.customer_id"
                    :rules="[
                      !id && !formData.create_customer ? rules.required : true,
                    ]"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-datepicker
                    :label="
                      formData.create_customer
                        ? 'Fatura ve Ürün Başlangıç Tarihi'
                        : 'Fatura Başlangıç Tarihi'
                    "
                    v-model="formData.invoicing_begins_on"
                    :disabled="
                      formData.customer_id === originalData.customer_id &&
                      !formData.create_customer
                    "
                    hint="Boş bırakılırsa mali başlangıç tarihi seçilir"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-datepicker
                    :label="
                      formData.create_customer
                        ? 'Fatura ve Ürün Bitiş Tarihi'
                        : 'Fatura Bitiş Tarihi'
                    "
                    v-model="formData.invoicing_ends_on"
                    :disabled="
                      formData.customer_id === originalData.customer_id &&
                      !formData.create_customer
                    "
                    hint="Boş bırakılırsa başlangıç tarihine bir yıl eklenir"
                  />
                </v-col>
              </v-row>

              <v-row dense v-if="id && hasRole('superadministrator')">
                <v-col cols="12">
                  <v-divider class="my-5" />
                  <h3>
                    Fatura Bilgisi
                    <strong class="text-danger"
                      >(Yakında bu bilgiler silinecek)</strong
                    >
                  </h3>
                </v-col>

                <v-col sm="4">
                  <rs-money-field
                    :label="$t('labels.agreement_price')"
                    disabled
                    v-model="formData.agreement_price"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-datepicker
                    :label="$t('labels.new_aggrement_date')"
                    v-model="formData.agreement_renewal_on"
                    :disabled="disabled"
                  />
                </v-col>

                <v-col sm="4">
                  <rs-textarea
                    label="Fatura Başlangıç Açıklaması"
                    v-model="formData.invoicing_begins_description"
                    disabled
                  />
                </v-col>

                <v-col sm="4">
                  <rs-datepicker
                    label="Hizmet durdurma tarihi"
                    v-model="formData.suspends_on"
                    :disabled="disabled"
                    hint="Doldurulursa bu TYA'nın yöneticilerine verilen hizmetin durdurulacağına dair uyarı gözükür."
                  />
                </v-col>

                <v-col sm="4">
                  <rs-datepicker
                    label="Silinmesi Gereken Tarih"
                    v-model="formData.should_delete_on"
                    :disabled="disabled"
                  />
                </v-col>
              </v-row>

              <ClusterCompanyForm
                ref="clusterCompanyForm"
                :extra-params="{ cluster_id: id }"
                @dismissed="handleCompanyFormDismissed"
                @saved="handleCompanyFormSaved"
                :cluster="originalData"
              />

              <rs-form-buttons
                :isLoading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
                v-if="!disabled && can('edit-cluster')"
                :disable-submit="!saveable"
              />
            </v-form>
          </v-tab-item>
          <v-tab-item value="other" class="pa-4">
            <v-form
              @submit.prevent="handleFormOthersSubmit"
              ref="formOthers"
              :readonly="disabled || !can('edit-cluster')"
              :filled="disabled || !can('edit-cluster')"
            >
              <v-row dense>
                <!-- <v-col cols="12" sm="6">
                  <rs-select
                    :items="techWorkerAuthorizationOptions"
                    :item-text="(item) => $t(item.translation_key)"
                    label="Teknik Personel Arıza Yetkisi"
                    v-model="formDataOthers.tech_worker_authorization_id"
                    :disabled="disabled"
                  />
                </v-col> -->
                <v-col cols="12" sm="6">
                  <rs-money-field
                    v-model="formDataOthers.notification_minimum_balance"
                    :disabled="disabled"
                    :label="$t('labels.notification_minimum_balance')"
                    :rules="[
                      rules.min(
                        formDataOthers.notification_minimum_balance,
                        0.01
                      ),
                      rules.max(
                        formDataOthers.notification_minimum_balance,
                        9999999.99
                      ),
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.person_risk_parameter')"
                    v-model="formDataOthers.person_risk_parameter"
                    :disabled="disabled"
                    type="number"
                    step="1"
                    min="0"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-money-field
                    v-model="formDataOthers.house_risk_price"
                    :disabled="disabled"
                    :label="$t('labels.house_risk_price')"
                    :rules="[
                      rules.min(formDataOthers.house_risk_price, 0.01),
                      rules.max(formDataOthers.house_risk_price, 9999999.99),
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.fee_last_payment_day')"
                    :rules="[rules.required]"
                    v-model="formDataOthers.fee_last_payment_day"
                    :disabled="disabled"
                    type="number"
                    step="1"
                    min="1"
                    max="31"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    :label="$t('labels.expired_option_days')"
                    :rules="[rules.required]"
                    v-model="formDataOthers.deferment_grace_period"
                    :disabled="disabled"
                    type="number"
                    step="1"
                    min="0"
                    max="60"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-text-field
                    label="Gecikme Faiz Oranı (Aylık)"
                    :rules="[rules.required]"
                    v-model="formDataOthers.deferment_interest_percent"
                    :disabled="disabled"
                    prefix="%"
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-select
                    label="Gecikme Hesaplama Türü"
                    :rules="[rules.required]"
                    :items="[
                      { id: 'daily', name: 'Günlük' },
                      { id: 'monthly', name: 'Aylık' },
                    ]"
                    v-model="formDataOthers.deferment_calculation_period"
                    :disabled="disabled"
                  />
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <rs-select
                    :items="shareMeterRoundingTypeOptions"
                    :item-text="(item) => $t(item.translation_key)"
                    :label="$t('labels.share_meter_rounding_type')"
                    v-model="formDataOthers.share_meter_rounding_type_id"
                    :disabled="disabled"
                  />
                </v-col> -->
                <!-- <v-col cols="12" sm="6">
                  <rs-select
                    :items="accountingRangeOptions"
                    :item-text="(item) => $t(item.translation_key)"
                    :rules="[rules.required]"
                    label="Hesap Hareketleri Tarih Aralığı"
                    v-model="formDataOthers.accouting_range_id"
                    :disabled="disabled"
                  />
                </v-col> -->
                <v-col cols="12" sm="6">
                  <rs-select
                    :items="bankWorkflowCollectionTypeOptions"
                    :item-text="(item) => $t(item.translation_key)"
                    :label="$t('labels.bank_workflow_collecting_type')"
                    v-model="formDataOthers.bank_workflow_collecting_type_id"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-select
                    :items="providerBankWorkflowCollectionTypeOptions"
                    :item-text="(item) => $t(item.translation_key)"
                    :label="$t('labels.bank_workflow_payment_type')"
                    v-model="formDataOthers.bank_workflow_payment_type_id"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-select
                    :items="residentRequiredFieldsOptions"
                    label="Sakin Zorunlu Alanlar"
                    v-model="formDataOthers.resident_required_fields"
                    :disabled="disabled"
                    multiple
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <rs-file
                    :label="$t('labels.report_logo')"
                    :rules="[]"
                    accept="image/png, image/jpeg"
                    :disabled="disabled"
                    :filled="disabled"
                    @change="handleReportLogoChange"
                    hint="En fazla 3MB. JPG veya PNG."
                  />
                </v-col>
                <v-col sm="6" v-if="formDataOthers.report_logo">
                  <div>
                    <img
                      :src="formDataOthers.report_logo.url"
                      class="report-logo"
                    />

                    <v-btn
                      color="pink"
                      small
                      outlined
                      dark
                      class="ms-3"
                      @click="handleRemoveReportLogoClick"
                    >
                      Kaldır
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    label="Sözleşmeli Ev Sahipleri"
                    v-model="formDataOthers.has_future_owners"
                    :disabled="disabled || !this.hasRole('superadministrator')"
                    hide-details="auto"
                  />
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-switch
                    :label="$t('labels.updates_person_data')"
                    v-model="formDataOthers.updates_person_data"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col> -->
                <!-- <v-col cols="12" sm="6">
                  <v-switch
                    :label="$t('labels.share_expense_data')"
                    v-model="formDataOthers.share_expense_data"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col> -->
                <!-- <v-col cols="12" sm="6">
                  <v-switch
                    :label="$t('labels.has_share_meter')"
                    v-model="formDataOthers.has_share_meter"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col> -->
                <!-- <v-col cols="12" sm="6">
                  <v-switch
                    :label="$t('labels.has_cash_collection_notifications')"
                    v-model="formDataOthers.has_cash_collection_notifications"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    :label="$t('labels.has_cash_payment_notifications')"
                    v-model="formDataOthers.has_cash_payment_notifications"
                    :disabled="disabled"
                    hide-details="auto"
                  />
                </v-col> -->
              </v-row>

              <rs-form-buttons
                :isLoading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormOthersSubmit"
                v-if="!disabled"
              />
            </v-form>
          </v-tab-item>
          <v-tab-item value="house-types" class="pa-4">
            <HouseTypeList :cluster-id="id" v-if="id" />
          </v-tab-item>
          <v-tab-item value="blocks" class="pa-4">
            <BlockList :cluster-id="id" v-if="id" />
          </v-tab-item>
          <v-tab-item value="sms" class="pa-4" v-if="can('edit-cluster')">
            <v-row dense class="mb-2">
              <v-col>
                <rs-action
                  @click="$refs.notificationProviderForm.show()"
                  v-if="$refs.notificationProviderForm && can('edit-cluster')"
                  class="float-end"
                >
                  Ekle
                </rs-action>
              </v-col>
            </v-row>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Bildirim Tedarikçisi</th>
                    <th>Durum</th>
                    <th>Varsayılan</th>
                    <th>Kalan Kredi</th>
                    <th>Son Kredi Kontrolü</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in notificationProviderList" :key="item.id">
                    <td>{{ item.notification_provider_name }}</td>
                    <td class="text-center">
                      <rs-action
                        @click="
                          handleNotificationProviderUpdateClick(item, {
                            is_active: !item.is_active,
                          })
                        "
                        :tooltip="
                          item.is_active
                            ? 'Bildirim yöntemini devre dışı bırak'
                            : 'Bildirim yöntemini etkinleştir'
                        "
                      >
                        <rs-table-cell-boolean :value="item.is_active" />
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-table-cell-boolean :value="item.is_default" />
                    </td>
                    <td class="text-center">
                      <rs-table-cell-number
                        :value="item.remaining_credits"
                        v-if="item.remaining_credits !== null"
                      />
                      <em class="text-muted" v-else>Bilgi yok</em>
                    </td>
                    <td class="text-center">
                      <rs-table-cell-date
                        show-time
                        :value="item.fetched_at"
                        v-if="item.fetched_at !== null"
                      />
                      <em class="text-muted" v-else>Bilgi yok</em>
                      <rs-action
                        icon
                        @click="handleNotificationProviderFetchClick(item)"
                        :is-loading="item.isLoading"
                      >
                        <v-icon>mdi-reload</v-icon>
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-action-delete
                        @click="handleDeleteNotificationProviderClick(item)"
                        confirmed
                        title="Kaldır"
                        icon
                        v-if="can('edit-cluster')"
                      />
                      <rs-action
                        icon
                        @click="$refs.notificationProviderForm.show(item.id)"
                        :is-loading="item.isLoading"
                        v-if="can('edit-cluster')"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </rs-action>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <ClusterNotificationProviderForm
              ref="notificationProviderForm"
              @saved="loadNotificationProviderList"
            />

            <!-- <div v-if="originalData.has_sms_credentials === true">
              <v-row dense>
                <v-col cols="10" class="text-center">
                  <h3>SMS Entegrasyonu yapılmıştır</h3>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="primary"
                    @click="originalData.has_sms_credentials = false"
                  >
                    Bilgileri Değiştir
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <rs-text-field
                    :label="$t('labels.sms_balance')"
                    v-model="originalData.sms_remaining_credits"
                    readonly
                    filled
                  />
                </v-col>
                <v-col cols="6">
                  <rs-text-field
                    :label="$t('labels.sms_fetch_date')"
                    :value="
                      new Date(
                        originalData.sms_credits_fetched_at
                      ).toLocaleString()
                    "
                    readonly
                    filled
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="secondary"
                    @click="checkSMS()"
                    :loading="isLoading"
                    :disabled="isLoading || disabled"
                  >
                    SMS Bakiye Kontrol
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-form
              v-model="smsFormValid"
              ref="smsForm"
              @submit.prevent="handleSmsFormSubmit"
              v-else
              :readonly="disabled || !can('edit-cluster')"
              :filled="disabled || !can('edit-cluster')"
            >
              <v-row dense>
                <v-col cols="12">
                  <rs-select
                    :items="smsCompanyList"
                    :rules="[rules.required]"
                    :label="$t('labels.sms_company')"
                    v-model="formDataSms.sms_company_id"
                    disabled
                  />
                </v-col>
                <v-col sm="4">
                  <rs-text-field
                    :label="$t('labels.sms_username')"
                    :rules="[rules.required]"
                    v-model="formDataSms.sms_username"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-text-field
                    :label="$t('labels.sms_password')"
                    :rules="[rules.required]"
                    v-model="formDataSms.sms_password"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col sm="4">
                  <rs-text-field
                    label="SMS Başlık"
                    :rules="[rules.required]"
                    v-model="formDataSms.sms_title"
                    :disabled="disabled"
                  />
                </v-col>
              </v-row>

              <div class="text-right mt-10">
                <v-btn
                  type="submit"
                  :loading="isLoading"
                  :disabled="isLoading || disabled"
                  color="primary"
                >
                  Entegre Et
                </v-btn>
              </div>
            </v-form> -->
          </v-tab-item>
          <v-tab-item
            value="bank-integration"
            class="pa-4"
            v-if="can('edit-cluster')"
          >
            <div v-if="originalData.paratic_token">
              <v-form
                v-if="originalData.paratic_token"
                ref="bankIntegrationConfigForm"
                @submit.prevent="handleBankIntegrationConfigFormSubmit"
              >
                <h5>Yeni Banka Hareketleri için Bildirim Gönder</h5>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-switch
                      label="TYA Yöneticisi"
                      v-model="
                        formDataBankIntegration.bank_activity_notification_manager
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch
                      label="Robo Admin"
                      v-model="
                        formDataBankIntegration.bank_activity_notification_admin
                      "
                    />
                  </v-col>
                </v-row>

                <rs-form-buttons
                  :is-loading="isLoading"
                  hide-cancel
                  hide-save-close
                  @submit="handleBankIntegrationConfigFormSubmit"
                  v-if="!disabled"
                />
              </v-form>

              <v-row dense class="mb-5">
                <v-col class="text-center">
                  <h3>Banka entegrasyonu yapılmıştır</h3>
                </v-col>
              </v-row>

              <v-simple-table dense>
                <thead>
                  <tr>
                    <th>Hesap Adı</th>
                    <th>Son Kontrol Tarihi</th>
                    <th>Son Eklenen İşlem Sayısı</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="account in bankAccountList" :key="account.id">
                    <td>{{ account.name }}</td>
                    <td class="text-center">
                      <rs-table-cell-date
                        :value="account.last_fetched_at"
                        show-time
                      />
                      <span class="text-muted" v-if="!account.last_fetched_at">
                        Hiçbir zaman
                      </span>
                    </td>
                    <td class="text-end font-weight-bold">
                      {{ account.last_fetched_item_count }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <v-form
              v-model="bankFormValid"
              ref="bankForm"
              @submit.prevent="handleBankFormSubmit"
              v-else-if="originalData.tax_number"
              :readonly="disabled || !can('edit-cluster')"
              :filled="disabled || !can('edit-cluster')"
            >
              <div class="text-right mt-10">
                <v-btn
                  class="d-block"
                  type="submit"
                  :loading="isLoading"
                  :disabled="isLoading || disabled"
                  color="primary"
                >
                  Entegre Et
                </v-btn>
              </div>
            </v-form>

            <p v-else>
              Banka entegrasyonu yapılabilmesi için vergi numarası girilmiş
              olmalıdır.
            </p>
          </v-tab-item>
          <v-tab-item
            value="cc"
            class="pa-4"
            v-if="hasRole('superadministrator')"
          >
            <v-row dense class="mb-2">
              <v-col>
                <rs-action
                  @click="$refs.paymentProviderForm.show()"
                  v-if="$refs.paymentProviderForm"
                  class="float-end"
                >
                  Ekle
                </rs-action>
              </v-col>
            </v-row>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Ödeme Tedarikçisi</th>
                    <th>POS Hesabı</th>
                    <th>Banka Hesabı</th>
                    <th>Durum</th>
                    <th>Varsayılan</th>
                    <th>Kaydetme</th>
                    <th>Otomatik Ödeme</th>
                    <th>Taksit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in paymentProviderList" :key="item.id">
                    <td>{{ item.payment_provider_name }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'definitions.bank-accounts.show',
                          params: { id: item.pos_account_id },
                        }"
                      >
                        {{ item.pos_account_name }}
                      </router-link>
                    </td>
                    <td>
                      <router-link
                        v-if="item.bank_account_id"
                        :to="{
                          name: 'definitions.bank-accounts.show',
                          params: { id: item.bank_account_id },
                        }"
                      >
                        {{ item.bank_account_name }}
                      </router-link>
                    </td>
                    <td class="text-center">
                      <rs-action
                        @click="
                          handleUpdateClick(item, {
                            is_active: !item.is_active,
                          })
                        "
                        :tooltip="
                          item.is_active
                            ? 'Ödeme yöntemini devre dışı bırak'
                            : 'Ödeme yöntemini etkinleştir'
                        "
                      >
                        <rs-table-cell-boolean :value="item.is_active" />
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-table-cell-boolean :value="item.is_default" />
                    </td>
                    <td class="text-center">
                      <rs-action
                        @click="
                          handleUpdateClick(item, {
                            save_enabled: !item.save_enabled,
                          })
                        "
                        :tooltip="
                          item.save_enabled
                            ? 'Kart kaydetmeyi kapat'
                            : 'Kart kaydetmeyi aç'
                        "
                      >
                        <rs-table-cell-boolean :value="item.save_enabled" />
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-action
                        @click="
                          handleUpdateClick(item, {
                            subscribe_enabled: !item.subscribe_enabled,
                          })
                        "
                        :tooltip="
                          item.subscribe_enabled
                            ? 'Otomatik ödemeyi kapat'
                            : 'Otomatik ödemeyi aç'
                        "
                      >
                        <rs-table-cell-boolean
                          :value="item.subscribe_enabled"
                        />
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-action
                        @click="
                          handleUpdateClick(item, {
                            installments_enabled: !item.installments_enabled,
                          })
                        "
                        :tooltip="
                          item.installments_enabled
                            ? 'Taksitleri kapat'
                            : 'Taksitleri aç'
                        "
                      >
                        <rs-table-cell-boolean
                          :value="item.installments_enabled"
                        />
                      </rs-action>
                    </td>
                    <td class="text-center">
                      <rs-action-delete
                        @click="handleDeletePaymentProviderClick(item)"
                        confirmed
                        title="Kaldır"
                        icon
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <ClusterPaymentProviderForm
              ref="paymentProviderForm"
              @saved="loadPaymentProviderList"
            />
          </v-tab-item>
          <v-tab-item value="notes" class="pa-4">
            <!-- TODO: the notes should be separate based on "mode" -->
            <NoteList :allow-add="true" :extra-params="{ cluster_id: id }" />
          </v-tab-item>
          <v-tab-item value="documents" class="pa-4">
            <DocumentList
              :allow-add="true"
              :extra-params="{ cluster_id: id }"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <ImportForm ref="importForm" />
      <ActionLogList ref="actionLogList" />
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import { hasDataTable, hasForm, hasPermissions, isPage } from "@/view/mixins";
import { ACCOUNTING_RANGES } from "@/core/data/accounting-ranges.js";
import {
  techWorkerAuthorizationOptions,
  shareMeterRoundingTypeOptions,
  bankWorkflowCollectionTypeOptions,
  residentRequiredFieldsOptions,
  providerBankWorkflowCollectionTypeOptions,
} from "@/core/data/options.js";
import {
  ClusterNotificationProviderForm,
  ClusterPaymentProviderForm,
} from "@/view/content/definitions/forms";
import ClusterCompanyForm from "@/view/content/clusters/ClusterCompanyForm";
import DocumentList from "@/view/content/documents/DocumentList";
import NoteList from "@/view/content/Notes/NoteList";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import BlockList from "./BlockList";
import HouseTypeList from "./HouseTypeList";
import ImportForm from "./ImportForm";
import ClusterService from "@/core/services/cluster.service";

export default {
  name: "ClusterForm",
  mixins: [hasDataTable, hasForm, hasPermissions, isPage],
  components: {
    ActionLogList,
    BlockList,
    ClusterNotificationProviderForm,
    ClusterPaymentProviderForm,
    DocumentList,
    HouseTypeList,
    ClusterCompanyForm,
    ImportForm,
    NoteList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: "admin",
    },
  },
  watch: {
    id() {
      this.load();
    },
    selectedTab(newVal) {
      if (newVal == "other") {
        if (
          this.formDataOthers.notification_minimum_balance != undefined &&
          this.formDataOthers.notification_minimum_balance != ""
        ) {
          document.getElementsByClassName("formatted-text")[1].innerHTML =
            this.numberToLocaleFormat(
              this.formDataOthers.notification_minimum_balance
            );
        }

        if (
          this.formDataOthers.house_risk_price != undefined &&
          this.formDataOthers.house_risk_price != ""
        ) {
          document.getElementsByClassName("formatted-text")[2].innerHTML =
            this.numberToLocaleFormat(this.formDataOthers.house_risk_price);
        }
      } else if (newVal === "cc") {
        this.loadPaymentProviderList();
      } else if (newVal === "sms") {
        this.loadNotificationProviderList();
      }
    },
  },
  computed: {
    ...mapGetters(["clusterId", "managementTypeList"]),
    templateDownloadTarget() {
      return Vue.axios.defaults.baseURL + "templates/import/cluster";
    },
    saveable() {
      if (!this.id) {
        return true;
      }

      for (const index in this.formData) {
        if (this.originalData[index] === undefined) {
          continue;
        }

        if (this.formData[index] !== this.originalData[index]) {
          return true;
        }
      }

      return false;
    },
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
  data() {
    return {
      selectedTab: "info",
      isLoading: false,
      deletable: false,
      originalData: {},
      bankAccountList: [],
      pageMeta: {
        title: "Toplu Yaşam Alanı Ekleme",
      },
      formData: {
        title: null,
        name: null,
        address: null,
        city_id: null,
        district_id: null,
        tax_office: null,
        tax_number: null,
        house_count: null,
        // receipt_name: null,
        // owner_id: null,
        // website: null,
        phone: null,
        management_type_id: null,
        active_company: {},
        company_user_id: null,
        is_closed: false,
        is_demo: false,
        is_active: true,
        demo_ends_at: null,
        has_budgets: false,
        legal_beginning_date: null,
        manager_resident_id: null,
        has_bank_integration: false,
        invoicing_begins_description: null,
        should_delete_on: null,
        agreement_renewal_on: null,
        suspends_on: null,

        // Customer fields
        customer_id: null,
        invoicing_begins_on: null,
        invoicing_ends_on: null,
        create_customer: false,
        new_customer: {
          customer_group_id: null,
          product_id: null,
          amount: null,
        },
      },
      formDataOthers: {
        accouting_range_id: 1,
        bank_workflow_collecting_type_id: 1,
        bank_workflow_payment_type_id: 1,
        cash_payment_notification: false,
        cash_collection_notification: false,
        deferment_calculation_period: "daily",
        deferment_grace_period: 0,
        deferment_interest_percent: 5,
        expired_option_days: 0,
        fee_last_payment_day: null,
        has_cash_collection_notifications: false,
        has_cash_payment_notifications: false,
        has_share_meter: false,
        has_module_lawyer: false,
        has_module_accounting: false,
        house_risk_price: null,
        notification_minimum_balance: null,
        person_risk_parameter: null,
        report_logo: null,
        share_expense_data: false,
        share_meter_rounding_type_id: 1,
        tech_worker_authorization_id: 1,
        updates_person_data: false,
        resident_required_fields: [],
        has_future_owners: false,
      },
      formDataSms: {
        sms_username: null,
        sms_password: null,
        sms_title: null,
        sms_company_id: 1,
      },
      formDataBankIntegration: {
        bank_activity_notification_manager: false,
        bank_activity_notification_admin: false,
      },
      formDataCC: {
        pos_account_id: null,
        sipay_merchant_id: null,
      },
      customerCluster: {},
      smsCompanyList: [{ id: 1, name: "GoldSMS" }],
      accountingRangeOptions: ACCOUNTING_RANGES,
      techWorkerAuthorizationOptions: techWorkerAuthorizationOptions,
      shareMeterRoundingTypeOptions: shareMeterRoundingTypeOptions,
      bankWorkflowCollectionTypeOptions: bankWorkflowCollectionTypeOptions,
      providerBankWorkflowCollectionTypeOptions:
        providerBankWorkflowCollectionTypeOptions.filter((v) => v.id !== 0),
      residentRequiredFieldsOptions,
      paymentProviderList: [],
      notificationProviderList: [],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves company data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      this.$api
        .query(`clusters/${this.id}`)
        .then((response) => {
          const originalData = Object.assign({}, response.data.data);
          this.pageMeta.title = originalData.name + " Düzenleme";
          this.updateClusterId(originalData.id);

          const formData = Object.assign({}, this.formData);

          for (const index in formData) {
            if (response.data.data[index] !== undefined) {
              formData[index] = response.data.data[index];
            }
          }

          for (const index in this.formDataOthers) {
            if (response.data.data[index] !== undefined) {
              this.formDataOthers[index] = response.data.data[index];
            }
          }

          for (const index in this.formDataSms) {
            if (response.data.data[index] !== undefined) {
              this.formDataSms[index] = response.data.data[index];
            }
          }
          for (const index in this.formDataBankIntegration) {
            if (response.data.data[index] !== undefined) {
              this.formDataBankIntegration[index] = response.data.data[index];
            }
          }
          for (const index in this.formDataCC) {
            if (response.data.data[index] !== undefined) {
              this.formDataCC[index] = response.data.data[index];
            }
          }

          if (
            originalData.active_company &&
            originalData.active_company.company_user_id
          ) {
            originalData.company_user_id =
              originalData.active_company.company_user_id;

            formData.company_user_id =
              originalData.active_company.company_user_id;
          } else {
            originalData.company_user_id = null;
            formData.company_user_id = null;
          }

          if (
            formData.agreement_price != undefined &&
            formData.agreement_price != ""
          ) {
            document.getElementsByClassName("formatted-text")[0].innerHTML =
              this.numberToLocaleFormat(formData.agreement_price);
          }

          this.originalData = originalData;
          this.formData = formData;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
        .then(() => {
          this.loadPaymentProviderList();
          this.loadNotificationProviderList();

          if (this.hasRole("superadministrator")) {
            this.loadCustomerCluster();
          }
        });

      const params = { cluster_id: this.id, per_page: 1 };
      this.deletable = false;

      this.$api.query("balance-activities", { params }).then((response) => {
        if (response.data.data.length === 0) {
          this.deletable = true;
        }
      });
    },
    loadCustomerCluster() {
      const params = {
        cluster_id: this.id,
        is_active: true,
        order_by: "starts_on",
        order: "desc",
      };

      this.$api
        .query(`api-super-admin/customer-clusters`, { params })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.customerCluster = response.data.data[0];
            this.formData.invoicing_ends_on = this.customerCluster.ends_on;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    changeFetchDate() {
      this.$api
        .query(`clusters/${this.id}`)
        .then((res) => {
          if (
            res.data.data.sms_credits_fetched_at !=
            this.originalData.sms_credits_fetched_at
          ) {
            this.originalData.sms_credits_fetched_at =
              res.data.data.sms_credits_fetched_at;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkSMS() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .query(`clusters/${this.id}/check-sms-credits`)
        .then((res) => {
          if (
            res.data.data.credits != this.originalData.sms_remaining_credits
          ) {
            this.originalData.sms_remaining_credits = res.data.data.credits;
          }
          this.changeFetchDate();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormOthersSubmit(event) {
      if (this.isLoading || !this.$refs.formOthers.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      return this.handleSubmit(event, this.formDataOthers);
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (this.formData.management_type_id === null) {
        delete this.formData.management_type_id;
      }

      return this.handleSubmit(event, this.formData);
    },
    handleSubmit(event, formData) {
      this.isLoading = true;
      let data = Object.assign({}, formData);
      let config = {};

      if (this.formDataOthers.new_report_logo) {
        config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        data = new FormData();
        data.append("_method", "put");

        for (const index in formData) {
          if (formData[index] === undefined || formData[index] === null) {
            data.append(index, "");
          } else if (formData[index].constructor.name === "Boolean") {
            data.append(index, formData[index] ? "1" : "0");
          } else if (formData[index].constructor.name === "Array") {
            if (formData[index].length === 0) {
              data.append(index, "[]");
            } else {
              data.append(index, formData[index]);
            }
          } else {
            data.append(index, formData[index]);
          }
        }
      }

      if (this.id) {
        if (data.constructor.name !== "FormData") {
          data._method = "put";
        }

        this.$api
          .post(`clusters/${this.id}`, data, config)
          .then((response) => {
            ClusterService.init();
            this.loadData(response);

            for (const index in this.formDataOthers) {
              if (response.data.data[index] !== undefined) {
                this.formDataOthers[index] = response.data.data[index];
              }
            }
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post("clusters", data, config)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            ClusterService.init();
            this.$router.replace({
              name: "definitions.clusters.edit",
              params: { id: response.data.data.id },
            });
            this.formData.create_customer = false;
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCCFormSubmit(event) {
      if (this.isLoading || !this.$refs.ccForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.id}/enable-card-payments`, this.formDataCC)
        .then(() => {
          this.$toast.success("Kredi kartı entegrasyonu yapıldı.");

          if (event && event.closeOnSave) {
            this.$router.back();
            return;
          }

          setTimeout(() => {
            this.load();
          }, 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDisableCCSubmit() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.id}/disable-card-payments`)
        .then(() => {
          this.$toast.success("Kredi kartı entegrasyonu iptal edildi.");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;

          this.load();
        });
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleSmsFormSubmit() {
      if (this.isLoading || !this.$refs.smsForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .put(`clusters/${this.id}`, this.formDataSms)
        .then(() => {
          this.$toast.success(
            "SMS Entegrasyonu başarılı bir şekilde gerçekleşmiştir"
          );

          setTimeout(() => this.load(), 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBankFormSubmit() {
      if (this.isLoading || !this.$refs.bankForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.id}/bank-integration-test`)
        .then(() => {
          this.$toast.success(
            "Banka entegrasyonu başarılı bir şekilde gerçekleşmiştir"
          );

          setTimeout(() => this.load(), 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBankIntegrationConfigFormSubmit() {
      if (this.isLoading || !this.$refs.bankIntegrationConfigForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .put(`clusters/${this.id}`, this.formDataBankIntegration)
        .then(() => {
          this.$toast.success("Kaydedildi");

          setTimeout(() => this.load(), 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBankActivityClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.id}/bank-integration-load`, this.formDataSms)
        .then((response) => {
          const count = response.data.data;
          this.$toast.success(
            `${count} adet hesap için işlemler kuyruğa alındı`
          );

          setTimeout(() => this.load(), 50);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBankIntegrationActive() {
      this.isLoading = true;
      this.bankAccountList = [];

      this.$api
        .query("bank-accounts", { params: { cluster_id: this.id } })
        .then((response) => {
          this.bankAccountList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCompanyFormSaved(formData) {
      this.formData.company_user_id = null;
      this.formData.active_company = formData;
      this.formData.authorized_person_id = null;
      this.formData.authorized_company_user_id = null;
      this.formData.manager_resident_id = null;

      this.load();
    },
    handleReportLogoChange(file) {
      if (!file) {
        this.formDataOthers.new_report_logo = null;
        return;
      }

      const allowedMimes = ["image/jpg", "image/jpeg", "image/png"];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.formDataOthers.new_report_logo = null;
        this.$toast.error("Sadece PNG veya JPG yükleyebilirsiniz.");
        return;
      }

      this.formDataOthers.new_report_logo = file;
    },
    handleRemoveReportLogoClick() {
      this.formDataOthers.new_report_logo = null;
      this.formDataOthers.report_logo = null;
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`clusters/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.$router.replace({
            name: "definitions.clusters.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Cluster", this.id);
    },
    loadPaymentProviderList() {
      if (!this.id || !this.hasRole("superadministrator")) {
        return false;
      }

      const params = {
        cluster_id: this.id,
      };
      this.paymentProviderList = [];
      this.isLoading = true;

      this.$api
        .query("cluster-payment-providers", { params })
        .then((response) => {
          this.paymentProviderList = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeletePaymentProviderClick(item) {
      if (this.isLoading || !item) {
        return false;
      }

      this.isLoading = true;
      this.$api
        .delete(`cluster-payment-providers/${item.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          setTimeout(() => this.loadPaymentProviderList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleUpdateClick(item, data) {
      if (this.isLoading || !item) {
        return false;
      }

      this.isLoading = true;
      this.$api
        .put(`cluster-payment-providers/${item.id}`, data)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_updated"));
          setTimeout(() => this.loadPaymentProviderList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadNotificationProviderList() {
      if (!this.id) {
        return false;
      }

      const params = {
        cluster_id: this.id,
      };
      this.notificationProviderList = [];
      this.isLoading = true;

      this.$api
        .query("cluster-notification-providers", { params })
        .then((response) => {
          this.notificationProviderList = response.data.data.map((v) => {
            v.isLoading = false;
            return v;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteNotificationProviderClick(item) {
      if (this.isLoading || !item) {
        return false;
      }

      this.isLoading = true;
      this.$api
        .delete(`cluster-notification-providers/${item.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          setTimeout(() => this.loadNotificationProviderList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleNotificationProviderUpdateClick(item, data) {
      if (this.isLoading || !item) {
        return false;
      }

      this.isLoading = true;
      this.$api
        .put(`cluster-notification-providers/${item.id}`, data)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_updated"));
          setTimeout(() => this.loadNotificationProviderList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleNotificationProviderFetchClick(item) {
      if (this.isLoading || !item || item.isLoading) {
        return false;
      }

      item.isLoading = true;

      this.$api
        .get(`cluster-notification-providers/${item.id}/credits`)
        .then((response) => {
          item.remaining_credits = response.data.data.credits;
          item.fetched_at = new Date();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    handleResidentFileClick() {
      document.querySelector("input#importResidentAccountInput").value = null;
      document.querySelector("input#importResidentAccountInput").click();
    },
    handleImportResidentFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
      ];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece XLSX yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(
          `clusters/${this.id}/import-resident-debt-list-files`,
          data,
          config
        )
        .then((response) => {
          this.$toast.success(
            response.data.data.count + " adet belge içeri aktarıldı"
          );
        })
        .catch((error) => {
          this.handleError(error, { timeout: 0 });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleProductSelected(product) {
      if (product) {
        this.formData.new_customer.amount = product.monthly_price_per_house;
      }
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}

.report-logo {
  max-height: 120px;
  max-width: 300px;
}
</style>
