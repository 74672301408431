<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Reklam</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Reklam Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <!-- <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action> -->
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text v-if="clusterId">
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <v-tab to="#clicks" replace :disabled="!id">
          <v-badge color="deep-purple lighten-3">Tıklayanlar</v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items touchless v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <p v-if="!id">
            <strong>{{ cluster.name }}</strong> için reklam ekliyorsunuz.
          </p>
          <v-form
            :readonly="disabled"
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col cols="12" sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                  v-model="formData.name"
                  hide-details="auto"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <rs-text-field
                  type="url"
                  label="Hedef URL"
                  :rules="[rules.maxLength(formData.name, 1000)]"
                  v-model="formData.target_url"
                  hide-details="auto"
                  hint="https:// ile başlamalıdır"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-file-input
                  :label="$t('labels.document')"
                  :rules="[]"
                  accept="image/jpeg,image/png"
                  @change="handleFileChange"
                  :disabled="readonly ? true : disabled"
                  hint="1200x400 piksel JPG veya PNG"
                  persistent-hint
                />
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.is_active"
                  :label="formData.is_active ? 'Aktif' : 'Pasif'"
                  hide-details="auto"
                ></v-switch>
              </v-col>
            </v-row>

            <img
              class="mt-5"
              style="max-width: 100%"
              v-if="originalData?.document?.url"
              :src="originalData.document.url"
            />

            <rs-form-buttons
              :isLoading="isLoading"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <v-tab-item value="clicks" class="pa-4">
          <AdvertClickList :advert-id="id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-text v-else>
      Reklam ekleyebilmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";
import { mapGetters } from "vuex";
import { AdvertClickList } from "../components";
// import { CustomerClusterList } from "../components";

export default {
  mixins: [hasForm],
  components: {
    AdvertClickList,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
        cluster_id: null,
        is_active: true,
        target_url: null,
      },
      file: null,
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/adverts/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.totals = response.data.data.totals || {};
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const data = new FormData();
      if (this.file) {
        data.append("document", this.file);
      }
      data.append("name", this.formData.name);
      data.append("cluster_id", this.clusterId);
      data.append("is_active", this.formData.is_active);
      if (this.formData.target_url) {
        data.append("target_url", this.formData.target_url);
      }

      let url = "api-super-admin/adverts";

      if (this.id) {
        data.append("_method", "put");
        url += `/${this.id}`;
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post(url, data, config)
        .then((response) => {
          this.$toast.success("Kaydedildi");
          if (event && event.closeOnSave) {
            this.$router.back();
            return;
          }

          if (!this.id) {
            this.$router.replace({
              name: "admin.ads.edit",
              params: { id: response.data.data.id },
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleFileChange(file) {
      if (!file) {
        this.file = null;
        return;
      }

      const allowedMimes = ["image/jpg", "image/jpeg", "image/png"];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.file = null;
        this.$toast.error("Sadece JPG ve PNG yükleyebilirsiniz.");
        return;
      }

      this.file = file;
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>
