<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Ziyaretçi Kaydı</small><br />
          <span>{{ originalData.visitor_name }}</span>
        </div>
        <span v-else>Ziyaretçi Kaydı Ekle</span>
      </h3>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text v-if="clusterId">
      <v-form
        :readonly="disabled"
        @submit.prevent.stop="handleFormSubmit"
        ref="form"
        :disabled="isLoading || disabled"
      >
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="Ziyaretçi Adı"
              :rules="[
                rules.required,
                rules.maxLength(formData.visitor_name, 100),
              ]"
              v-model="formData.visitor_name"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select-resident
              label="İlgili Sakin"
              v-model="formData.resident_id"
              :cluster-id="clusterId"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              :rules="[rules.maxLength(formData.description, 1000)]"
              v-model="formData.description"
              rows="3"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-text-field
              type="datetime-local"
              label="Giriş"
              :rules="[rules.required]"
              v-model="formData.visited_at"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-text-field
              type="datetime-local"
              label="Çıkış"
              v-model="formData.left_at"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-switch
              label="Kargo"
              v-model="formData.is_courier"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :isLoading="isLoading"
          @cancel="handleCancelClick"
          @submit="handleFormSubmit"
          v-if="!disabled"
        />
      </v-form>
    </v-card-text>

    <v-card-text v-else>
      Ekleme yapabilmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [hasForm, hasPermissions],
  watch: {
    id() {
      this.load();
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      formData: {
        visitor_name: null,
        resident_id: null,
        description: null,
        visited_at: new Date().toISOString().substring(0, 16),
        left_at: null,
        is_courier: false,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`visitor-logs/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(response.data.data.cluster_id);

          this.formData.visited_at = this.formData.visited_at
            .replace("T", " ")
            .substring(0, 16);

          if (this.formData.left_at !== null) {
            this.formData.left_at = this.formData.left_at
              .replace("T", " ")
              .substring(0, 16);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      formData.visited_at = formData.visited_at.replace("T", " ");

      if (formData.left_at) {
        formData.left_at = formData.left_at.replace("T", " ");
      }

      if (this.id) {
        this.$api
          .put(`visitor-logs/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.cluster_id = this.clusterId;
        this.$api
          .post("visitor-logs", formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "communication.visitor-logs.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>
