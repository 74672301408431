var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[(_vm.safe || _vm.bankAccount)?_c('template',{slot:"header"},[_vm._v(" "+_vm._s((_vm.safe || _vm.bankAccount).name)+" Açılış Fişi Ekle ")]):_vm._e(),_c('template',{slot:"body"},[_c('p',[_vm._v(" Gireceğiniz bakiye, "+_vm._s(_vm.safe ? "kasanın" : "banka hesabının")+" yeni bakiyesi olarak kaydedilecek. ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"cols":"12","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.date)?_c('rs-text-field',{attrs:{"label":_vm.$t('labels.date'),"value":_vm.date.toLocaleDateString(),"readonly":"","filled":""}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-money-field',{attrs:{"label":"Bakiye","rules":_vm.disabled
                ? []
                : [
                    _vm.rules.required,
                    _vm.rules.max(_vm.formData.balance, 9999999.99),
                    _vm.rules.min(_vm.formData.balance, -9999999.99),
                  ],"hint":"Alacaklı girmek için eksi, borçlu girmek için artı bakiye girebilirsiniz.","disabled":_vm.disabled},model:{value:(_vm.formData.balance),callback:function ($$v) {_vm.$set(_vm.formData, "balance", $$v)},expression:"formData.balance"}})],1),_c('v-col',[_c('rs-textarea',{attrs:{"label":_vm.$t('labels.description'),"rules":_vm.disabled ? [] : [_vm.rules.required],"filled":_vm.disabled,"readonly":_vm.disabled},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":"","hide-save-close":_vm.disabled},on:{"cancel":_vm.handleCancelClick,"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }