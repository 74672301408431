<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">BB Tipine Göre Tahakkuk</h3>
        </div>
        <AddResidentsToBatchForm ref="batchForm" />
        <v-col class="text-right" v-if="can('edit-assessment')">
          <v-btn
            class="ms-2"
            v-if="id && originalData.applied === false"
            small
            color="pink"
            outlined
            @click="handleCreateDebtsClick"
            ref="createDebtsButton"
            :loading="isLoading"
          >
            Borçlandır
          </v-btn>
          <v-btn
            class="ms-2"
            v-if="
              id &&
              originalData.applied === true &&
              originalData.debitted_at !== null
            "
            small
            color="pink"
            outlined
            @click="handleDeleteDebtsClick"
            ref="deleteDebtsButton"
            :loading="isLoading"
          >
            Borçlandırma İptal
          </v-btn>
          <v-btn
            class="ms-2"
            v-if="
              id &&
              originalData.applied === true &&
              originalData.debitted_at !== null
            "
            small
            color="pink"
            outlined
            @click="handleSendNotificationClick"
            ref="sendNotificationButton"
            :loading="isLoading"
          >
            Bildirim Gönder
          </v-btn>
          <!-- <v-btn
            v-if="id"
            class="ms-2"
            small
            color="pink"
            outlined
            :loading="isLoading"
          >
            Makbuz Yazdır
          </v-btn> -->
          <v-btn
            class="me-2 ml-2"
            color="pink"
            outlined
            @click="showActionLogs"
            v-if="id"
            icon
            small
          >
            İ
          </v-btn>
        </v-col>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>
      <div class="card-body" v-if="id || clusterId">
        <p
          class="text-uppercase"
          v-if="originalData.queued_at && originalData.debitted_at === null"
        >
          <strong>
            Borçlandırma işlemi kuyruğa alındı:
            {{ new Date(originalData.queued_at).toLocaleString() }}
          </strong>
        </p>
        <v-progress-linear
          v-if="originalData.queued_at && originalData.debitted_at === null"
          color="pink"
          indeterminate
          class="mb-3"
        />

        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col cols="12" sm="6">
              <rs-text-field
                type="number"
                step="1"
                min="2019"
                max="2025"
                label="Yıl"
                :rules="[
                  rules.required,
                  rules.min(formData.name, 2019),
                  rules.max(formData.name, new Date().getFullYear() + 2),
                ]"
                v-model="formData.year"
                :readonly="!editable"
                :filled="!editable"
                @change="handlePeriodChange"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Ay"
                :rules="[rules.required]"
                v-model="formData.month"
                :items="monthListLocal"
                :readonly="!editable"
                :filled="!editable"
                @change="handlePeriodChange"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Borçlandırma Tipi"
                :rules="[rules.required]"
                v-model="formData.income_type_id"
                :items="incomeTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
                @change="handlePeriodChange"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select-project
                label="Proje"
                v-model="formData.project_id"
                :readonly="!editable"
                :filled="!editable"
                :clearable="editable"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-datepicker
                label="Tahakkuk Tarihi"
                v-model="formData.issues_on"
                :rules="!editable ? [] : [rules.required]"
                :disabled="!editable"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-datepicker
                label="Son Ödeme Tarihi"
                v-model="formData.last_payment_on"
                :rules="!editable ? [] : [rules.required]"
                :disabled="!editable"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Hesaplama Tipi"
                :rules="[rules.required]"
                v-model="formData.calculation_type_id"
                :items="assessmentCalculationTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select-block
                v-if="formData.calculation_type_id === 2"
                label="Bloklar"
                :rules="[rules.required]"
                v-model="formData.block_ids"
                :readonly="!editable"
                :filled="!editable"
                multiple
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Borçlu Tipi"
                :rules="[rules.required]"
                v-model="formData.debitor_type_id"
                :items="debitorTypeList"
                :readonly="!editable"
                :filled="!editable"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-text-field
                label="Açıklama"
                :rules="[
                  rules.required,
                  rules.maxLength(formData.description, 100),
                ]"
                v-model="formData.description"
                :readonly="!editable"
                :filled="!editable"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-select
                label="Gecikme Hesaplama Türü"
                :rules="[rules.required]"
                :items="[
                  { id: 'daily', name: 'Günlük' },
                  { id: 'monthly', name: 'Aylık' },
                ]"
                v-model="formData.deferment_calculation_period"
                :readonly="!editable"
                :filled="!editable"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-text-field
                :label="$t('labels.expired_option_days')"
                :rules="[rules.required]"
                v-model="formData.deferment_grace_period"
                type="number"
                step="1"
                min="0"
                max="60"
                :readonly="!editable"
                :filled="!editable"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <rs-text-field
                label="Gecikme Faiz Oranı (Aylık)"
                :rules="[rules.required]"
                v-model="formData.deferment_interest_percent"
                prefix="%"
                type="number"
                step="1"
                min="0"
                max="100"
                :readonly="!editable"
                :filled="!editable"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="formData.send_push_notifications"
                label="Mobil Bildirim Gönderilsin mi?"
                hide-details="auto"
                :readonly="!editable"
                :filled="!editable"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-5">
            <v-col>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>BB Tipi</th>
                      <th>Tutar</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in houseTypes" :key="item.house_type_id">
                      <td :disabled="item.disabled">
                        {{ item.house_type_name }}
                        ({{ item.house_count }})
                      </td>
                      <td :disabled="item.disabled">
                        <rs-money-field
                          v-model="item.amount"
                          :rules="
                            item.disabled || !editable
                              ? []
                              : [
                                  rules.required,
                                  rules.min(item.amount, 0.01),
                                  rules.max(item.amount, 9999999.99),
                                ]
                          "
                          :disabled="item.disabled || !editable"
                        />
                      </td>
                      <td class="text-end">
                        <v-btn
                          @click="handleHouseTypeToggle(item)"
                          class="btn"
                          color="pink"
                          outlined
                          small
                          :disabled="!editable"
                        >
                          {{ item.disabled ? "Etkinleştir" : "İptal Et" }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            v-if="!disabled"
            @submit="handleFormSubmit"
          />
        </v-form>

        <div v-if="id && totals.total_amount === 0" class="p-5 mt-5">
          <h3>Borçlandırılacak Bağımsız Bölümler</h3>

          <HouseAssessmentList
            :assessment-id="id"
            v-if="totals.total_amount === 0"
          />
        </div>

        <div v-if="id && originalData.applied">
          <v-divider class="my-10" />
          <h3 class="ps-5">Özet</h3>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Toplam Tutar</th>
                  <th>Toplam Gecikme</th>
                  <th>Toplam Ödenen</th>
                  <th>Kalan</th>
                  <th>Kalan Gecikme</th>
                  <th>İcra</th>
                  <th>İcra Tahsilat</th>
                  <th>İcra Kalan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <rs-table-cell-number price :value="totals.total_amount" />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.total_deferment"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.total_collecting_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.remaining_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.remaining_deferment"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_collecting_amount"
                    />
                  </td>
                  <td class="text-center">
                    <rs-table-cell-number
                      price
                      :value="totals.enforcement_remaining_amount"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="id && originalData.applied">
          <v-divider class="my-10" />
          <h3 class="ps-5">Tahakkuklar</h3>

          <v-tabs v-model="selectedTab">
            <v-tab href="#all">Tüm Tahakkuklar</v-tab>
            <v-tab href="#not-collected">Ödemeyenler</v-tab>
            <v-tab href="#collected">Ödeyenler</v-tab>
          </v-tabs>

          <v-tabs-items touchless v-model="selectedTab">
            <v-tab-item class="py-4" value="all">
              <DebtList :assessment-id="id" />
            </v-tab-item>
            <v-tab-item class="py-4" value="not-collected">
              <DebtList :assessment-id="id" :unpaid-only="true" />
            </v-tab-item>
            <v-tab-item class="py-4" value="collected">
              <DebtList :assessment-id="id" :paid-only="true" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>

      <div class="card-body" v-else>
        <p>Tahakkuk eklemek için üst menüden bir toplu yaşam alanı seçin.</p>
      </div>
      <ActionLogList ref="actionLogList" />
    </div>

    <v-tooltip bottom :activator="$refs.deleteDebtsButton">
      <span>Bu tahakkuktan oluşturulmuş tüm borçlar silinir.</span>
    </v-tooltip>
    <v-tooltip bottom :activator="$refs.createDebtsButton">
      <span>Tahakkuk kapsamındaki tüm daireler borçlandırılır.</span>
    </v-tooltip>
    <v-tooltip bottom :activator="$refs.sendNotificationButton">
      <span>Borçlu ve ödememiş kişilere bildirim gönderilir.</span>
    </v-tooltip>

    <rs-confirm
      ref="confirmCreateDebts"
      :loading="isLoading"
      @confirmed="handleCreateDebtsSubmitClick"
      @cancelled="$refs.confirmCreateDebts.hide()"
    />
    <rs-confirm
      ref="confirmDeleteDebts"
      :loading="isLoading"
      @confirmed="handleDeleteDebtsSubmitClick"
      @cancelled="$refs.confirmDeleteDebts.hide()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionLogList from "@/view/content/action-logs/ActionLogList";
import { hasForm, hasPermissions } from "@/view/mixins";
import DebtList from "../DebtList";
import HouseAssessmentList from "../HouseAssessmentList";
import AddResidentsToBatchForm from "@/view/pages/communication/batch-notifications/AddResidentsToBatchForm";

export default {
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    AddResidentsToBatchForm,
    DebtList,
    HouseAssessmentList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
    clusterId: {
      handler() {
        if (this.id) {
          return;
        }

        this.$nextTick(() => {
          if (this.clusterId) {
            this.formData.cluster_id = this.clusterId;
          } else {
            this.formData.cluster_id = null;
          }
        });
      },
      immediate: true,
    },
    cluster: {
      handler() {
        if (this.id) {
          return;
        }

        this.formData.deferment_grace_period =
          this.cluster.deferment_grace_period;
        this.formData.deferment_interest_percent =
          this.cluster.deferment_interest_percent;
        this.formData.deferment_calculation_period =
          this.cluster.deferment_calculation_period;
        this.handlePeriodChange();
      },
      immediate: true,
    },
    houseTypeList: {
      handler() {
        if (this.id) {
          return;
        }

        this.populateHouseTypeList();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "incomeTypeList",
      "assessmentCalculationTypeList",
      "debitorTypeList",
      "monthList",
    ]),
    houseTypeList() {
      return this.cluster && this.cluster.house_types;
    },
    monthListLocal() {
      return this.monthList.map((item, index) => {
        return {
          id: index + 1,
          name: item,
        };
      });
    },
    editable() {
      if (this.disabled) {
        return false;
      }

      if (!this.id === null) {
        return true;
      }

      if (this.originalData.applied) {
        return false;
      }

      return true;
    },
  },
  data() {
    return {
      selectedTab: "all",
      /**
       * Total amounts about assessment
       * @type {Object}
       */
      totals: {},
      houseTypes: [],
      queueInterval: null,
      formData: {
        assessment_type_id: 2,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        income_type_id: 1,
        project_id: null,
        issues_on: null,
        last_payment_on: null,
        calculation_type_id: 1,
        block_ids: [],
        debitor_type_id: 2,
        description: null,
        deferment_calculation_period: null,
        deferment_grace_period: null,
        deferment_interest_percent: null,
        send_push_notifications: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`assessments/${this.id}`)
        .then((response) => {
          this.originalData = Object.assign({}, response.data.data);
          this.totals = response.data.data.totals;

          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.formData[index] = response.data.data[index];
            }
          }

          this.populateHouseTypeList();
          this.updateClusterId(this.originalData.cluster_id);

          if (
            this.originalData.queued_at &&
            this.originalData.debitted_at === null
          ) {
            // we need to check if queue job is finished
            if (this.queueInterval === null) {
              this.queueInterval = window.setInterval(() => this.load(), 10000);
            }
          } else if (this.queueInterval !== null) {
            this.clearQueueInterval();
            this.$toast.success("Bağımsız bölümler borçlandırıldı");
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      formData.assessment_house_types = this.houseTypes.filter((item) => {
        return !item.disabled;
      });

      if (formData.calculation_type_id === 1) {
        delete formData.block_ids;
      }

      if (this.id) {
        this.$api
          .put(`assessments/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.originalData = response.data.data;
            if (event && event.closeOnSave) {
              this.handleCancelClick();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`clusters/${this.clusterId}/assessments`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");

            this.$router.replace({
              name: "assessments.house-type.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    populateHouseTypeList() {
      const formList = [];

      if (this.id) {
        const currentData = [];
        if (this.originalData.assessment_house_types) {
          for (const index in this.originalData.assessment_house_types) {
            const item = this.originalData.assessment_house_types[index];
            currentData[item.house_type_id] = item.amount;
          }
        }

        for (const index in this.houseTypeList) {
          if (!this.editable) {
            if (currentData[this.houseTypeList[index].id] === undefined) {
              continue;
            }
          }

          formList.push({
            house_type_id: this.houseTypeList[index].id,
            house_type_name: this.houseTypeList[index].name,
            house_count: this.houseTypeList[index].house_count,
            amount: currentData[this.houseTypeList[index].id]
              ? currentData[this.houseTypeList[index].id]
              : 0,
            disabled:
              currentData[this.houseTypeList[index].id] === undefined
                ? true
                : false,
          });
        }
      } else {
        for (const index in this.houseTypeList) {
          formList.push({
            house_type_id: this.houseTypeList[index].id,
            house_type_name: this.houseTypeList[index].name,
            amount: this.houseTypeList[index].fee || 0.0,
            disabled: false,
          });
        }
      }

      this.houseTypes = formList;
    },
    handlePeriodChange() {
      if (this.formData.year && this.formData.month) {
        let description = this.formData.year + "-";
        description += this.monthList[parseInt(this.formData.month) - 1];

        if (this.formData.income_type_id) {
          for (const index in this.incomeTypeList) {
            if (
              this.incomeTypeList[index].id === this.formData.income_type_id
            ) {
              description += " " + this.incomeTypeList[index].name;
            }
          }
        }

        this.formData.description = description;

        this.formData.issues_on =
          this.formData.year +
          "-" +
          this.formData.month.toString().padStart(2, "0") +
          "-01";

        const lastPaymentOn = new Date(
          Date.UTC(this.formData.year, this.formData.month, 0)
        );

        let lastPaymentDay = lastPaymentOn.getDate();

        if (this.cluster.fee_last_payment_day) {
          if (this.cluster.fee_last_payment_day < lastPaymentDay) {
            lastPaymentDay = this.cluster.fee_last_payment_day;
            lastPaymentOn.setUTCDate(lastPaymentDay);
          }
        }

        this.formData.last_payment_on = lastPaymentOn.toJSON().split("T")[0];
      }
    },
    handleCreateDebtsClick() {
      this.$refs.confirmCreateDebts.show(
        "Seçilen tüm bağımsız bölümler borçlandırılacak. Emin misiniz?"
      );
    },
    handleCreateDebtsSubmitClick() {
      if (this.isLoading) return false;

      if (!this.houseTypeList.length) {
        this.$toast.error("Borçlandırılacak bağımsız bölüm tipi yok.");
        this.$refs.confirmCreateDebts.hide();
        return;
      }

      this.isLoading = true;

      this.$api
        .post(`assessments/${this.id}/debit`)
        .then(() => {
          // this.$toast.success("Bağımsız bölümler borçlandırıldı.");
          // this.$nextTick(() => this.load());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.confirmCreateDebts.hide();
        })
        .then(() => {
          return this.load();
        })
        .then(() => {
          if (this.originalData.debitted_at !== null) {
            this.$toast.success("Bağımsız bölümler borçlandırıldı.");
          } else {
            this.$toast.warning("Borçlandırma işlemi kuyruğa alındı.", {
              timeout: 10000,
            });
          }
        });
    },
    handleDeleteDebtsClick() {
      this.$refs.confirmDeleteDebts.show(
        "Bu tahakkuktan oluşturulan tüm borçlar silinecek. Emin misiniz?"
      );
    },
    handleDeleteDebtsSubmitClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      this.$api
        .delete(`assessments/${this.id}/debit`)
        .then(() => {
          this.$toast.success("Borçlandırmalar iptal edildi.");
          this.$nextTick(() => this.load());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.confirmDeleteDebts.hide();
        });
    },
    handleSendNotificationClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = { assessment_id: this.id, per_page: 2000 };

      this.$api
        .query("income/debts", { params })
        .then((response) => {
          return response.data.data.map((item) => item.resident_id);
        })
        .then((residentIds) => {
          if (!residentIds.length) {
            this.$toast.info("Bildirim gönderilecek sakin yok.");
          } else {
            this.$refs.batchForm.show(residentIds, 1, {
              assessment_id: this.id,
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleSubmitSendSMSClick() {
      this.$refs.confirmSendSMS.hide();
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.$toast.success("SMS gönderimi kuyruğa alındı.");
        this.load();
      }, 1500);
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleHouseTypeToggle(item) {
      item.disabled = !item.disabled;
      item.amount = 0;
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Assessment", this.id);
    },
    clearQueueInterval() {
      window.clearInterval(this.queueInterval);
      this.queueInterval = null;
    },
  },
  destroyed() {
    if (this.queueInterval !== null) {
      this.clearQueueInterval();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      } else {
        this.handlePeriodChange();
      }
    });
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>

<style scoped>
td {
  opacity: 1;
  transition: opacity 0.3s ease-out !important;
}

td[disabled] {
  opacity: 0.4;
}
</style>
