<template>
  <div>
    <v-card :loading="isLoading" class="mb-3">
      <v-card-title>
        <h3 class="card-label">
          <div v-if="originalData.customer">
            <small>Müşteri Ödemesi</small><br />
            <span>{{ originalData.customer.name }}</span>
            <span
              ><rs-table-cell-date :value="originalData.invoiced_on"
            /></span>
          </div>
          <span v-else>Müşteriye Ödeme Ekle</span>
        </h3>
        <v-col class="text-right p-0">
          <rs-action-delete
            target="_blank"
            confirmed
            @click="handleDeleteClick"
            v-if="id"
            icon
            tooltip="Sil"
            :is-loading="isLoading"
          />
          <!-- <rs-action
            @click="showActionLogs"
            v-if="id"
            icon
            title="Düzenleme geçmişi"
          >
            <v-icon>mdi-information-variant</v-icon>
          </rs-action> -->
          <!-- <rs-action
            :href="
              'https://uygulama.parasut.com/416690/satislar/' +
              originalData.accounting_id
            "
            target="_blank"
            v-if="id && originalData.accounting_id"
            icon
            title="Paraşüt'te göster"
          >
            P
          </rs-action>
          <rs-action
            @confirmed="handleSyncClick"
            target="_blank"
            v-if="id && !originalData.accounting_id"
            icon
            tooltip="Paraşüt'e gönder"
            confirmed
            :is-loading="isLoading"
          >
            <v-icon>mdi-file-sync-outline</v-icon>
          </rs-action> -->
        </v-col>
        <!-- <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div> -->
      </v-card-title>
      <v-card-text>
        <v-form
          :readonly="disabled"
          @submit.prevent.stop="handleFormSubmit"
          ref="form"
          :disabled="isLoading || disabled"
        >
          <v-row dense>
            <v-col cols="12" sm="6">
              <rs-select-customer
                v-model="formData.customer_id"
                :rules="[rules.required]"
                @change:item="handleProductSelected"
                :readonly="id"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-datepicker
                label="Fatura Tarihi"
                v-model="formData.invoiced_on"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-money-field
                label="Toplam Tutar"
                v-model="formData.amount"
                disabled
              />
            </v-col>

            <v-col cols="12" sm="6">
              <rs-money-field
                label="Toplam Tutar KDV Dahil"
                v-model="formData.amount_tax_included"
                disabled
              />
            </v-col>

            <v-col sm="6" v-if="id">
              <rs-text-field
                label="Muhasebe Entegrasyon ID"
                v-model="formData.accounting_id"
                disabled
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :isLoading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
      </v-card-text>
    </v-card>

    <v-card :loading="isLoading">
      <v-card-title>Ödenmiş Faturalar</v-card-title>
      <v-card-text v-if="originalData.customer_id">
        <CustomerInvoicePaymentList
          ref="invoicePaymentList"
          :customer-id="originalData.customer_id"
          :payment-id="id"
          :allow-add="false"
        />
      </v-card-text>
      <v-card-text v-else>Kaydedilmemiş ödeme.</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { hasDataTable, hasForm, isPage } from "@/view/mixins";
import { CustomerInvoicePaymentList } from "../components";

export default {
  name: "AdminCustomerPaymentForm",
  mixins: [hasDataTable, hasForm, isPage],
  components: { CustomerInvoicePaymentList },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      pageMeta: {
        title: "Müşteri Ödemesi",
      },
      formData: {
        customer_id: null,
        invoiced_on: new Date().toJSON().split("T")[0],
        amount: null,
        amount_tax_included: null,
        accounting_id: null,
        is_auto_invoiced: false,
        items: [],
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      // const scopes = [
      //   "customer",
      //   "items",
      //   "items.customerCluster",
      //   "items.customerCluster.cluster",
      // ];
      // const params = Object.assign(this.buildSearchParams({ scopes: scopes }));

      return this.$api
        .query(`api-super-admin/customer-payments/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.pageMeta.title = this.originalData.customer.name + " Ödeme";
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-payments/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/customer-payments`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "admin.crm.customer-payments.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleSyncClick() {
      if (!this.id || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .post(`api-super-admin/customer-payments/${this.id}/sync`)
        .then(() => {
          this.$toast.success("Kaydedildi");
          setTimeout(() => {
            this.load();
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      return this.$api
        .delete(`api-super-admin/customer-payments/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          this.$toast.info("Tahsilatı paraşütten kendiniz silmelisiniz.", {
            timeout: 15,
          });

          this.$router.replace({
            name: "admin.crm.customer-payments.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>
