var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-calendar-search")]),_vm._v(" Aylık Gider Fatura Analiz ")],1),(_vm.clusterId)?_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-form',{ref:"form",staticClass:"mb-3",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"cols":"12"}},[_c('v-col',{attrs:{"sm":"2"}},[_c('rs-datepicker',{attrs:{"label":"Başlangıç Tarih","rules":[
                  _vm.rules.maxDate(_vm.formData.start_date, _vm.formData.end_date),
                ]},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('rs-datepicker',{attrs:{"label":"Bitiş Tarihi","rules":[
                  _vm.rules.minDate(_vm.formData.end_date, _vm.formData.start_date),
                ]},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('rs-select-provider',{model:{value:(_vm.formData.provider_id),callback:function ($$v) {_vm.$set(_vm.formData, "provider_id", $$v)},expression:"formData.provider_id"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('rs-select-project',{model:{value:(_vm.formData.project_id),callback:function ($$v) {_vm.$set(_vm.formData, "project_id", $$v)},expression:"formData.project_id"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-switch',{attrs:{"label":"Gider Tiplerini Göster","hide-details":"auto"},model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-cancel":"","hide-save-close":"","saveLabel":"Filtrele"},on:{"submit":_vm.handleFormSubmit}})],1),_c('v-col',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12"}},[_c('v-btn',{attrs:{"small":"","color":"pink","outlined":""},on:{"click":function($event){return _vm.exportXLSX()}}},[_c('v-icon',[_vm._v("mdi-file-table")]),_vm._v(" Dışa Aktar ")],1)],1)],1)],1)],1)],1),_c('v-simple-table',{attrs:{"dense":"","id":"printTable"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"bg-secondary bordered"},[_c('th',{staticClass:"text-left sticky bg-secondary",attrs:{"rowspan":"2"}},[_vm._v(" Gider Türü ")]),(_vm.childExpensesEnabled)?_c('th',{staticClass:"text-left sticky bg-secondary",attrs:{"rowspan":"2"}},[_vm._v(" Gider Tipi ")]):_vm._e(),_vm._l((_vm.yearList),function(year){return [_c('th',{key:year,attrs:{"colspan":_vm.headerList.filter((h) => h.year === year && !h.totals)
                    .length}},[_vm._v(" "+_vm._s(year)+" ")]),_c('th',{key:year + 'sum',attrs:{"rowspan":"2"}},[_vm._v(_vm._s(year)+" Toplam")]),_c('th',{key:year + 'avg',attrs:{"rowspan":"2"}},[_vm._v(_vm._s(year)+" Ortalama")])]}),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Genel Toplam")])],2),_c('tr',{staticClass:"bg-secondary bordered"},_vm._l((_vm.headerList.filter((v) => !v.totals)),function(cell){return _c('th',{key:cell.key,staticClass:"text-left",attrs:{"rowspan":cell.totals ? 2 : undefined}},[_c('div',[_vm._v(" "+_vm._s(cell.monthName)+" ")])])}),0)]),_c('tbody',_vm._l((_vm.expenseTypeList),function(row){return _c('tr',{key:row.id + (row.child_id ? '/' + row.child_id : ''),staticClass:"bordered"},[(row.rowspan !== 0)?_c('td',{staticClass:"bg-secondary sticky",attrs:{"rowspan":row.rowspan}},[_vm._v(" "+_vm._s(row.expense_type_name)+" ")]):_vm._e(),(_vm.childExpensesEnabled)?_c('td',{staticClass:"bg-secondary sticky"},[(row.child_expense_type_name)?[_vm._v(" "+_vm._s(row.child_expense_type_name)+" ")]:_c('em',{staticClass:"text-muted"},[_vm._v("Girilmemiş")])],2):_vm._e(),_vm._l((row.cells),function(cell){return _c('td',{key:cell.date,staticClass:"text-end"},[_c('rs-table-cell-number',{class:!cell.amount ? 'text-muted' : '',attrs:{"price":"","value":cell.amount}})],1)}),(_vm.childExpensesEnabled)?_c('td',{staticClass:"bg-secondary"},[_c('rs-table-cell-number',{attrs:{"price":"","value":_vm.totals.expense_type_sums.filter(
                    (v) =>
                      v.expense_type_id === row.id &&
                      v.child_expense_type_id === row.child_id
                  )[0].sum}})],1):_c('td',{staticClass:"bg-secondary"},[_c('rs-table-cell-number',{attrs:{"price":"","value":_vm.totals.expense_type_sums.filter(
                    (v) => v.expense_type_id === row.id
                  )[0].sum}})],1)],2)}),0),_c('tfoot',[_c('tr',{staticClass:"bg-secondary bordered"},[_c('td',{staticClass:"font-weight-bold bg-secondary sticky",attrs:{"colspan":_vm.childExpensesEnabled ? 2 : 1}},[_vm._v(" Genel Toplam ")]),_vm._l((_vm.headerList),function(cell){return _c('td',{key:cell.key,staticClass:"text-right"},[_c('rs-table-cell-number',{attrs:{"price":"","value":cell.sum}})],1)}),_c('td',{staticClass:"bg-secondary bordered"},[_c('rs-table-cell-number',{attrs:{"price":"","value":_vm.totals.sum}})],1)],2)])]},proxy:true}],null,false,3767307923)})],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }