<template>
  <rs-side-panel ref="sidePanel" v-if="bankActivity">
    <template slot="header">Bankadan Bankaya Transfer</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col cols="12" sm="6">
            <rs-select-bank-account
              :label="
                bankActivity.amount > 0
                  ? 'Transferi Alan Banka Hesabı'
                  : 'Transferi Gönderen Banka Hesabı'
              "
              :value="bankActivity.bank_account_id"
              :cluster-id="bankActivity.cluster_id"
              readonly
              filled
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-text-field
              label="Tarih"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-money-field :value="remainingAmount" :disabled="true" />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-select-bank-account
              :label="
                bankActivity.amount > 0
                  ? 'Transferi Gönderen Banka Hesabı'
                  : 'Transferi Alan Banka Hesabı'
              "
              v-model="formData.bank_account_id"
              :cluster-id="bankActivity.cluster_id"
              :rules="[rules.required]"
              :readonly="disabled"
              required
              :filled="disabled"
            />
          </v-col>

          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :readonly="disabled"
              :filled="disabled"
              :rules="[
                rules.required,
                rules.maxLength(formData.description, 1000),
              ]"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          hide-submit
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions } from "vuex";
import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  computed: {
    remainingAmount() {
      if (this.bankActivity) {
        return (
          (this.bankActivity.amount * 100 -
            this.bankActivity.transaction_amount * 100) /
          100
        );
      }

      return null;
    },
  },
  data() {
    return {
      bankActivity: null,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(bankActivity) {
      this.bankActivity = bankActivity;

      if (bankActivity.cluster_id) {
        this.updateClusterId(bankActivity.cluster_id);
      }

      this.formData = {
        bank_account_id: null,
        description: bankActivity.description,
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.bankActivity = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      this.isLoading = true;

      this.$api
        .post(
          `income/bank-activities/${this.bankActivity.id}/transfer`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
