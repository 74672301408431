<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Fatura Taksiti</template>
    <template slot="body">
      <v-form
        ref="form"
        @submit.prevent="handleFormSubmit"
        v-if="totalUninstalled > 0 || id"
      >
        <v-row cols="12" dense>
          <v-col cols="12" sm="6">
            <rs-datepicker
              label="İlk Taksit Tarihi"
              v-model="formData.starts_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-text-field
              type="number"
              step="1"
              min="1"
              label="Taksit Sayısı"
              v-model="formData.installment_count"
              :rules="[rules.required, rules.min(formData.amount, 1)]"
              :disabled="disabled"
              :readonly="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>

      <div v-else-if="!id">
        <v-alert type="error" border="left">
          Faturanın tümü taksitlendirilmiş. Yeni taksit ekleyemezsiniz
        </v-alert>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-submit
          hide-save-close
        />
      </div>
    </template>
  </rs-side-panel>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "InvoiceAutoInstallmentForm",
  mixins: [hasForm],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalAmount() {
      if (!this.invoice || !this.invoice.items || !this.invoice.items.length) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.items.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalInstallmentAmount() {
      if (
        !this.invoice ||
        !this.invoice.installments ||
        !this.invoice.installments.length
      ) {
        return 0;
      }

      let totalAmount = 0;

      this.invoice.installments.forEach(
        (item) => (totalAmount += parseFloat(item.amount))
      );

      return totalAmount;
    },
    totalUninstalled() {
      const amount =
        (this.totalAmount * 100 - this.totalInstallmentAmount * 100) / 100;
      return amount;
    },
  },
  data() {
    return {};
  },
  methods: {
    show() {
      this.formData = {
        starts_on: new Date().toJSON().split("T")[0],
        installment_count: null,
      };

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);
      formData.invoice_id = this.invoice.id;

      this.$api
        .post(
          `expense/invoices/${this.invoice.id}/installments/auto-installment`,
          formData
        )
        .then((response) => {
          this.$toast.success("Kaydedildi");
          this.$nextTick(() => this.$emit("saved", this.formData));
          if (event && event.closeOnSave) {
            this.hide();
            return;
          }
          this.id = response.data.data.id;
          setTimeout(() => this.load(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
