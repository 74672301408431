var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",staticStyle:{"padding":"0 25px"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('rs-text-field',{staticClass:"menu-search-input",attrs:{"placeholder":"Menüde ara","dark":""},on:{"input":_vm.filterMenuItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('ul',{staticClass:"menu-nav"},[(_vm.isMenuItemVisible('mali_gelir_islemleri'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/incomes') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",class:{ 'menu-item-open': _vm.hasActiveChildren('/incomes') },attrs:{"aria-haspopup":"true"}},[_vm._m(1)]),(
              _vm.can('see-house-income') &&
              _vm.isMenuItemVisible('bagimsiz_bolum_mali_islemleri')
            )?_c('router-link',{attrs:{"to":{ name: 'incomes.houses.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-analytics"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bağımsız Bölüm Mali İşlemleri")])])])]}}],null,false,1728244789)}):_vm._e(),(
              _vm.can('see-resident-income') &&
              _vm.isMenuItemVisible('sakin_mali_islemleri')
            )?_c('router-link',{attrs:{"to":{ name: 'incomes.residents.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-account"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sakin Mali İşlemleri")])])])]}}],null,false,2536492685)}):_vm._e(),(
              _vm.can('see-bank-online') &&
              _vm.isMenuItemVisible('banka_online_islemleri')
            )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/incomes/bank'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(3),(_vm.isMenuItemVisible('banka_online_bekleyen'))?_c('router-link',{attrs:{"to":{ name: 'incomes.bank.open.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-clock"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Banka Online Bekleyen")])])])]}}],null,false,2803469688)}):_vm._e(),(_vm.isMenuItemVisible('banka_islenen'))?_c('router-link',{attrs:{"to":{ name: 'incomes.bank.closed.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-check"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Banka İşlenen")])])])]}}],null,false,815957549)}):_vm._e(),(_vm.isMenuItemVisible('bakiye_kontrol'))?_c('router-link',{attrs:{"to":{ name: 'incomes.bank.balance-check.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-bank-check"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bakiye Kontrol")])])])]}}],null,false,287747908)}):_vm._e()],1)])]):_vm._e(),(
              _vm.can('see-other-income') &&
              _vm.isMenuItemVisible('diger_gelir_islemleri')
            )?_c('router-link',{attrs:{"to":{ name: 'incomes.other.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-refund"}),_c('span',{staticClass:"menu-text"},[_vm._v("Diğer Gelir İşlemleri")])])])]}}],null,false,741614387)}):_vm._e(),(
              _vm.can('see-risky-resident') && _vm.isMenuItemVisible('riskli_sakin')
            )?_c('router-link',{attrs:{"to":{ name: 'incomes.risky.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-alert"}),_c('span',{staticClass:"menu-text"},[_vm._v("Riskli Sakin")])])])]}}],null,false,50140986)}):_vm._e()],1)])]):_vm._e(),(
        _vm.isMenuItemVisible('mali_gider_islemleri') &&
        _vm.can('see-invoice') &&
        (_vm.hasRole('superadministrator') ||
          _vm.hasRole('companyadmin') ||
          _vm.hasRole('companyworker') ||
          _vm.hasRole('supervision') ||
          _vm.hasRole('local_cluster_manager'))
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/expenses') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(4),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",class:{
              'menu-item-open': _vm.hasActiveChildren('/expenses'),
            },attrs:{"aria-haspopup":"true"}},[_vm._m(5)]),(_vm.can('see-invoice') && _vm.isMenuItemVisible('faturalar'))?_c('router-link',{attrs:{"to":{ name: 'expenses.invoices.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-timeline-clock"}),_c('span',{staticClass:"menu-text"},[_vm._v("Faturalar")])])])]}}],null,false,4243815060)}):_vm._e(),(
              _vm.can('see-invoice') && _vm.isMenuItemVisible('gider_fatura_tahakkuk')
            )?_c('router-link',{attrs:{"to":{ name: 'expenses.invoiceitems.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-progress-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gider Fatura Tahakkuk")])])])]}}],null,false,1296153884)}):_vm._e(),(
              _vm.can('see-invoice') && _vm.isMenuItemVisible('gider_fatura_odenmis')
            )?_c('router-link',{attrs:{"to":{ name: 'expenses.invoicepayments.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gider Fatura Ödenmiş")])])])]}}],null,false,2733147032)}):_vm._e()],1)])]):_vm._e(),(
        _vm.isMenuItemVisible('hesap_hareketleri') &&
        _vm.can('see-balance-activity') &&
        (_vm.hasRole('superadministrator') ||
          _vm.hasRole('companyadmin') ||
          _vm.hasRole('companyworker') ||
          _vm.hasRole('supervision') ||
          _vm.hasRole('local_cluster_manager'))
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/activities') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(6),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(7),(_vm.isMenuItemVisible('bagimsiz_bolum_hesap_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'activities.houses.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-switch-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bağımsız Bölüm Hesap Hareketleri")])])])]}}],null,false,3310974126)}):_vm._e(),(_vm.isMenuItemVisible('sakin_hesap_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'activities.residents.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-convert"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Sakin Hesap Hareketleri")])])])]}}],null,false,1198830183)}):_vm._e(),(_vm.isMenuItemVisible('banka_hesap_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'activities.banks.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-bank"}),_c('span',{staticClass:"menu-text"},[_vm._v("Banka Hesap Hareketleri")])])])]}}],null,false,2075220504)}):_vm._e(),(_vm.isMenuItemVisible('kasa_hesap_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'activities.safes.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-safe"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kasa Hesap Hareketleri")])])])]}}],null,false,785666128)}):_vm._e(),(_vm.isMenuItemVisible('tedarikci_hesap_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'activities.providers.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tedarikçi Hesap Hareketleri")])])])]}}],null,false,1224047752)}):_vm._e(),(
              _vm.isMenuItemVisible('robosay_pos_islemleri') &&
              _vm.can('see-cc-payment') &&
              ((_vm.cluster && _vm.cluster.has_credit_card_payments) ||
                _vm.hasRole('superadministrator'))
            )?_c('router-link',{attrs:{"to":{ name: 'activities.cc-payments.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v("RoboSAY POS İşlemleri")])])])]}}],null,false,854112410)}):_vm._e()],1)])]):_vm._e(),(_vm.can('see-assessment') && _vm.isMenuItemVisible('tahakkuk_islemleri'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/assessments') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(8),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(9),(_vm.isMenuItemVisible('tum_tahakkuklar'))?_c('router-link',{attrs:{"to":{ name: 'assessments.all.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-layers-triple-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tüm Tahakkuklar")])])])]}}],null,false,4142519077)}):_vm._e(),(_vm.isMenuItemVisible('genel_tahakkuklar'))?_c('router-link',{attrs:{"to":{ name: 'assessments.general.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-marker-check"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Genel Tahakkuklar")])])])]}}],null,false,602910634)}):_vm._e(),(_vm.isMenuItemVisible('serbest_tahakkuklar'))?_c('router-link',{attrs:{"to":{ name: 'assessments.free.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-earth-arrow-right"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Serbest Tahakkuklar")])])])]}}],null,false,2003809521)}):_vm._e(),(_vm.isMenuItemVisible('bagimsiz_bolum_tipi'))?_c('router-link',{attrs:{"to":{ name: 'assessments.house-type.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cube"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bağımsız Bölüm Tipi")])])])]}}],null,false,87204447)}):_vm._e(),(_vm.isMenuItemVisible('parametrik_tahakkuklar'))?_c('router-link',{attrs:{"to":{ name: 'assessments.parametric.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-variable"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Parametrik Tahakkuklar")])])])]}}],null,false,1232301838)}):_vm._e(),(
              _vm.can('edit-house-income') && _vm.isMenuItemVisible('devir_alacak')
            )?_c('router-link',{attrs:{"to":{ name: 'assessments.transfer-due' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-timeline-plus-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Devir Alacak")])])])]}}],null,false,2397181960)}):_vm._e()],1)])]):_vm._e(),(_vm.can('see-enforcement') && _vm.isMenuItemVisible('icra_islemleri'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/enforcements') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(10),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(11),(_vm.isMenuItemVisible('icralar'))?_c('router-link',{attrs:{"to":{ name: 'enforcements.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-card-multiple-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("İcralar")])])])]}}],null,false,38093888)}):_vm._e()],1)])]):_vm._e(),(
        (_vm.can('see-notification-template') || _vm.can('see-visitor-log')) &&
        _vm.isMenuItemVisible('iletisim')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/communication') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(12),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(13),(_vm.isMenuItemVisible('bildirimler_sms-mobil'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/notifications'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(14),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(15),(
                    _vm.can('see-notification') &&
                    _vm.isMenuItemVisible('gonderilen_bildirimler_toplu')
                  )?_c('router-link',{attrs:{"to":{ name: 'communication.batch-notifications.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cellphone-wireless"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gönderilen Bildirimler Toplu")])])])]}}],null,false,1908090656)}):_vm._e(),(
                    _vm.can('see-notification') &&
                    _vm.isMenuItemVisible('gonderilen_bildirimler_bireysel')
                  )?_c('router-link',{attrs:{"to":{ name: 'communication.sended-notifications.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cellphone-dock"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gönderilen Bildirimler Bireysel")])])])]}}],null,false,1062600558)}):_vm._e(),(
                    _vm.can('see-notification-template') &&
                    _vm.isMenuItemVisible('bildirim_sablonlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'communication.notification-templates.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cellphone-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Bildirim Şablonları ")])])])]}}],null,false,1053567984)}):_vm._e(),(
                    _vm.hasRole('superadministrator') &&
                    _vm.isMenuItemVisible('sistem_bildirim_sablonlari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'communication.system-notification-templates.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cellphone-link"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sistem Bildirim Şablonları")])])])]}}],null,false,2231096224)}):_vm._e()],1)])]):_vm._e(),(_vm.isMenuItemVisible('hatirlaticilar'))?_c('router-link',{attrs:{"to":{ name: 'communication.reminders.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-alarm-multiple"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hatırlatıcılar")])])])]}}],null,false,4082618997)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'communication.breakdowns.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(_vm.can('see-breakdown') && _vm.isMenuItemVisible('arizalar'))?_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-hammer-wrench"}),_c('span',{staticClass:"menu-text"},[_vm._v("Arızalar")])])]):_vm._e()]}}],null,false,2562328860)}),_c('router-link',{attrs:{"to":{ name: 'communication.suggestions.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(_vm.can('see-suggestion') && _vm.isMenuItemVisible('oneriler'))?_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-head-lightbulb-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Öneriler")])])]):_vm._e()]}}],null,false,214995077)}),_c('router-link',{attrs:{"to":{ name: 'communication.complaints.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(_vm.can('see-complaint') && _vm.isMenuItemVisible('sikayetler'))?_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-clipboard-alert"}),_c('span',{staticClass:"menu-text"},[_vm._v("Şikayetler")])])]):_vm._e()]}}],null,false,97857618)}),_c('router-link',{attrs:{"to":{ name: 'communication.maintenances.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(_vm.can('see-maintenance') && _vm.isMenuItemVisible('bakim'))?_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-spray-bottle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bakım")])])]):_vm._e()]}}],null,false,842707770)}),(
              _vm.can('see-house-user') &&
              _vm.isMenuItemVisible('communication.house-users.list')
            )?_c('router-link',{attrs:{"to":{ name: 'communication.house-users.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-fireplace-off"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tüm BB-Sakinler")])])])]}}],null,false,1440697228)}):_vm._e(),(
              _vm.can('see-visitor-log') &&
              _vm.isMenuItemVisible('ziyaretci_kayitlari')
            )?_c('router-link',{attrs:{"to":{
              name: 'communication.visitor-logs.list',
            }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-door"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ziyaretçi Kayıtları")])])])]}}],null,false,1650938088)}):_vm._e(),(
              _vm.can('see-resident') &&
              _vm.isMenuItemVisible('communication.meeting-attendants.list')
            )?_c('router-link',{attrs:{"to":{
              name: 'communication.meeting-attendants.list',
            }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-stamper"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hazirun Listesi")])])])]}}],null,false,4176930946)}):_vm._e()],1)])]):_vm._e(),(_vm.can('see-analyses') && _vm.isMenuItemVisible('analizler'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/analysis') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(16),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(17),(_vm.isMenuItemVisible('borclu_bb-sakin_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.income-type-house-user' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-toy-brick-search"}),_c('span',{staticClass:"menu-text"},[_vm._v("Borçlu BB-Sakin Analiz")])])])]}}],null,false,616392190)}):_vm._e(),(_vm.isMenuItemVisible('aylik_bb-sakin_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.monthly-house-user' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-details"}),_c('span',{staticClass:"menu-text"},[_vm._v("Aylık BB-Sakin Analiz")])])])]}}],null,false,997212866)}):_vm._e(),(_vm.isMenuItemVisible('aylik_gider_fatura_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.expense-pivot.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-calendar-search"}),_c('span',{staticClass:"menu-text"},[_vm._v("Aylık Gider Fatura Analiz")])])])]}}],null,false,1315467846)}):_vm._e(),(_vm.isMenuItemVisible('aylik_gider_odeme_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.expense-payment-pivot.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-calendar-star"}),_c('span',{staticClass:"menu-text"},[_vm._v("Aylık Gider Ödeme Analiz")])])])]}}],null,false,1069172374)}):_vm._e(),(_vm.isMenuItemVisible('mali_durum_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.financial-status' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-finance"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mali Durum Analiz")])])])]}}],null,false,3355108363)}):_vm._e(),(_vm.isMenuItemVisible('tahakkuk_analiz'))?_c('router-link',{attrs:{"to":{ name: 'analysis.assessments' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-finance"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tahakkuk Analiz")])])])]}}],null,false,4276399515)}):_vm._e()],1)])]):_vm._e(),(_vm.can('see-reports') && _vm.isMenuItemVisible('raporlar'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/reports') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(18),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(19),(_vm.isMenuItemVisible('ekstreler'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/reports/extracts'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(20),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(21),(_vm.isMenuItemVisible('1_bb-sakin_hesap_ekstre'))?_c('router-link',{attrs:{"to":{ name: 'reports.extracts.account-statement' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-multiple"}),_c('span',{staticClass:"menu-text"},[_vm._v("1. BB-Sakin Hesap Ekstre")])])])]}}],null,false,2821075916)}):_vm._e(),(_vm.isMenuItemVisible('2_tedarikci_hesap_ekstre'))?_c('router-link',{attrs:{"to":{
                    name: 'reports.extracts.balance-activities.provider',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-store-marker"}),_c('span',{staticClass:"menu-text"},[_vm._v("2. Tedarikçi Hesap Ekstre")])])])]}}],null,false,2582721143)}):_vm._e(),(_vm.isMenuItemVisible('3_banka_hesap_ekstre'))?_c('router-link',{attrs:{"to":{
                    name: 'reports.extracts.balance-activities.bank-account',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-table"}),_c('span',{staticClass:"menu-text"},[_vm._v("3. Banka Hesap Ekstre")])])])]}}],null,false,1053200853)}):_vm._e(),(_vm.isMenuItemVisible('4_kasa_hesap_ekstre'))?_c('router-link',{attrs:{"to":{ name: 'reports.extracts.balance-activities.safe' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-archive-search-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("4. Kasa Hesap Ekstre")])])])]}}],null,false,3437183967)}):_vm._e()],1)])]):_vm._e(),(_vm.isMenuItemVisible('isletme_defterleri'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/reports/operating'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(22),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(23),(_vm.isMenuItemVisible('1_gelir_gider_odeme'))?_c('router-link',{attrs:{"to":{ name: 'reports.operating.income-expense' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-gantt"}),_c('span',{staticClass:"menu-text"},[_vm._v("1. Gelir Gider (Ödeme)")])])])]}}],null,false,4029084416)}):_vm._e(),(_vm.isMenuItemVisible('2_gelir-gider_fatura'))?_c('router-link',{attrs:{"to":{
                    name: 'reports.operating.accounting-income-expense',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-areaspline-variant"}),_c('span',{staticClass:"menu-text"},[_vm._v("2. Gelir-Gider (Fatura)")])])])]}}],null,false,2450431948)}):_vm._e(),(_vm.isMenuItemVisible('3_gelir_rapor'))?_c('router-link',{attrs:{"to":{ name: 'reports.operating.income' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-arrow-collapse-right"}),_c('span',{staticClass:"menu-text"},[_vm._v("3. Gelir Rapor")])])])]}}],null,false,3452883588)}):_vm._e(),(_vm.isMenuItemVisible('4_gider_rapor'))?_c('router-link',{attrs:{"to":{ name: 'reports.operating.expense' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-arrow-collapse-left"}),_c('span',{staticClass:"menu-text"},[_vm._v("4. Gider Rapor")])])])]}}],null,false,72795728)}):_vm._e(),(_vm.isMenuItemVisible('5_gelir-gider_ozet'))?_c('router-link',{attrs:{"to":{ name: 'reports.operating.income-expense-review' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-bar-stacked"}),_c('span',{staticClass:"menu-text"},[_vm._v("5. Gelir-Gider (Özet)")])])])]}}],null,false,707710576)}):_vm._e()],1)])]):_vm._e(),(_vm.isMenuItemVisible('denetim_raporlari'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/reports/review'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(24),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(25),(_vm.isMenuItemVisible('1_denetim_raporu'))?_c('router-link',{attrs:{"to":{ name: 'reports.review-reports.review' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-gesture-tap-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("1. Denetim Raporu")])])])]}}],null,false,1604366745)}):_vm._e(),(_vm.isMenuItemVisible('2_mizan_raporu'))?_c('router-link',{attrs:{"to":{ name: 'reports.review-reports.review-detail' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-gesture-tap-box"}),_c('span',{staticClass:"menu-text"},[_vm._v("2. Mizan Raporu")])])])]}}],null,false,658495761)}):_vm._e()],1)])]):_vm._e(),(_vm.isMenuItemVisible('tya_pano_raporlari'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/reports/cluster'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(26),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(27),(_vm.isMenuItemVisible('1_tya_pano_raporu'))?_c('router-link',{attrs:{"to":{ name: 'reports.cluster.audit' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-analytics"}),_c('span',{staticClass:"menu-text"},[_vm._v("1. TYA Pano Raporu")])])])]}}],null,false,3406345756)}):_vm._e(),(_vm.isMenuItemVisible('2_faaliyetler'))?_c('router-link',{attrs:{"to":{ name: 'reports.cluster.operating-items' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-format-list-numbered"}),_c('span',{staticClass:"menu-text"},[_vm._v("2. Faaliyetler")])])])]}}],null,false,972435087)}):_vm._e(),(_vm.isMenuItemVisible('3_faaliyet_raporu'))?_c('router-link',{attrs:{"to":{ name: 'reports.cluster.operating' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-format-list-text"}),_c('span',{staticClass:"menu-text"},[_vm._v("3. Faaliyet Raporu")])])])]}}],null,false,93423777)}):_vm._e()],1)])]):_vm._e(),(_vm.isMenuItemVisible('borc_alacak_toplamlari'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/reports/sums'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(28),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(29),(_vm.isMenuItemVisible('1-gider_detay'))?_c('router-link',{attrs:{"to":{ name: 'reports.sums.expense-detail' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-waves-arrow-left"}),_c('span',{staticClass:"menu-text"},[_vm._v(" 1. Tedarikçi Borç - Alacak Toplamları ")])])])]}}],null,false,2334368564)}):_vm._e()],1)])]):_vm._e()])])]):_vm._e(),(
        (_vm.hasRole('companyadmin') ||
          _vm.hasRole('superadministrator') ||
          _vm.hasRole('companyworker')) &&
        _vm.isMenuItemVisible('sirket_panelim')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/my-company') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(30),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(31),(
              _vm.can('see-company-info') &&
              _vm.isMenuItemVisible('sirket_bilgileri') &&
              (_vm.hasRole('companyadmin') || _vm.hasRole('superadministrator'))
            )?_c('router-link',{attrs:{"to":{ name: 'my-company.info' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-domain"}),_c('span',{staticClass:"menu-text"},[_vm._v("Şirket Bilgileri")])])])]}}],null,false,1412940004)}):_vm._e(),(
              _vm.can('see-company-user') &&
              _vm.isMenuItemVisible('sirket_calisanlari') &&
              (_vm.hasRole('companyadmin') || _vm.hasRole('superadministrator'))
            )?_c('router-link',{attrs:{"to":{ name: 'my-company.workers.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-badge-account-horizontal"}),_c('span',{staticClass:"menu-text"},[_vm._v("Şirket Çalışanları")])])])]}}],null,false,3846706944)}):_vm._e(),(
              _vm.can('see-company-user') &&
              _vm.isMenuItemVisible('calisan_gorev_turleri') &&
              (_vm.hasRole('companyadmin') || _vm.hasRole('superadministrator'))
            )?_c('router-link',{attrs:{"to":{ name: 'my-company.worker-positions.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-network"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Çalışan Görev Türleri")])])])]}}],null,false,1917228794)}):_vm._e(),(
              _vm.can('edit-company') && _vm.isMenuItemVisible('sirket_tedarikcileri')
            )?_c('router-link',{attrs:{"to":{ name: 'my-company.providers.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-dump-truck"}),_c('span',{staticClass:"menu-text"},[_vm._v("Şirket Tedarikçileri")])])])]}}],null,false,2149610173)}):_vm._e()],1)])]):_vm._e(),(
        (_vm.hasRole('companyadmin') ||
          _vm.hasRole('superadministrator') ||
          _vm.hasRole('local_cluster_manager') ||
          _vm.hasRole('companyworker')) &&
        _vm.isMenuItemVisible('sirket_panelim')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/my-customer') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-domain"}),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteri Panelim")]),(_vm.hasPaymentWarning)?_c('v-icon',{staticClass:"payment-warning",attrs:{"dark":""}},[_vm._v("mdi-exclamation")]):_vm._e(),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(32),(
              _vm.hasRole('local_cluster_manager') ||
              _vm.hasRole('companyadmin') ||
              _vm.hasRole('superadministrator') ||
              _vm.hasRole('companyworker')
            )?_c('router-link',{attrs:{"to":{ name: 'my-customer.billing.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-100"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ödemeler")]),(_vm.hasPaymentWarning)?_c('v-icon',{staticClass:"payment-warning",attrs:{"dark":""}},[_vm._v("mdi-exclamation")]):_vm._e()],1)])]}}],null,false,3770188233)}):_vm._e()],1)])]):_vm._e(),(
        (_vm.isMenuItemVisible('sistem_bilgisi') &&
          _vm.hasRole('superadministrator')) ||
        _vm.hasRole('companyadmin') ||
        _vm.hasRole('companyworker') ||
        _vm.hasRole('supervision') ||
        _vm.hasRole('local_cluster_manager')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/logs') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(33),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(34),(_vm.isMenuItemVisible('kullanici_hareketleri'))?_c('router-link',{attrs:{"to":{ name: 'logs.action.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-reactivate"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Kullanıcı Hareketleri")])])])]}}],null,false,4126383549)}):_vm._e()],1)])]):_vm._e(),(
        (_vm.isMenuItemVisible('tanımlar') && _vm.hasRole('superadministrator')) ||
        _vm.hasRole('companyadmin') ||
        _vm.hasRole('companyworker') ||
        _vm.hasRole('supervision') ||
        _vm.hasRole('local_cluster_manager')
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open':
          _vm.hasActiveChildren('/definitions') ||
          _vm.hasActiveChildren('/other-definitions'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(35),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(36),(_vm.isMenuItemVisible('temel_tanimlar'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/definitions'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(37),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(38),(
                    _vm.can('see-company') &&
                    _vm.isMenuItemVisible('yonetim_sirketi_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.companies.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-modern"}),_c('span',{staticClass:"menu-text"},[_vm._v("Yönetim Şirketi Tanımları")])])])]}}],null,false,4162520630)}):_vm._e(),(
                    _vm.can('see-cluster') &&
                    _vm.isMenuItemVisible('toplu_yasam_alani_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.clusters.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-city-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Toplu Yaşam Alanı Tanımları")])])])]}}],null,false,19586475)}):_vm._e(),(
                    _vm.can('see-house') &&
                    _vm.isMenuItemVisible('bagimsiz_bolum_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.houses.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-assistant"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bağımsız Bölüm Tanımları")])])])]}}],null,false,2818388910)}):_vm._e(),(
                    _vm.can('see-resident') &&
                    _vm.isMenuItemVisible('sakin_ve_kullanici_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.residents.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-badge"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sakin ve Kullanıcı Tanımları")])])])]}}],null,false,2077294815)}):_vm._e(),(
                    _vm.can('see-cluster-provider') &&
                    _vm.isMenuItemVisible('tedarikci_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.cluster-providers.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-mower"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tedarikçi Tanımları")])])])]}}],null,false,2331724033)}):_vm._e(),(
                    _vm.can('see-bank-account') &&
                    _vm.isMenuItemVisible('banka_hesap_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.bank-accounts.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-bank-plus"}),_c('span',{staticClass:"menu-text"},[_vm._v("Banka Hesap Tanımları")])])])]}}],null,false,4070750896)}):_vm._e(),(
                    _vm.can('see-safe') && _vm.isMenuItemVisible('kasa_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.safes.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-wallet-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kasa Tanımları")])])])]}}],null,false,497325755)}):_vm._e(),(
                    _vm.can('see-periodical-income') &&
                    _vm.isMenuItemVisible('periyodik_borclandirma_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'definitions.periodical-assessments.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cube-unfolded"}),_c('span',{staticClass:"menu-text"},[_vm._v("Periyodik Borçlandırma Tanımları")])])])]}}],null,false,3313914208)}):_vm._e()],1)])]):_vm._e(),(
              (_vm.isMenuItemVisible('diger_tanimlar') &&
                _vm.hasRole('superadministrator')) ||
              _vm.hasRole('companyadmin') ||
              _vm.hasRole('companyworker') ||
              _vm.hasRole('supervision') ||
              _vm.hasRole('local_cluster_manager')
            )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/other-definitions'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(39),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(40),(
                    _vm.can('see-expense-type') &&
                    _vm.isMenuItemVisible('gider_turleri')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.expense-types.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-tag-arrow-up"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Gider Türleri")])])])]}}],null,false,2061136224)}):_vm._e(),(
                    _vm.can('see-expense-type') &&
                    _vm.isMenuItemVisible('gider_tipleri')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.child-expense-types.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-clipboard-arrow-up"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Gider Tipleri")])])])]}}],null,false,1886997545)}):_vm._e(),(
                    _vm.can('see-income-type') &&
                    _vm.isMenuItemVisible('gelir_turleri')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.income-types.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-tag-arrow-down"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Gelir Türleri")])])])]}}],null,false,2068582655)}):_vm._e(),(
                    _vm.can('see-other-income-type') &&
                    _vm.isMenuItemVisible('diger_gelir_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.other-incomes.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-100"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Diğer Gelir Tanımları")])])])]}}],null,false,1578010631)}):_vm._e(),(
                    _vm.can('see-custom-income-type') &&
                    _vm.isMenuItemVisible('bagimsiz_bolum_ozel_gelir_turleri')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.housing-income.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-group-plus"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bağımsız Bölüm Özel Gelir Türleri")])])])]}}],null,false,4195970328)}):_vm._e(),(
                    _vm.can('see-project') && _vm.isMenuItemVisible('proje_tanimlari')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.projects.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-home-roof"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Proje Tanımları")])])])]}}],null,false,69436582)}):_vm._e(),(
                    _vm.can('see-fixture-group') &&
                    _vm.isMenuItemVisible('demirbas_gruplari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.fixtures.fixture-groups.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-sitemap"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Demirbaş Grupları")])])])]}}],null,false,185642457)}):_vm._e(),(
                    _vm.can('see-fixture') && _vm.isMenuItemVisible('demirbaslar')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.fixtures.fixtures.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-folder-star-multiple"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Demirbaşlar")])])])]}}],null,false,4245005597)}):_vm._e(),(
                    _vm.can('see-payback-reason') &&
                    _vm.isMenuItemVisible('avans_iade_nedenleri')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.payback-reasons.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-refund"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Avans İade Nedenleri")])])])]}}],null,false,2198251820)}):_vm._e(),(
                    _vm.can('see-contract-type') &&
                    _vm.isMenuItemVisible('sozlesme_tur_tanimlari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.types.contract-types.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-format-list-text"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Sözleşme Tür Tanımları")])])])]}}],null,false,738077397)}):_vm._e(),(
                    _vm.can('see-control-type') &&
                    _vm.isMenuItemVisible('bakim_tur_tanimlari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.types.maintenance-types.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-hexagon-multiple-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bakım Tür Tanımları")])])])]}}],null,false,557371006)}):_vm._e(),(
                    _vm.can('see-control-type') &&
                    _vm.isMenuItemVisible('bakim_tanimlari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.maintenance.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-spray-bottle"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bakım Tanımları")])])])]}}],null,false,1501620838)}):_vm._e(),(
                    _vm.can('see-cluster-phone') &&
                    _vm.isMenuItemVisible('onemli_telefonlar')
                  )?_c('router-link',{attrs:{"to":{ name: 'other-definitions.cluster-phones.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cellphone-information"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Telefon Rehberi")])])])]}}],null,false,637620636)}):_vm._e(),(
                    _vm.can('see-breakdown-type') &&
                    _vm.isMenuItemVisible('ariza_tur_tanimlari')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.types.breakdown-types.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-toolbox-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Arıza Tür Tanımları")])])])]}}],null,false,710130929)}):_vm._e(),(
                    _vm.can('edit-house') && _vm.isMenuItemVisible('bb_entegrasyon')
                  )?_c('router-link',{attrs:{"to":{
                    name: 'other-definitions.house-integrations.list',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-connection"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("DAF Entegrasyon")])])])]}}],null,false,3040746283)}):_vm._e()],1)])]):_vm._e()])])]):_vm._e(),(
        _vm.isMenuItemVisible('budget') &&
        _vm.can('see-budget') &&
        _vm.cluster &&
        _vm.cluster.has_budgets
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/budget'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(41),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(42),(_vm.isMenuItemVisible('budget_definition'))?_c('router-link',{class:{
              'menu-item-open':
                _vm.hasActiveChildren('/budget/definition') ||
                _vm.hasActiveChildren('/budget/wizard'),
            },attrs:{"to":{ name: 'budget.definition.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-script"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bütçe Tanımı")])])])]}}],null,false,3407427057)}):_vm._e(),(_vm.isMenuItemVisible('budget_invoice_items_active'))?_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren(
                '/budget/invoice-items/active'
              ),
            },attrs:{"to":{ name: 'budget.invoice-items.active' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Bütçelenmiş Giderler")])])])]}}],null,false,1772168992)}):_vm._e(),(_vm.isMenuItemVisible('budget_invoice_items_open'))?_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren(
                '/budget/invoice-items/open'
              ),
            },attrs:{"to":{ name: 'budget.invoice-items.open' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-remove"}),_c('span',{staticClass:"menu-text"},[_vm._v("Açık Bütçe Giderleri")])])])]}}],null,false,4226709737)}):_vm._e()],1)])]):_vm._e(),(_vm.hasRole('superadministrator'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/admin'),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(43),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(44),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/crm'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(45),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(46),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/crm/customers'
                    ),
                  },attrs:{"to":{ name: 'admin.crm.customers.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-office-building-cog-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteriler")])])])]}}],null,false,3937904623)}),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/crm/customer-groups'
                    ),
                  },attrs:{"to":{ name: 'admin.crm.customer-groups.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-group"}),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteri Grupları")])])])]}}],null,false,2653129477)}),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/crm/customer-invoices'
                    ),
                  },attrs:{"to":{ name: 'admin.crm.customer-invoices.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-invoice-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Faturalar")])])])]}}],null,false,3362128150)}),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/crm/customer-payments'
                    ),
                  },attrs:{"to":{ name: 'admin.crm.customer-payments.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-100"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ödemeler")])])])]}}],null,false,2682788769)}),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/crm/bank-activities'
                    ),
                  },attrs:{"to":{ name: 'admin.crm.bank-activities.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-bank-circle-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Banka Hareketleri")])])])]}}],null,false,3927851333)})],1)])]),_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/products'),
            },attrs:{"to":{ name: 'admin.products.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-package-variant"}),_c('span',{staticClass:"menu-text"},[_vm._v("Ürünler")])])])]}}],null,false,2779655175)}),_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/users'),
            },attrs:{"to":{ name: 'admin.users.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-account-search"}),_c('span',{staticClass:"menu-text"},[_vm._v("Kullanıcılar")])])])]}}],null,false,3973665199)}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/statistics'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(47),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(48),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/statistics/web'
                    ),
                  },attrs:{"to":{ name: 'admin.statistics.web' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-desktop-classic"}),_c('span',{staticClass:"menu-text"},[_vm._v("Web İstatistikler")])])])]}}],null,false,2381249694)}),_c('router-link',{class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/admin/statistics/mobile'
                    ),
                  },attrs:{"to":{ name: 'admin.statistics.mobile' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-tablet-cellphone"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mobil İstatistikler")])])])]}}],null,false,1111343021)})],1)])]),_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/payout-check'),
            },attrs:{"to":{ name: 'admin.payout-check.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Transfer Takip")])])])]}}],null,false,486947326)}),_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/ads'),
            },attrs:{"to":{ name: 'admin.ads.list' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-advertisements"}),_c('span',{staticClass:"menu-text"},[_vm._v("Reklamlar")])])])]}}],null,false,337492877)}),_c('router-link',{class:{
              'menu-item-open': _vm.hasActiveChildren('/admin/import/transfers'),
            },attrs:{"to":{ name: 'admin.import.transfers' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-timeline-plus-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("Devir")])])])]}}],null,false,4174437837)})],1)])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-plus"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gelir İşlemleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Gelir İşlemleri")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-fast"}),_c('span',{staticClass:"menu-text"},[_vm._v("Banka Online İşlemleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-minus"}),_c('span',{staticClass:"menu-text"},[_vm._v("Gider İşlemleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Gider İşlemleri")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-cash-register"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hesap Hareketleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Hesap Hareketleri")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-receipt-text-check"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tahakkuk İşlemleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Tahakkuk İşlemleri")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-card-bulleted-settings-outline"}),_c('span',{staticClass:"menu-text"},[_vm._v("İcra İşlemleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("İcra İşlemleri")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-ear-hearing"}),_c('span',{staticClass:"menu-text"},[_vm._v("İletişim")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("İletişim")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-message-text"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bildirimler"),_c('br'),_vm._v("(SMS-Mobil)")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-ab-testing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Analizler")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Analizler")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-scatter-plot"}),_c('span',{staticClass:"menu-text"},[_vm._v("Raporlar")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Raporlar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-forwardburger"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Ekstreler")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-store-settings"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("İşletme Defterleri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-account-hard-hat-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Denetim Raporları")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-folder-file"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("TYA Pano Raporları")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-waves-arrow-left"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Borç - Alacak Toplamları")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Şirket Panelim")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Şirket Panelim")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Müşteri Panelim")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-information"}),_c('span',{staticClass:"menu-text"},[_vm._v("Sistem Bilgisi")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Sistem Bilgisi")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-clipboard-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Tanımlar")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Tanımlar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-clipboard-file"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Temel Tanımlar")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-clipboard-flow"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Diğer Tanımlar")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-library"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bütçe Yönetimi")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-shield-crown-outline"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Yönetici")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-face-agent"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Müşteri İlişkileri")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon mdi mdi-chart-bell-curve"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("İstatistikler")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])])
}]

export { render, staticRenderFns }