<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        show-expand
        :expanded.sync="expandedItems"
        @item-expanded="handleItemExpanded"
        disable-sort
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bakiye Kontrol"
            icon="mdi-bank-check"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-budget')"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            :show-delete="can('delete-budget')"
          >
          </rs-table-title-bar>
          <v-alert
            type="info"
            dismissible
            transition="slide-x-transition"
            prominent
          >
            <p>
              RoboSAY'da tüm işlemleri banka entegrasyondan yapabileceğiniz gibi
              elle borç ve alacak ekleyebilirsiniz. Ancak bu işlemler doğru
              yapılmadığında bankadaki gerçek bakiyenizle sistemde tutulan
              bakiye arasında fark oluşabilir.
            </p>
            <p>
              Bu listede banka entegrasyondaki hareketlerinizle sistemdeki banka
              hareketleriniz arasındaki farkı tespit edebilir ve
              düzeltebilirsiniz.
            </p>
            <p v-if="bankActivityCount">
              Listenin doğru veri getirebilmesi için
              <router-link
                :to="{ name: 'incomes.bank.open.list' }"
                class="text-light text-decoration-underline"
                >banka enterasyonda bekleyen işlem olmadığından</router-link
              >
              emin olun.
            </p>
          </v-alert>

          <v-row dense v-if="balanceList !== null">
            <v-col
              cols="6"
              sm="3"
              lg="2"
              v-for="item in balanceList"
              :key="item.id"
              class="text-end"
            >
              Banka: <strong>{{ item.name }}</strong
              ><br />
              İşlenmiş: <rs-table-cell-balance :value="item.balance" /><br />
              Entegrasyon:
              <rs-table-cell-balance :value="item.activity_balance" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="search.hide_equals"
                label="Son bakiyesi tutan bankaları gösterme"
              />
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.system_balance="{ value }">
          <rs-table-cell-number muted-zeros price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.integration_balance="{ value }">
          <rs-table-cell-number muted-zeros price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.diff="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding-left: 0 !important">
            <div class="p-3">
              <v-row dense>
                <v-col md="6" v-if="!isLoading">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th colspan="2" class="text-center font-weight-bold">
                            İşlenen Hareketler
                          </th>
                        </tr>

                        <tr>
                          <th>Açıklama</th>
                          <th>Tutar</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td>
                            <em>
                              <router-link
                                :to="{
                                  name: 'activities.banks.list',
                                  query: {
                                    bank_safe: item.bank_account_name,
                                    'action_on[min]': item.action_on,
                                    'action_on[max]': item.action_on,
                                  },
                                }"
                                target="_blank"
                              >
                                {{ item.expandedBalanceActivities?.length }}
                                işlem.
                              </router-link>
                            </em>
                          </td>
                          <td class="text-end">
                            <em>
                              <rs-table-cell-number
                                price
                                colored
                                :value="
                                  item.expandedBalanceActivities?.reduce(
                                    (i, t) => (i += t.amount),
                                    0.0
                                  )
                                "
                              />
                            </em>
                          </td>
                        </tr>
                      </tfoot>
                      <tbody v-if="item.expandedBalanceActivities?.length">
                        <tr
                          v-for="row in item.expandedBalanceActivities"
                          :key="row.id"
                        >
                          <td>{{ row.description }}</td>
                          <td class="text-end">
                            <rs-table-cell-number
                              price
                              colored
                              :value="row.amount"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-if="item.expandedBalanceActivities?.length === 0">
                          <td colspan="2" class="text-center">
                            <em>Sistemde işlem bulunamadı.</em>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col md="6" v-if="isLoading">
                  <v-skeleton-loader
                    type="table-thead, table-tbody, table-tfoot"
                  />
                </v-col>

                <v-col md="6" v-if="!isLoading">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th colspan="3" class="text-center font-weight-bold">
                            Banka Hesap Hareketleri
                          </th>
                        </tr>

                        <tr>
                          <th>Tutar</th>
                          <th>Açıklama</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td class="text-end">
                            <em>
                              <rs-table-cell-number
                                price
                                colored
                                :value="
                                  item.expandedBankActivities?.reduce(
                                    (i, t) => (i += t.amount),
                                    0.0
                                  )
                                "
                              />
                            </em>
                          </td>
                          <td>
                            <em>
                              <router-link
                                :to="{
                                  name: 'incomes.bank.closed.list',
                                  query: {
                                    bank_account: item.bank_account_name,
                                    'activity_on[min]': item.action_on,
                                    'activity_on[max]': item.action_on,
                                  },
                                }"
                                target="_blank"
                              >
                                {{ item.expandedBankActivities?.length }}
                                işlem.
                              </router-link>
                            </em>
                          </td>
                          <td></td>
                        </tr>
                      </tfoot>
                      <tbody v-if="item.expandedBankActivities?.length">
                        <tr
                          v-for="row in item.expandedBankActivities"
                          :key="row.id"
                        >
                          <td class="text-end">
                            <rs-table-cell-number
                              price
                              colored
                              :value="row.amount"
                            />
                          </td>
                          <td>{{ row.description }}</td>
                          <td class="text-center">
                            <router-link
                              target="_blank"
                              :to="{
                                name: 'incomes.bank.show',
                                params: { id: row.id },
                              }"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-if="item.expandedBankActivities?.length === 0">
                          <td colspan="3" class="text-center">
                            <em>Entegrasyondan aktarılan işlem bulunamadı.</em>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col md="6" v-if="isLoading">
                  <v-skeleton-loader
                    type="table-thead, table-tbody, table-tfoot"
                  />
                </v-col>
              </v-row>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "@/core/plugins/debounce";
import {
  filtersToURL,
  hasExport,
  hasPermissions,
  isPage,
} from "../../../mixins";

export default {
  name: "BalanceCheckList",
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  computed: {
    ...mapGetters(["bankActivityCount", "clusterId", "companyId"]),
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  data() {
    return {
      expandedItems: [],
      balanceList: null,
      options: {
        sortBy: ["action_on"],
      },
      pageMeta: {
        title: "Bakiye Kontrol",
      },
      search: {
        hide_equals: true,
      },
      titleBarAttrs: {
        exportUrl: () => `balance-check`,
        exportParams: this.getParams,
      },
      headers: [
        {
          text: "TYA",
          value: "cluster_name",
          searchable: "text",
          hide: () => this.clusterId,
        },
        {
          text: "Banka",
          value: "bank_account_name",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "action_on",
          searchable: "date",
        },
        {
          text: "İşlenen Hareketler",
          value: "system_balance",
          searchable: false,
          align: "end",
        },
        {
          text: "Banka Hesap Hareketleri",
          value: "integration_balance",
          searchable: false,
          align: "end",
        },
        {
          text: "Fark",
          value: "diff",
          searchable: "number",
          align: "end",
        },
        { text: "Detay", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId || undefined;

      return params;
    },
    loadList() {
      const params = this.getParams();

      this.isLoading = true;

      return this.$api
        .query("balance-check", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false))
        .then(() => {
          if (this.clusterId) {
            this.isLoading = true;

            this.$api
              .query("bank-accounts", {
                params: {
                  cluster_id: this.clusterId,
                  order_by: "name",
                },
              })
              .then((response) => {
                this.balanceList = response.data.data;
              })
              .catch((error) => {
                this.handleError(error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.balanceList = null;
          }
        });
    },
    async handleItemExpanded({ item, value }) {
      if (value) {
        this.isLoading = true;

        let params = {
          cluster: [item.cluster_id],
          bank_account_id: item.bank_account_id,
          activity_on: {
            min: item.action_on,
            max: item.action_on,
          },
          order_by: "amount",
          order: "asc",
          per_page: 200,
        };

        item.expandedBankActivities = await this.$api
          .query("income/bank-activities", { params })
          .then((response) => {
            return response.data.data;
          })
          .catch((error) => {
            this.handleError(error);
            return [];
          });

        params = {
          cluster_id: item.cluster_id,
          bank_account_id: item.bank_account_id,
          action_on: {
            min: item.action_on,
            max: item.action_on,
          },
          order_by: "bank_safe_amount",
          order: "asc",
          per_page: 200,
        };

        item.expandedBalanceActivities = await this.$api
          .query("balance-activities/bank-safe", { params })
          .then((response) => {
            return response.data.data.map((item) => {
              if (item.bank_safe_due) {
                item.amount = -1 * item.bank_safe_due;
              } else {
                item.amount = item.bank_safe_debt;
              }
              return item;
            });
          })
          .catch((error) => {
            this.handleError(error);
            return [];
          });

        this.isLoading = false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
