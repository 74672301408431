<template>
  <div>Yönlendiriliyorsunuz.</div>
</template>

<script>
import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  methods: {
    load() {
      this.isLoading = true;

      return this.$api
        .query(`assessments/${this.id}`)
        .then((response) => {
          const assessment = response.data.data;

          if (assessment.assessment_type_id === 1) {
            this.$router.replace({
              name: "assessments.general.edit",
              params: { id: assessment.id },
            });
          } else if (assessment.assessment_type_id === 2) {
            this.$router.replace({
              name: "assessments.house-type.edit",
              params: { id: assessment.id },
            });
          } else if (assessment.assessment_type_id === 3) {
            this.$router.replace({
              name: "assessments.free.edit",
              params: { id: assessment.id },
            });
          } else if (assessment.assessment_type_id === 4) {
            this.$router.replace({
              name: "assessments.parametric.edit",
              params: { id: assessment.id },
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
