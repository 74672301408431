<template>
  <div class="text-center text-sm-end mt-7 form-buttons">
    <v-btn
      :loading="isLoading"
      :disabled="isLoading"
      color="default"
      type="button"
      @click="handleCancelClick"
      v-if="!hideCancel"
      >Kapat</v-btn
    >

    <v-btn
      v-if="!hideSubmit"
      :loading="isLoading"
      :disabled="isLoading || disableSubmit"
      color="primary"
      type="submit"
      class="ms-3"
      >{{ saveLabel }}</v-btn
    >

    <v-btn
      v-if="!hideSaveClose"
      :loading="isLoading"
      :disabled="isLoading || disableSubmit"
      color="primary"
      type="submit"
      @click.prevent="handleSaveCloseClick"
      class="ms-3"
      >{{ saveCloseLabel }}</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideSaveClose: {
      type: Boolean,
      default: false,
      required: false,
    },
    saveLabel: {
      type: String,
      default: "Kaydet",
    },
    saveCloseLabel: {
      type: String,
      default: "Kaydet ve Kapat",
    },
  },
  watch: {
    isLoading() {
      if (this.saveCloseClicked && !this.isLoading) {
        this.$emit("cancel");
      }
    },
  },
  methods: {
    handleCancelClick(event) {
      this.$emit("cancel", event);
    },
    handleSaveCloseClick(event) {
      this.$emit("submit", Object.assign(event, { closeOnSave: true }));
    },
  },
};
</script>
