var render = function render(){var _vm=this,_c=_vm._self._c;return _c('rs-side-panel',{ref:"sidePanel"},[_c('template',{slot:"header"},[_vm._v("Diğer Gider")]),_c('template',{slot:"body"},[_c('form',[(_vm.originalData.id)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Diğer Gider Türü","value":_vm.originalData.income_type.name,"readonly":_vm.disabled,"filled":_vm.disabled}})],1),(_vm.originalData.resident)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Sakin","value":_vm.originalData.resident.name,"readonly":_vm.disabled,"filled":_vm.disabled}})],1):_vm._e(),(_vm.originalData.amount)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"label":"Tutar","value":_vm.originalData.amount,"disabled":_vm.disabled}})],1):_vm._e(),(_vm.originalData.income_type_id)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Diğer Gider Türü","value":_vm.originalData.income_type.name,"readonly":_vm.disabled,"filled":_vm.disabled}})],1):_vm._e(),(_vm.originalData.spent_on)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"İşlem Tarihi","value":new Date(_vm.originalData.spent_on).toLocaleDateString(),"readonly":_vm.disabled,"filled":_vm.disabled}})],1):_vm._e(),(_vm.originalData.bank_account_id || _vm.originalData.safe_id)?_c('v-col',{staticClass:"cursor-pointer",attrs:{"sm":"6"},on:{"click":function($event){return _vm.$router.push(
              _vm.originalData.bank_account_id
                ? {
                    name: 'definitions.bank-accounts.edit',
                    params: { id: _vm.originalData.bank_account_id },
                  }
                : {
                    name: 'definitions.safes.edit',
                    params: { id: _vm.originalData.safe_id },
                  }
            )}}},[_c('rs-select-collecting',{attrs:{"label":"Banka / Kasa","bank-account-id":_vm.originalData.bank_account_id,"safe-id":_vm.originalData.safe_id,"readonly":_vm.disabled,"filled":_vm.disabled,"required":_vm.disabled},on:{"update:bankAccountId":function($event){return _vm.$set(_vm.originalData, "bank_account_id", $event)},"update:bank-account-id":function($event){return _vm.$set(_vm.originalData, "bank_account_id", $event)},"update:safeId":function($event){return _vm.$set(_vm.originalData, "safe_id", $event)},"update:safe-id":function($event){return _vm.$set(_vm.originalData, "safe_id", $event)}}})],1):_vm._e(),(_vm.originalData.resident_id)?_c('v-col',{staticClass:"cursor-pointer",attrs:{"sm":"6"},on:{"click":function($event){return _vm.$router.push({
              name: 'definitions.residents.edit',
              params: { id: _vm.originalData.resident_id },
            })}}},[_c('rs-select-resident',{attrs:{"label":"İlgili Sakin","cluster-id":_vm.clusterId,"value":_vm.originalData.resident_id,"readonly":_vm.disabled,"filled":_vm.disabled,"required":_vm.disabled}})],1):_vm._e(),(_vm.originalData.house_id)?_c('v-col',{staticClass:"cursor-pointer",attrs:{"sm":"6"},on:{"click":function($event){return _vm.$router.push({
              name: 'definitions.houses.edit',
              params: { id: _vm.originalData.house_id },
            })}}},[_c('rs-text-field',{attrs:{"label":"İlgili Bağımsız Bölüm","value":_vm.originalData.house.door_number,"readonly":_vm.disabled,"filled":_vm.disabled,"required":_vm.disabled}})],1):_vm._e(),(_vm.originalData.refund_type_id)?_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Avans İade Nedeni","value":_vm.originalData.payback_reason.name,"readonly":_vm.disabled,"filled":_vm.disabled}})],1):_vm._e()],1):_vm._e(),(_vm.originalData.id && _vm.originalData.collecting)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"elevation-2 mt-3 p-3"},[_c('h5',[_vm._v("İlgili Tahsilat")]),_c('p',[_vm._v("Tutar: "+_vm._s(_vm.originalData.collecting.amount.toLocaleString())+"₺")]),_c('p',[_vm._v(" Tarih: "+_vm._s(new Date( _vm.originalData.collecting.collected_on ).toLocaleDateString())+" ")]),_c('p',[_vm._v("Açıklama: "+_vm._s(_vm.originalData.collecting.description))])])],1):_vm._e(),(_vm.originalData.id && _vm.originalData.debt)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"elevation-2 mt-3 p-3"},[_c('h5',[_vm._v("İlgili Borç")]),_c('p',[_vm._v("Tutar: "+_vm._s(_vm.originalData.debt.amount.toLocaleString())+"₺")]),(_vm.originalData.debt.deferment_amount)?_c('p',[_vm._v(" Gecikme: "+_vm._s(_vm.originalData.debt.deferment_amount.toLocaleString())+"₺ ")]):_vm._e(),_c('p',[_vm._v("Açıklama: "+_vm._s(_vm.originalData.debt.description))])])],1):_vm._e(),_c('rs-form-buttons',{attrs:{"is-loading":_vm.isLoading,"hide-submit":"","hide-save-close":""},on:{"cancel":function($event){return _vm.hide()}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }