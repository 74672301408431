<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">TYA - Yönetim Şirketi Eşleştir</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="6" v-if="cluster === null">
            <rs-autocomplete-cluster
              v-model="formData.cluster_id"
              :rules="[rules.required]"
              ref="autocompleteCluster"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
          <v-col sm="6" v-if="company === null">
            <rs-autocomplete-company
              v-model="formData.company_id"
              :rules="[rules.required]"
              ref="autocompleteCompany"
              :readonly="disabled"
              :filled="disabled"
              @change:option="handleCompanySelected"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-datepicker
              :rules="[rules.required]"
              required
              v-model="formData.legal_beginning_date"
              label="Başlangıç Tarihi"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <rs-datepicker
              :rules="[
                rules.required,
                rules.min(
                  formData.legal_ending_date,
                  new Date().toISOString().substr(0, 10)
                ),
              ]"
              v-model="formData.legal_ending_date"
              label="Bitiş Tarihi"
              :min="new Date().toISOString().substr(0, 10)"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide"
          hide-save-close
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions } from "vuex";

import validation from "@/core/validation";
import hasForm from "@/view/mixins/hasForm";

export default {
  name: "ClusterCompanyForm",
  mixins: [hasForm],
  props: {
    // id: {
    //   type: Number,
    //   required: false,
    //   default: null,
    // },
    company: {
      type: Object,
      required: false,
      default: null,
    },
    cluster: {
      type: Object,
      required: false,
      default: null,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  watch: {
    // id: {
    //   handler() {
    //     if (this.id) {
    //       this.load();
    //     }
    //   },
    // },
  },
  data() {
    return {
      isLoading: false,
      formData: {},
      clusterSearch: null,
      clusterList: [],
      rules: validation,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show() {
      this.$nextTick(() => {
        this.$refs.sidePanel.show();
        this.formData = {
          company_id: null,
          cluster_id: null,
          legal_beginning_date: this.cluster.legal_beginning_date,
          legal_ending_date: null,
          selectedCompany: null,
        };

        const now = new Date();
        if (!this.cluster.legal_beginning_date) {
          this.formData.legal_beginning_date = now.toISOString().substr(0, 10);
          this.formData.legal_ending_date = new Date(
            now.setFullYear(now.getFullYear() + 1)
          )
            .toISOString()
            .substr(0, 10);
        } else {
          this.formData.legal_ending_date = new Date(
            new Date(this.cluster.legal_beginning_date).setFullYear(
              new Date(this.cluster.legal_beginning_date).getFullYear() + 1
            )
          )
            .toISOString()
            .substr(0, 10);
        }

        if (this.cluster) {
          this.formData.cluster_id = this.cluster.id;
        }

        if (this.company) {
          this.formData.company_id = this.company.id;
        }
      });
    },
    handleFormSubmit() {
      if (!this.$refs.form.validate() || this.isLoading) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.dataId) {
        // this.$api
        //   .put(`blocks/${this.dataId}`, formData)
        //   .then((response) => {
        //     this.$toast.success("Kaydedildi");
        //     this.$emit("saved", response.data.data);
        //     this.updateClusterId(this.clusterId);
        //     this.hide();
        //   })
        //   .catch((error) => {
        //     this.handleError(error);
        //   })
        //   .finally(() => {
        //     this.isLoading = false;
        //   });
      } else {
        this.$api
          .post(`clusters/${this.formData.cluster_id}/companies`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$nextTick(() => this.$emit("saved", response.data.data));
            this.updateClusterId(this.clusterId);
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCompanySelected(event) {
      this.formData.selectedCompany = event.item;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
