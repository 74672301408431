<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Borçlu / Ödeyen Değiştirme</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row cols="12" dense>
          <v-col cols="12" sm="6">
            <rs-select-resident
              v-if="house"
              label="Taşınan Sakin"
              v-model="formData.resident_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :house-id="onlyHouseUsers ? house.id : null"
              required
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="onlyHouseUsers"
              :label="$t('labels.show_only_house_users')"
              hide-details="auto"
              @change="handleOnlyHouseUsersChange"
            ></v-switch>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <rs-select-house
              v-if="formData.resident_id && !onlyHouseUsers"
              label="Taşınan Bağımsız Bölüm"
              v-model="formData.house_id"
              :rules="[rules.required]"
              :cluster-id="clusterId"
              :resident-id="formData.resident_id"
              required
            />
          </v-col> -->
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  name: "DebtResidentForm",
  mixins: [hasForm],
  props: {
    house: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      onlyHouseUsers: true,
      formData: {
        resident_id: null,
        house_id: null,
      },
    };
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();
      this.formData.resident_id = null;
      this.formData.house_id = null;
      this.onlyHouseUsers = true;
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      if (!this.formData.house_id) {
        this.formData.house_id = this.house.id;
      }

      const params = {
        house_id: this.formData.house_id,
        resident_id: this.formData.resident_id,
        debt_ids: this.selected
          .filter((item) => item.debt_id)
          .map((item) => item.debt_id),
        collecting_ids: this.selected
          .filter((item) => item.collecting_id)
          .map((item) => item.collecting_id),
      };

      if (!params.debt_ids.length) {
        delete params.debt_ids;
      }

      if (!params.collecting_ids.length) {
        delete params.collecting_ids;
      }

      this.$api
        .post("income/change", params)
        .then(() => {
          this.$toast.success("Borçlu / ödeyen değiştirme yapıldı.");
          this.$emit("saved", this.formData);
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleOnlyHouseUsersChange() {
      this.formData.resident_id = null;
      this.formData.house_id = null;
    },
  },
};
</script>
